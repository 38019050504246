import React from "react";
import Edit from "@material-ui/icons/Edit";
import Check from "@material-ui/icons/Check";
import DeleteOutlined from "@material-ui/icons/DeleteOutlined";
import NotInterestedOutlined from "@material-ui/icons/NotInterestedOutlined";
import { Tooltip } from "antd";

const orange = "#ff8c2e";
const fontSize = 20;

export default ({ type, title, click }) => (
  <div className="col-1 mx-1 px-0 ">
    <Tooltip title={title} color={orange} placement="bottom">
      <button onClick={click} className="modal-title-options mr-2">
        {type === "edit" && (
          <Edit style={{ color: orange, fontSize: fontSize }} />
        )}
        {type === "delete" && (
          <DeleteOutlined style={{ color: orange, fontSize: fontSize }} />
        )}
        {type === "accept" && (
          <Check style={{ color: orange, fontSize: fontSize }} />
        )}
        {type === "refuse" && (
          <NotInterestedOutlined
            style={{ color: orange, fontSize: fontSize }}
          />
        )}
      </button>
    </Tooltip>
  </div>
);
