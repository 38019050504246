import React from 'react';
import Routes from './utils/Routes'
import { BrowserRouter } from 'react-router-dom'
import { createBrowserHistory } from "history";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css'

export const history = createBrowserHistory();

function App() {
  return (
    <BrowserRouter>
      <div className="app">
        <Routes />
      </div>
      </BrowserRouter>
  )
}

export default App;
