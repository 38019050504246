import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Header from "../../../utils/template/Header/Header";
import Logo from "../../../utils/template/Logo/Logo";
import Menu from "../../../utils/template/Menu/Menu";
import {
  cpfMask,
  dateMask,
  phoneMask,
  cellPhoneMask,
  CEPMask,
} from "../../../utils/masks";
import { fillParentObject } from "../../../utils/objects";
import {
  fetchUserInfos,
  updateUser,
} from "../../../utils/store/actions/registerActions";
import {
  Button,
  message,
  Select,
  Tag,
  Menu as DropdownMenu,
  Dropdown,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";

const { Option } = Select;

const initialState = {
  loading: true,
  redirect: false,
  sending: false,
  error: {
    name: false,
    email: false,
    rg: false,
    cpf: false,
    birthDate: false,
    gender: false,
    cellPhone: false,
    street: false,
    number: false,
    neighborhood: false,
    complement: false,
    city: false,
    state: false,
    children: false,
  },
  userInfos: {
    name: "",
    email: "",
    rg: "",
    cpf: "",
    birthDate: "",
    phone: "",
    cellPhone: "",
    gender: "",
    address: {
      street: "",
      number: "",
      complement: "",
      neighborhood: "",
      city: "",
      state: "",
      cep: "",
    },
    children: [],
  },
};

class AtualizacaoCadastro extends Component {
  state = {
    ...initialState,
    time: [null, null],
    timeString: ["", ""],
    registration: { ...initialState.registration },
    userInfos: {
      ...initialState.userInfos,
      address: { ...initialState.userInfos.address },
      children: [],
    },
    error: { ...initialState.error },
  };

  componentDidMount() {
    const localId = this.props.match.params.id;
    this.props.onfetchUser("responsavel", localId);
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.loading && !this.props.loading) {
      const userInfos = fillParentObject(this.props.selectedUser);
      this.setState({ ...this.state, userInfos, loading: false });
    }
    if (prevProps.registering && !this.props.registering) {
      const userInfos = fillParentObject(this.props.selectedUser);
      this.setState({ ...this.state, userInfos, sending: false });
      message.success("Informações atualizadas com sucesso!");
    }
  };

  getaddress = () => {
    axios
      .get(
        `https://api.pagar.me/1/zipcodes/${this.state.userInfos.address.cep}`
      )
      .catch((err) => {
        const state = { ...this.state };
        message.error("CEP não encontrado");
        this.setState({ ...state });
      })
      .then((res) => {
        if (res) {
          const state = { ...this.state };
          state.userInfos.address.street = res.data.street;
          state.userInfos.address.city = res.data.city;
          state.userInfos.address.neighborhood = res.data.neighborhood;
          state.userInfos.address.state = res.data.state;
          this.setState({ ...state });
        }
      });
  };

  menuGender = (
    <DropdownMenu onClick={(e) => this.handleChange(e, "gender")}>
      <DropdownMenu.Item key="Feminino">Feminino</DropdownMenu.Item>
      <DropdownMenu.Item key="Masculino">Masculino</DropdownMenu.Item>
    </DropdownMenu>
  );

  handleChange = (e, option) => {
    let state = { ...this.state };
    switch (option) {
      case "name":
        state.userInfos.name = e.target.value;
        this.setState({ ...state });
        break;
      case "email":
        state.userInfos.email = e.target.value;
        this.setState({ ...state });
        break;
      case "rg":
        state.userInfos.rg = e.target.value;
        this.setState({ ...state });
        break;
      case "cpf":
        state.userInfos.cpf = cpfMask(e.target.value);
        this.setState({ ...state });
        break;
      case "birthDate":
        state.userInfos.birthDate = dateMask(e.target.value);
        this.setState({ ...state });
        break;
      case "gender":
        state.userInfos.gender = e.key;
        this.setState({ ...state });
        break;
      case "phone":
        state.userInfos.phone = phoneMask(e.target.value);
        this.setState({ ...state });
        break;
      case "cellPhone":
        state.userInfos.cellPhone = cellPhoneMask(e.target.value);
        this.setState({ ...state });
        break;
      case "children":
        state.userInfos.children.push(e);
        this.setState({ ...state });
        break;
      default:
        this.setState({ ...state });
        break;
    }
  };

  handleaddress = (e, option) => {
    let state = { ...this.state };
    switch (option) {
      case "street":
        state.userInfos.address.street = e.target.value;
        this.setState({ ...state });
        break;
      case "number":
        state.userInfos.address.number = e.target.value;
        this.setState({ ...state });
        break;
      case "complement":
        state.userInfos.address.complement = e.target.value;
        this.setState({ ...state });
        break;
      case "neighborhood":
        state.userInfos.address.neighborhood = e.target.value;
        this.setState({ ...state });
        break;
      case "city":
        state.userInfos.address.city = e.target.value;
        this.setState({ ...state });
        break;
      case "state":
        state.userInfos.address.state = e.target.value;
        this.setState({ ...state });
        break;
      case "cep":
        state.userInfos.address.cep = e.target.value;
        this.setState({ ...state });
        break;
      default:
        this.setState({ ...state });
        break;
    }
  };

  checkRequired = () => {
    let state = { ...this.state };
    let flag = false;

    //Checa quais campos da página foram preenchidos
    state.error.name = state.userInfos.name.trim() === ""
    state.error.email = state.userInfos.email.trim() === "";
    // state.error.rg = state.userInfos.rg.trim() === "";
    // state.error.cpf = state.userInfos.cpf.trim() === "";
    // state.error.birthDate = state.userInfos.birthDate.trim() === "";
    // state.error.gender = state.userInfos.gender.trim() === "";
    // state.error.cellPhone = state.userInfos.cellPhone.trim() === "";
    // state.error.street = state.userInfos.address.street.trim() === "";
    // state.error.number = state.userInfos.address.number.trim() === "";
    // state.error.neighborhood = state.userInfos.address.neighborhood.trim() === "";
    // state.error.city = state.userInfos.address.city.trim() === "";
    // state.error.state = state.userInfos.address.state.trim() === "";
    // state.error.children = state.userInfos.children.length === 0;
    
    for (let key in state.error){
      if (state.error[key] === true){
        flag = true;
      }
    }

    if (flag) {
      message.error("Todos os campos obrigatórios precisam ser preenchidos.");
      this.setState({ ...state });
    } else {
      state.sending = true;
      this.setState({ ...state });
      this.props.onLoadUser(
        state.userInfos,
        "responsavel",
        this.props.match.params.id
      );
    }
  };


  menuGender = (
    <DropdownMenu onClick={(e) => this.handleChange(e, "gender")}>
      <DropdownMenu.Item key="Feminino">Feminino</DropdownMenu.Item>
      <DropdownMenu.Item key="Masculino">Masculino</DropdownMenu.Item>
    </DropdownMenu>
  );

  removeItem(type, item) {
    let state = { ...this.state };
    for (let i = 0; i < state.userInfos.children.length; i++) {
      if (state.userInfos.children[i] === item) {
        state.userInfos.children.splice(i, 1);
      }
    }
    this.setState({ ...state });
  }

  render() {
    return this.state.loading ? null : (
      <div className="page parents">
        {/*-------------------------------------------- HEADER ----------------------------------------- */}
        <Header />
        <Logo />
        <Menu />
        <div className="content mt-4 ml-4">
          <h1 className="title">Atualizar informações de cadastro</h1>
          {/* --------------------------------- NOME  -----------------------------------------*/}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Nome</h3>
            </div>
            <div className="sectionContent">
              <Input
                value={this.state.userInfos.name}
                onChange={(e) => this.handleChange(e, "name")}
                error={this.state.error.name}
                required
                fullWidth
              />
            </div>
          </div>
          {/*--------------------------------- EMAIL ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">E-mail</h3>
            </div>
            <div className="sectionContent">
              <Input
                value={this.state.userInfos.email}
                placeholder="exemplo@email.com"
                onChange={(e) => this.handleChange(e, "email")}
                error={this.state.error.email}
                required
                fullWidth
              />
            </div>
          </div>
          {/*--------------------------------- RG ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">RG</h3>
            </div>
            <div className="sectionContent">
              <Input
                value={this.state.userInfos.rg}
                placeholder="xxxxxxxxx-x"
                onChange={(e) => this.handleChange(e, "rg")}
                fullWidth
                required={this.type === "aluno" ? false : true}
                error={this.state.error.rg}
              />
            </div>
          </div>
          {/*--------------------------------- CPF ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">CPF</h3>
            </div>
            <div className="sectionContent">
              <Input
                value={this.state.userInfos.cpf}
                placeholder="xxx.xxx.xxx-xx"
                onChange={(e) => this.handleChange(e, "cpf")}
                required={this.type === "aluno" ? false : true}
                fullWidth
                error={this.state.error.cpf}
              />
            </div>
          </div>
          {/*--------------------------------- NASCIMENTO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Data de nascimento</h3>
            </div>
            <div className="sectionContent">
              <Input
                value={this.state.userInfos.birthDate}
                placeholder="dd/mm/aaaa"
                onChange={(e) => this.handleChange(e, "birthDate")}
                error={this.state.error.birthDate}
                required
                fullWidth
              />
            </div>
          </div>
          {/*--------------------------------- GÊNERO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Gênero</h3>
            </div>
            <div className="sectionContent">
              <Dropdown overlay={this.menuGender}>
                <Button size="small" className="acoes-massa">
                  {this.state.userInfos.gender
                    ? this.state.userInfos.gender
                    : "Gênero"}
                  <DownOutlined />
                </Button>
              </Dropdown>
            </div>
          </div>
          {/*--------------------------------- TELEFONE ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Telefone</h3>
            </div>
            <div className="sectionContent">
              <Input
                value={this.state.userInfos.phone}
                placeholder="(xx) xxxx-xxxx"
                onChange={(e) => this.handleChange(e, "phone")}
                fullWidth
              />
            </div>
          </div>
          {/*--------------------------------- CELULAR ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Celular</h3>
            </div>
            <div className="sectionContent">
              <Input
                value={this.state.userInfos.cellPhone}
                placeholder="(xx) xxxxx-xxxx"
                onChange={(e) => this.handleChange(e, "cellPhone")}
                error={this.state.error.cellPhone}
                fullWidth
              />
            </div>
          </div>
          {/*--------------------------------- ENDEREÇO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Endereço</h3>
            </div>
            <div className="sectionContent address">
              <Input
                value={
                  this.state.userInfos.address
                    ? CEPMask(this.state.userInfos.address.cep)
                    : ""
                }
                placeholder="CEP"
                onChange={(e) => this.handleaddress(e, "cep")}
                required
                fullWidth
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={(e) => this.getaddress()}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
              <Input
                value={
                  this.state.userInfos.address
                    ? this.state.userInfos.address.street
                    : ""
                }
                placeholder="Rua/Avenida"
                onChange={(e) => this.handleaddress(e, "street")}
                error={this.state.error.street}
                required
                fullWidth
              />
              <Input
                value={
                  this.state.userInfos.address
                    ? this.state.userInfos.address.number
                    : ""
                }
                placeholder="Número"
                onChange={(e) => this.handleaddress(e, "number")}
                error={this.state.error.number}
                required
                fullWidth
              />
              <Input
                value={
                  this.state.userInfos.address
                    ? this.state.userInfos.address.complement
                    : ""
                }
                placeholder="Complemento"
                onChange={(e) => this.handleaddress(e, "complement")}
                required
                fullWidth
              />
              <Input
                value={
                  this.state.userInfos.address
                    ? this.state.userInfos.address.neighborhood
                    : ""
                }
                placeholder="Bairro"
                onChange={(e) => this.handleaddress(e, "neighborhood")}
                error={this.state.error.neighborhood}
                required
                fullWidth
              />
              <Input
                value={
                  this.state.userInfos.address
                    ? this.state.userInfos.address.city
                    : ""
                }
                placeholder="Cidade"
                onChange={(e) => this.handleaddress(e, "city")}
                error={this.state.error.city}
                required
                fullWidth
              />
              <Input
                value={
                  this.state.userInfos.address
                    ? this.state.userInfos.address.state
                    : ""
                }
                placeholder="Estado"
                onChange={(e) => this.handleaddress(e, "state")}
                error={this.state.error.state}
                required
                fullWidth
              />
            </div>
          </div>
          {/*--------------------------------- FILHOS ----------------------------------------- */}
            <div className="section">
              <div className="sectionTitle">
                <h3 className="item-title">Filhos</h3>
              </div>
              <div className="sectionContent time">
                {this.state.userInfos.children.map((child) => (
                  <Tag
                    closable
                    onClose={() => this.removeItem("children", child)}
                  >
                    {child}
                  </Tag>
                ))}
              </div>
              <div className="sectionContent ">
                <Select
                  showSearch
                  placeholder="Selecione um aluno"
                  optionFilterProp="children"
                  onChange={(value) => this.handleChange(value, "children")}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.props.users.map(
                    (user, index) =>
                      user.type === "aluno" && (
                        <Option key={`${user.localId}child`} value={user.name}>
                          {user.name}
                        </Option>
                      )
                  )}
                </Select>
              </div>
            </div>
          {/*--------------------------------- ENVIAR ----------------------------------------- */}
          <Button
            type="primary"
            className="submit"
            onClick={(e) => this.checkRequired()}
            loading={this.state.sending}
          >
            Enviar
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ register }) => ({
  users: register.users,
  selectedUser: register.selectedUser,
  loading: register.loading,
  registering: register.registering,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onfetchUser: (type, localId) => dispatch(fetchUserInfos(type, localId)),
    onLoadUser: (user, type, localId) =>
      dispatch(updateUser(user, type, localId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AtualizacaoCadastro);
