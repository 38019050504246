import React, { Component } from "react";
import { Redirect } from "react-router";
import Header from "../../../utils/template/Header/Header";
import Logo from "../../../utils/template/Logo/Logo";
import Menu from "../../../utils/template/Menu/Menu";
import { connect } from "react-redux";
import { editReport } from "../../../utils/store/actions/reportsActions";
import { info, createObject } from "../constantes";
import {
  Dropdown,
  Button,
  DatePicker,
  Checkbox,
  Input,
  Select,
  message,
  Menu as DropdownMenu,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import moment from "moment";
const { TextArea } = Input;
const { Option } = Select;

const initialReport = {
  id: null,
  student: null,
  tutor: null,
  date: null,
  startOfClass: null,
  endOfClass: null,
  subject: [],
  content: null,
  workedInClass: [],
  behavior: [],
  perfomance: null,
  observation: null,
  given: null,
  orientation: null,
  verified: false,
  read: false,
  messages: [],
};

const initialState = {
  date: null,
  subjects: [],
  behaviors: [],
  workedInClass: [],
  report: initialReport,
  loading: true,
  sending: false,
  redirect: false,
  error: {
    student: false,
    tutor: false,
    date: false,
    time: false,
    subject: false,
    content: false,
    workedInClass: false,
    behavior: false,
    perfomance: false,
    given: false,
    orientation: false,
    observation: false
  }
};

class Edicao extends Component {
  state = initialState;

  //Seta o relatório do estado da tela como o selecionado pelo usuário na página de Buscas
  componentDidMount() {
    const report = this.findReport();
    const subjects = createObject(info.subjects);
    const behaviors = createObject(info.behaviors);
    const workedInClass = createObject(info.workedInClass);
    //Confere quais matérias, comportamentos e itens trabalhados vieram do relatório e marca as checkboxes no array que tem todos os itens
    const checkedSubjects = this.checkItem(subjects, report.subject);
    const checkedBehaviors = this.checkItem(behaviors, report.behavior);
    const checkedWorkedInClass = this.checkItem(
      workedInClass,
      report.workedInClass
    );
    this.setState({
      ...this.state,
      report: report,
      subjects: checkedSubjects,
      behaviors: checkedBehaviors,
      workedInClass: checkedWorkedInClass,
      subjects: subjects,
      behaviors: behaviors,
      workedInClass: workedInClass,
      date: moment(report.date, "DD/MM/YYYY"),
      loading: false,
    });
  }

  // Após o envio do relatório (mudança do estado) redireciona o usuário para a página de Buscas
  componentDidUpdate = (prevProps) => {
    if (prevProps.loading && !this.props.loading) {
      this.setState({ ...initialState, redirect: true });
    }
  };

  //Encontra o relatório selecionado pelo usuário na store
  findReport = () => {
    //O id do relatório deve vir dos parâmetros passados na navegação
    const id = this.props.match.params.id;
    let thisReport = {};
    //O relatório correto é aquele que tem o mesmo id passado como parâmetro
    let reports = this.props.reports.concat(this.props.oldReports)
    reports.forEach((report) => {
      if (report.id === id) {
        thisReport = { ...report };
      }
    });
    return thisReport;
  };  

  //Recebe um array com todos os itens (initialArray) e outro com os dados que virem do banco de dados (paramsArray)
  //Compara os nomes dos itens e marca (isChecked=true) aqueles estão no paramsArray.
  //A consequência é que nesta tela as checkboxes estarão marcadas.
  checkItem = (initialArray, paramsArray) => {
    paramsArray.forEach((item) => {
      initialArray.forEach((element) => {
        if (element.name === item) {
          element.isChecked = true;
        }
      });
    });
    return initialArray;
  };

  clear = () => {
    this.setState({ ...initialState });
  };

  getNames = (array) => {
    let names = [];
    array.forEach((element) => {
      if (element.isChecked) {
        names.push(element.name);
      }
    });
    return names;
  };

  handleDate = (date, stringDate) => {
    const state = { ...this.state };
    state.date = date;
    state.report.date = stringDate;
    this.setState({ ...state });
  };

  handleTime(e, option) {
    const state = { ...this.state };
    if (option === "start") {
      state.report.startOfClass = info.time[e.key];
      state.report.endOfClass = info.time[parseInt(e.key, 10) + 2];
    } else {
      state.report.endOfClass = info.time[e.key];
    }
    this.setState({ ...state });
  }

  //Funções para lidar com o toggle das checkboxes das matérias, comportamentos e quesitos trabalhados em aula
  toggleMateria = (e) => {
    const state = { ...this.state };
    state.subjects.forEach((subject) => {
      if (subject.name === e.target.id) {
        subject.isChecked = !subject.isChecked;
      }
    });
    this.setState({ ...state });
  };
  toggleBehavior = (e) => {
    const state = { ...this.state };
    state.behaviors.forEach((behavior) => {
      if (behavior.name === e.target.id) {
        behavior.isChecked = !behavior.isChecked;
      }
    });
    this.setState({ ...state });
  };
  toggleWorkedInClass = (e) => {
    const state = { ...this.state };
    state.workedInClass.forEach((item) => {
      if (item.name === e.target.id) {
        item.isChecked = !item.isChecked;
      }
    });
    this.setState({ ...state });
  };

  handleMenuStudent(e) {
    const state = { ...this.state };
    state.report.student = e.key;
    this.setState({ ...state });
  }

  menuStudents = (
    <DropdownMenu onClick={(e) => this.handleMenuStudent(e)}>
      {this.props.students.map((student) =>
        student !== this.state.report.student ? (
          <DropdownMenu.Item key={`${student}`}>{student}</DropdownMenu.Item>
        ) : null
      )}
    </DropdownMenu>
  );

  handleMenuTutor(e) {
    const state = { ...this.state };
    state.report.tutor = e.key;
    this.setState({ ...state });
  }

  menuTutor = (
    <DropdownMenu onClick={(e) => this.handleMenuTutor(e)}>
      {this.props.tutors.map((tutor) =>
        tutor !== this.state.report.student ? (
          <DropdownMenu.Item key={`${tutor}`}>{tutor}</DropdownMenu.Item>
        ) : null
      )}
    </DropdownMenu>
  );

  menuStartOfClass = (
    <DropdownMenu onClick={(e) => this.handleTime(e, "start")}>
      {info.time.map((time, index) =>
        time !== this.state.report.startOfClass ? (
          <DropdownMenu.Item key={`${index}`}>{time}</DropdownMenu.Item>
        ) : null
      )}
    </DropdownMenu>
  );

  menuEndOfClass = (
    <DropdownMenu onClick={(e) => this.handleTime(e, "end")}>
      {info.time.map((time, index) =>
        time !== this.state.report.endOfClass ? (
          <DropdownMenu.Item key={`${index}`}>{time}</DropdownMenu.Item>
        ) : null
      )}
    </DropdownMenu>
  );

  handleChange = (e, option) => {
    let state = { ...this.state };
    switch (option) {
      case "content":
        state.report.content = e.target.value;
        this.setState({ ...state });
        break;
      case "perfomance":
        state.report.perfomance = e.target.value;
        this.setState({ ...state });
        break;
      case "observation":
        state.report.observation = e.target.value;
        this.setState({ ...state });
        break;
      case "given":
        state.report.given = e.target.value;
        this.setState({ ...state });
        break;
      case "orientation":
        state.report.orientation = e.target.value;
        this.setState({ ...state });
        break;
      case "student":
        state.report.student = e;
        break;
      case "tutor":
        state.report.tutor = e;
        break;
      default:
        this.setState({ ...state });
        break;
    }
  };

  checkRequired = () => {
    let state = {...this.state}
    let flag = false
    const behaviors = this.getNames(state.behaviors)
    const subjects = this.getNames(state.subjects)
    const workedInClass = this.getNames(state.workedInClass)
    if (state.report.student.trim() === ""){
      state.error.student = true;
      flag = true
    } else {
      state.error.student = false;
    }
    if (state.report.tutor.trim() === ""){
      state.error.tutor = true;
      flag = true
    } else {
      state.error.tutor = false;
    }
    if (state.report.date.trim() === ""){
      state.error.date = true;
      flag = true
    } else {
      state.error.date = false;
    }
    if (state.report.startOfClass.trim() === "" || state.report.endOfClass.trim() === ""){
      state.error.time = true;
      flag = true
    } else {
      state.error.time = false;
    }
    if (subjects.length === 0){
      state.error.subject = true;
      flag = true
    } else {
      state.error.subject = false;
    }
    if (state.report.content.trim() === ""){
      state.error.content = true;
      flag = true
    } else {
      state.error.content = false;
    }
    if (workedInClass.length === 0){
      state.error.workedInClass = true;
      flag = true
    } else {
      state.error.workedInClass = false;
    }
    if (behaviors.length === 0){
      state.error.behavior = true;
      flag = true
    } else {
      state.error.behavior = false;
    }
    if (state.report.perfomance.trim() === ""){
      state.error.perfomance = true;
      flag = true
    } else {
      state.error.perfomance = false;
    }
    if (state.report.observation.trim() === ""){
      state.error.observation = true;
      flag = true
    } else {
      state.error.observation = false;
    }
    if (state.report.given.trim() === ""){
      state.error.given = true;
      flag = true
    } else {
      state.error.given = false;
    }
    if (state.report.orientation.trim() === ""){
      state.error.orientation = true;
      flag = true
    } else {
      state.error.orientation = false;
    }

    if(flag){
      message.error("Todos os campos obrigatórios precisam ser preenchidos.")
      this.setState({...state})
    } else {
      this.setState({...state})
      this.editReport()
    }
  }

  editReport = () => {
    this.setState({ sending: true });
    //O novo relatório, enviado para a store, é aquele que está no state da tela
    const report = { ...this.state.report };
    //Verifica quais materias, comportamentos e itens dados foram selecionados e envia somente o nome
    report.subject = this.getNames(this.state.subjects);
    report.behavior = this.getNames(this.state.behaviors);
    report.workedInClass = this.getNames(this.state.workedInClass);
    //Como a edição se completa nessa função, alterar o status de verified para true
    report.verified = true;
    const string = this.state.report.date + " " + this.state.report.startOfClass;
    const date = moment(string, "DD/MM/YYYY hh:mm").toDate();    
    report.timestamp = date.getTime();
    this.props.onEditReport(report);
  };

  render() {
    return this.state.redirect ? (
      <Redirect to="/relatorios" />
    ) : this.state.loading ? null : (
      <div className="page edicao">
        {/*--------------------------------- HEADER ----------------------------------------- */}
        <Header />
        <Logo />
        <Menu />
        <div className="content mt-4 ml-4">
          <h1 className="title">Editar relatório de aula</h1>
          {/*--------------------------------- ALUNO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Aluno</h3>
            </div>
            <div className={`sectionContent ${this.state.error.student ? "error" : ""}`}>
              <Select
                showSearch
                placeholder="Selecione o aluno"
                defaultValue={this.state.report.student}
                optionFilterProp="children"
                onChange={(value) => this.handleChange(value, "student")}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.props.users.map((user) =>
                  user.type === "aluno" ? (
                    <Option key={user.localId} value={user.name}>
                      {user.name}
                    </Option>
                  ) : null
                )}
              </Select>
            </div>
          </div>
          {/*--------------------------------- TUTOR ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Tutor</h3>
            </div>
            <div className={`sectionContent ${this.state.error.tutor ? "error" : ""}`}>
              <Select
                showSearch
                placeholder="Selecione o tutor"
                defaultValue={this.state.report.tutor}
                optionFilterProp="children"
                onChange={(value) => this.handleChange(value, "tutor")}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.props.users.map((user) =>
                  user.type === "tutor" ||
                  user.type === "orientador" ||
                  user.type === "admin" ? (
                    <Option key={user.localId} value={user.name}>
                      {user.name}
                    </Option>
                  ) : null
                )}
              </Select>
            </div>
          </div>
          {/*--------------------------------- DATA ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Data da aula</h3>
            </div>
            <div className={`sectionContent ${this.state.error.date ? "error" : ""}`}>
              <DatePicker
                defaultValue={this.state.date}
                format="DD/MM/YYYY"
                size="large"
                onChange={(date, dateString) =>
                  this.handleDate(date, dateString)
                }
              />
            </div>
          </div>
          {/*--------------------------------- HORÁRIO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Horário da aula</h3>
            </div>
            <div className={`sectionContent ${this.state.error.time ? "error" : ""}`}>
              <Dropdown overlay={this.menuStartOfClass} className="time">
                <Button size="default">
                  {this.state.report.startOfClass} <DownOutlined />
                </Button>
              </Dropdown>
              <Dropdown overlay={this.menuEndOfClass} className="time">
                <Button size="default">
                  {this.state.report.endOfClass} <DownOutlined />
                </Button>
              </Dropdown>
            </div>
          </div>
          {/*--------------------------------- MATÉRIAS ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Matérias</h3>
            </div>
            <div className={`sectionContent ${this.state.error.subject ? "error" : ""}`}>
              {this.state.subjects.map((subject, index) => (
                <div className="checkbox" key={`subject${index}`}>
                  <Checkbox
                    checked={subject.isChecked}
                    id={subject.name}
                    onChange={(e) => this.toggleMateria(e)}
                  >
                    {subject.name}
                  </Checkbox>
                </div>
              ))}
            </div>
          </div>
          {/*--------------------------------- CONTEÚDO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Conteúdo</h3>
            </div>
            <div className={`sectionContent ${this.state.error.content ? "error" : ""}`}>
              <TextArea
                value={this.state.report.content}
                onChange={(e) => this.handleChange(e, "content")}
              />
            </div>
          </div>
          {/*--------------------------------- O QUE FOI TRABALHADO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">O que foi trabalhado</h3>
            </div>
            <div className={`sectionContent ${this.state.error.workedInClass ? "error" : ""}`}>
              {this.state.workedInClass.map((workedInClass, index) => (
                <div className="checkbox" key={`workedInClass${index}`}>
                  <Checkbox
                    checked={workedInClass.isChecked}
                    id={workedInClass.name}
                    onChange={(e) => this.toggleWorkedInClass(e)}
                  >
                    {workedInClass.name}
                  </Checkbox>
                </div>
              ))}
            </div>
          </div>
          {/*--------------------------------- COMPORTAMENTO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Comportamento</h3>
            </div>
            <div className={`sectionContent ${this.state.error.behavior ? "error" : ""}`}>
              {this.state.behaviors.map((behavior, index) => (
                <div className="checkbox" key={`behavior${index}`}>
                  <Checkbox
                    checked={behavior.isChecked}
                    id={behavior.name}
                    onChange={(e) => this.toggleBehavior(e)}
                  >
                    {behavior.name}
                  </Checkbox>
                </div>
              ))}
            </div>
          </div>
          {/*--------------------------------- DESEMPENHO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Desempenho do aluno</h3>
            </div>
            <div className={`sectionContent larger-text-area ${this.state.error.perfomance ? "error" : ""}`}>
              <TextArea
                value={this.state.report.perfomance}
                onChange={(e) => this.handleChange(e, "perfomance")}
              />
            </div>
          </div>
          {/*--------------------------------- OBSERVAÇÃO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Observação</h3>
            </div>
            <div className={`sectionContent larger-text-area ${this.state.error.observation ? "error" : ""}`}>
              <TextArea
                value={this.state.report.observation}
                onChange={(e) => this.handleChange(e, "observation")}
              />
            </div>
          </div>
          {/*--------------------------------- DADO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">O que foi dado</h3>
            </div>
            <div className={`sectionContent ${this.state.error.given ? "error" : ""}`}>
              <TextArea
                value={this.state.report.given}
                onChange={(e) => this.handleChange(e, "given")}
              />
            </div>
          </div>
          {/*--------------------------------- ORIENTAÇÃO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Orientação para a próxima aula</h3>
            </div>
            <div className={`sectionContent ${this.state.error.orientation ? "error" : ""}`}>
              <TextArea
                value={this.state.report.orientation}
                onChange={(e) => this.handleChange(e, "orientation")}
              />
            </div>
          </div>
          {/*--------------------------------- ENVIAR ----------------------------------------- */}
          <Button
            type="primary"
            className="submit"
            onClick={(e) => this.checkRequired()}
            loading={this.state.sending}
          >
            Enviar
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ reports, register }) => ({
  reports: reports.reports,
  oldReports: reports.oldReports,
  loading: reports.isLoading,
  students: register.students,
  tutors: register.tutors,
  users: register.users,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onEditReport: (report) => dispatch(editReport(report)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Edicao);
