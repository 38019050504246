import {
  FETCHING_INFO,
  INFO_LOADED,
  NEW_EVENT,
  SET_VISIBLE,
  SET_USER_CLASSES,
  SET_USER_PLANS
} from "../actions/actionTypes";

//Estado inicial, zerado.
const initialState = {
  current: [],
  permanent: [],
  visible: [],
  userClasses: [],
  userPlans: [],
  loading: false,
};

//------------------------------------------- REDUCER ---------------------------------------------------------
const reducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case NEW_EVENT:
      return {
        ...newState,
        current: action.payload.current,
        permanent: action.payload.permanent
          ? action.payload.permanent
          : state.permanent,
      };
    case SET_VISIBLE:
      return {
        ...state,
        visible: action.payload,
      };
    case SET_USER_CLASSES: 
    return {
      ...state,
      userClasses: action.payload,
      loading: false
    }
    case SET_USER_PLANS:
      return {
        ...state,
        userPlans: action.payload,
      };
    case FETCHING_INFO:
      return {
        ...state,
        loading: true,
      };
    case INFO_LOADED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
