import React, { Component } from "react";
import { Button } from "antd";
import { resetPassword } from "../../../utils/store/actions/userActions";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Lock from "@material-ui/icons/Lock";
import IconButton from "@material-ui/core/IconButton";
import { message } from "antd";
import queryString from 'query-string'

class Login extends Component {
  state = {
    password: "",
    confirmation: "",
    error: {
      password: false,
      confirmation: false,
    },
    show: {
      password: false,
      confirmation: false,
    },
    query: {
        mode: "",
        oobCode: ""
    }
  };

  componentDidMount() {
    const values = queryString.parse(this.props.location.search)
    this.setState({...this.state, query: {mode: values.mode, oobCode: values.oobCode}})
  }

  makeRequest = () => {
    if (this.state.password.length < 6){
        message.error('A senha precisa ter no mínimo 6 caracteres')
        this.setState({ ...this.state, error: {password: true, confirmation: true} });
    } else if (this.state.password !== this.state.confirmation) {
        message.error("A senha e sua confirmação precisam ser iguais.");
        this.setState({ ...this.state, error: {password: true, confirmation: true} });
    } else {
        resetPassword(this.state.query.oobCode, this.state.password);
        this.setState({
          password: "",
          confirmation: "",
          error: {
            password: false,
            confirmation: false,
          },
          show: {
            password: false,
            confirmation: false,
          },
          query: {
              mode: "",
              oobCode: ""
          }
        })
    }
  };

  handleClickShowPassword = (key) => {
    let state = { ...this.state };
    state.show[key] = !state.show[key];
    this.setState({ ...state });
  };

  handleKeyPress = (e) => {
  };

  render() {
    return (
      <div className="login">
        <div className="form">
          <div className="info-password">
            <p>Insira sua nova senha (mínimo de seis caracteres)</p>
          </div>
          <Input
            placeholder="Nova senha"
            value={this.state.password}
            onChange={(e) => this.setState({...this.state, password: e.target.value })}
            fullWidth
            type={this.state.show.password ? "text" : "password"}
            error={this.state.error.password}
            helperText="Campo obrigatório."
            startAdornment={
              <InputAdornment position="start">
                <Lock />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => this.handleClickShowPassword('password')}>
                  {this.state.show.password ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          <Input
            placeholder="Confirme a senha"
            value={this.state.confirmation}
            onChange={(e) => this.setState({...this.state, confirmation: e.target.value })}
            onKeyPress={(e) => this.handleKeyPress(e)}
            fullWidth
            type={this.state.show.confirmation  ? "text" : "password"}
            error={this.state.error.confirmation}
            helperText="Campo obrigatório."
            startAdornment={
              <InputAdornment position="start">
                <Lock />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => this.handleClickShowPassword('confirmation')}>
                  {this.state.show.confirmation ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          <div>
            <Button
              onClick={this.makeRequest}
              type="link"
              size="large"
            >
              Enviar
            </Button>
          </div>
          <div></div>
        </div>
      </div>
    );
  }
}

export default Login;
