import React from "react";
import { Button, Modal, Tag, Checkbox, Divider } from "antd";
import Seletor from "./Seletor";

export default ({
  visible,
  onOk,
  onCancel,
  students,
  tutors,
  selected,
  unavailabilities,
  handleChange,
  allSelected,
  allUnavailabilities,
  mySchedule,
  holidays
}) => {
  return (
    <Modal
      title="Agendas"
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      okText="Ok"
      cancelText="Cancelar"
      centered
      maskClosable
    >
      <div className="modal-schedules-section">
        <div className="row mx-0 align-items-center mb-2">
          <Checkbox
            checked={mySchedule}
            onClick={() => handleChange(null, "selectMySchedule")}
            className="upper-checkbox"
          >
            Minha agenda
          </Checkbox>
          <Checkbox
            checked={holidays}
            onClick={() => handleChange(null, "selectHolidays")}
            className="upper-checkbox"
          >
            Feriados
          </Checkbox>
        </div>
      </div>
      <Seletor
        title="Tutores"
        type="tutor"
        selected={selected}
        handleChange={handleChange}
        list={tutors}
        showSelectAll
        selectAll={allSelected}
        eventType={"calendar"}
      />
      <Seletor
        title="Alunos"
        type="student"
        selected={selected}
        handleChange={handleChange}
        list={students}
        eventType={"calendar"}
      />
      <div className="modal-schedules-section">
        <div className="row mx-0 align-items-center mb-2">
          <p className="modal-section-title mb-0">Agendas selecionadas</p>
          <Button
            type="text"
            className="schedules-clear"
            onClick={() => handleChange(null, "clearSchedules")}
          >
            Limpar
          </Button>
        </div>
        <div className="row mx-0">
          {selected.map((item) => {
            return (
              <Tag
                closable
                key={`tag-schedule-${item}`}
                onClose={() => handleChange(item, "removeSchedule")}
              >
                {item}
              </Tag>
            );
          })}
        </div>
      </div>
      <Divider />
      <Seletor
        title="Indisponibilidades"
        type="tutor"
        selected={unavailabilities}
        handleChange={handleChange}
        list={tutors}
        showSelectAll
        selectAll={allUnavailabilities}
        eventType={"unavailability"}
      />
      <div className="modal-schedules-section">
        <div className="row mx-0 align-items-center mb-2">
          <p className="modal-section-title mb-0">Indisponibilidades selecionadas</p>
          <Button
            type="text"
            className="schedules-clear"
            onClick={() => handleChange(null, "clearUnavailabilities")}
          >
            Limpar
          </Button>
        </div>
        <div className="row mx-0">
          {unavailabilities.map((item) => {
            return (
              <Tag
                closable
                key={`tag-schedule-${item}`}
                onClose={() => handleChange(item, "removeUnavailability")}
              >
                {item}
              </Tag>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};
