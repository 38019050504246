import {
  FETCHING_INFO,
  INFO_LOADED,
  NEW_EVENT,
  SET_VISIBLE,
  SET_USER_CLASSES,
  SET_USER_PLANS,
} from "../actions/actionTypes";
import axios from "axios";
import {
  createNewEvent,
  createEventObject,
  eraseEvents,
} from "./calendarFunctions";
import { setTutors } from "./registerActions";
import moment from "moment";
import _ from "lodash";
import { message } from "antd";

const functionsBaseURL =
  "https://us-central1-banco-de-dados-realize.cloudfunctions.net";

export const uploadNewEvents = (details) => {
  return (dispatch, getState) => {
    const events = getState().calendar.current;
    const token = getState().user.userValidation.token;
    const newEvents = createNewEvent(details);
    let requests = [];
    //Se for uma aula, adiciona o evento à agenda do tutor e aluno no bd. Atualiza a cor relacionada ao tutor.
    if (details.type === "class") {
      let object = _.omit(details.tutor, ["answered", "accepted"]);
      newEvents.forEach((event) => {
        requests.push(
          axios.put(
            `/agendas/${details.tutor.localId}/aulas/${event.details.id}.json?auth=${token}`,
            event
          )
        );
        requests.push(
          axios.put(
            `/agendas/${details.student.localId}/aulas/${event.details.id}.json?auth=${token}`,
            event
          )
        );
      });

      requests.push(
        axios.patch(`/usuarios/${details.tutor.localId}.json?auth=${token}`, {
          ...object,
          color: details.color[details.creator.localId],
        })
      );

      axios
        .all(requests)
        //Após atualização do bd, atualiza a store com os eventos e seta todos os calendários de tutores como visíveis.
        .then(() => {
          let tutors = getState().register.tutors.filter(
            (tutor) => tutor.localId !== details.tutor.localId
          );
          tutors = [
            ...tutors,
            {
              ...object,
              color: details.color[details.creator.localId],
            },
          ];
          dispatch(setTutors(tutors));
          dispatch(fetchUserClasses("tutor", details.tutor.localId));
          if (details.tutor.email) {
            axios({
              url: "calendarNotificationEmail",
              baseURL: functionsBaseURL,
              method: "post",
              data: {
                to: details.tutor,
                from: { name: details.creator.name },
                details: details,
                option: "newClass",
                date: moment(details.start).format("DD/MM/YYYY"),
                time: details.allDay
                  ? "00:00"
                  : moment(details.start).format("HH:mm"),
              },
            })
              .then(() => {
                if (details.tutor.tokenNotification) {
                  axios({
                    url: "calendarPushNotifications",
                    baseURL: functionsBaseURL,
                    method: "post",
                    data: {
                      expoPushToken: [details.tutor.tokenNotification],
                      details: details,
                      option: "newClass",
                      date: moment(details.start).format("DD/MM/YYYY"),
                    },
                  }).catch((err) => {
                    console.log(err);
                    message.error(
                      "Erro ao enviar push notification de criação de aula"
                    );
                  });
                }
              })
              .catch((err) => {
                console.log(err);
                message.error("Erro ao enviar e-mails de criação de aula");
              });
          }
        })
        .catch((err) => {
          console.log(err);
          message.error("Erro ao criar aulas");
        });
      //Se for um evento, adiciona o evento à agenda de todos os participantes
    } else if (details.type === "event") {
      // let notifications = [];
      let emailRequests = [];
      let notifications = [];
      //Uma requisição para cada participante
      details.participants.forEach((participant) => {
        newEvents.forEach((event) => {
          requests.push(
            axios.put(
              `/agendas/${participant.localId}/eventos/${event.details.id}.json?auth=${token}`,
              event
            )
          );
        });
        if (!participant.creator) {
          if (participant.tokenNotification) {
            notifications.push(
              axios({
                url: "calendarPushNotifications",
                baseURL: functionsBaseURL,
                method: "post",
                data: {
                  expoPushToken: [participant.tokenNotification],
                  details: details,
                  option: "newEvent",
                  date: moment(details.start).format("DD/MM/YYYY"),
                },
              })
            );
          }
          if (participant.email) {
            emailRequests.push(
              axios({
                url: "calendarNotificationEmail",
                baseURL: functionsBaseURL,
                method: "post",
                data: {
                  to: participant,
                  from: { name: details.creator.name },
                  details: details,
                  option: "newEvent",
                  date: moment(details.start).format("DD/MM/YYYY"),
                  time: details.allDay
                    ? "00:00"
                    : moment(details.start).format("HH:mm"),
                },
              })
            );
          }
        }
      });
      //Faz todas as requisições simultaneamente.
      axios
        .all(requests)
        //Após atualização do bd, atualiza a store com os eventos.
        .then(() => {
          dispatch(setEvent(null, events.concat(newEvents)));
          if (emailRequests.length > 0) {
            axios
              .all(emailRequests)
              .then(() => {
                axios.all(notifications).catch((err) => {
                  console.log(err);
                  message.error(
                    "Erro ao enviar push notifications de criação de evento"
                  );
                });
              })
              .catch((err) => {
                console.log(err);
                message.error("Erro ao enviar e-mails de criação de evento");
              });
          }
        })
        .catch((err) => {
          console.log(err);
          message.error("Erro ao criar evento.");
        });
    } else {
      newEvents.forEach((event) => {
        requests.push(
          axios.put(
            `/agendas/${details.creator.localId}/feriados/${event.details.id}.json?auth=${token}`,
            event
          )
        );
      });
      axios
        .all(requests)
        .then(() => {
          dispatch(setEvent(null, events.concat(newEvents)));
        })
        .catch((err) => {
          console.log(err);
          message.error("Erro ao criar feriado.");
        });
    }
  };
};

export const fetchUserEvents = () => {
  let requests = [];
  return (dispatch, getState) => {
    dispatch(loadingInfo());
    const localId = getState().user.localId;
    const token = getState().user.userValidation.token;
    requests.push(axios.get(`/agendas/${localId}/aulas.json?auth=${token}`));
    requests.push(axios.get(`/agendas/${localId}/eventos.json?auth=${token}`));
    requests.push(axios.get(`/agendas/${localId}/feriados.json?auth=${token}`));

    axios.all(requests).then(
      axios.spread((...responses) => {
        let items = [];
        responses.forEach((res) => {
          const rawData = res.data;
          for (let key in rawData) {
            const event = rawData[key];
            if (!event.deleted && event.details.type !== "unavailable") {
              event.mainInfo.start = new Date(event.mainInfo.start);
              event.mainInfo.end = new Date(event.mainInfo.end);
              items.push({ ...event });
            }
          }
        });
        dispatch(setEvent(items, items));
      })
    );
  };
};

export const fetchAllClasses = () => {
  return (dispatch, getState) => {
    let current = getState().calendar.current;
    const tutors = getState().register.tutors;
    const thisUser = getState().user;
    const token = getState().user.userValidation.token;

    const thisUserEvents = current.filter(
      (event) =>
        event.details.type === "event" &&
        event.details.participants.some(
          (participant) => participant.localId === thisUser.localId
        )
    );

    let requests = [];
    //Checa se, entre os calendários na store, já existem eventos do usuário
    tutors.forEach((tutor) => {
      requests.push(
        axios.get(`/agendas/${tutor.localId}/aulas.json?auth=${token}`)
      );
    });
    axios
      .all(requests)
      .then(
        axios.spread((...responses) => {
          let allClasses = [];
          responses.forEach((res) => {
            const events = res.data;
            for (let key in events) {
              const classes = events[key];
              //Só adiciona se o evento não estiver na store, já que o mesmo evento pode pertencer a agendas de diferentes usuários.
              if (!classes.deleted) {
                classes.mainInfo.start = new Date(classes.mainInfo.start);
                classes.mainInfo.end = new Date(classes.mainInfo.end);
                allClasses.push({ ...classes });
              }
            }
          });
          dispatch(setEvent(null, thisUserEvents.concat(allClasses)));
        })
      )
      .catch((err) => {
        console.log(err);
        message.error("Erro ao recuperar as agendas.");
      });
  };
};

export const fetchAllUnavailabilities = () => {
  return (dispatch, getState) => {
    let current = getState().calendar.current;
    const tutors = getState().register.tutors;
    const thisUser = getState().user;
    const token = getState().user.userValidation.token;

    const currentEvents = current.filter(
      (event) => event.details.type !== "unavailability"
    );

    let requests = [];
    //Checa se, entre os calendários na store, já existem eventos do usuário
    tutors.forEach((tutor) => {
      requests.push(
        axios.get(
          `/agendas/${tutor.localId}/indisponibilidades.json?auth=${token}`
        )
      );
    });
    axios
      .all(requests)
      .then(
        axios.spread((...responses) => {
          let allClasses = [];
          responses.forEach((res) => {
            const events = res.data;
            for (let key in events) {
              const classes = events[key];
              //Só adiciona se o evento não estiver na store, já que o mesmo evento pode pertencer a agendas de diferentes usuários.
              if (!classes.deleted) {
                classes.mainInfo.start = new Date(classes.mainInfo.start);
                classes.mainInfo.end = new Date(classes.mainInfo.end);
                allClasses.push({ ...classes });
              }
            }
          });
          dispatch(setEvent(null, currentEvents.concat(allClasses)));
        })
      )
      .catch((err) => {
        console.log(err);
        message.error("Erro ao recuperar as agendas de indisponibilidades.");
      });
  };
};

export const updateClass = (details, sendNotification) => {
  return (dispatch, getState) => {
    const updatedEvent = createEventObject(details);
    const token = getState().user.userValidation.token;
    let current = getState().calendar.current.filter(
      (event) => event.details.id !== details.id
    );
    let newList = [...current, updatedEvent];
    axios
      .all([
        axios.patch(
          `/agendas/${details.tutor.localId}/aulas/${details.id}.json?auth=${token}`,
          updatedEvent
        ),
        axios.patch(
          `/agendas/${details.student.localId}/aulas/${details.id}.json?auth=${token}`,
          updatedEvent
        ),
      ])
      .catch((err) => {
        console.log(err);
        message.error("Erro ao atualizar o banco de dados");
      })
      .then((res) => {
        dispatch(setEvent(null, newList));
        if (details.tutor.email && sendNotification) {
          axios({
            url: "calendarNotificationEmail",
            baseURL: functionsBaseURL,
            method: "post",
            data: {
              to: details.tutor,
              from: { name: "" },
              details: details,
              option:
                details.confirmed === "yes" ? "newClass" : "classCanceled",
              date: moment(details.start).format("DD/MM/YYYY"),
              time: details.allDay
                ? "00:00"
                : moment(details.start).format("HH:mm"),
            },
          })
            .catch((err) => {
              console.log(err);
              message.error("Erro ao enviar e-mails de notificação de evento");
            })
            .then((res) => {
              if (details.tutor.tokenNotification && sendNotification) {
                axios({
                  url: "calendarPushNotifications",
                  baseURL: functionsBaseURL,
                  method: "post",
                  data: {
                    expoPushToken: [details.tutor.tokenNotification],
                    details: details,
                    option:
                      details.confirmed === "yes"
                        ? "newClass"
                        : "classCanceled",
                    date: moment(details.start).format("DD/MM/YYYY"),
                  },
                }).catch((err) => {
                  console.log(err);
                  message.error(
                    "Erro ao enviar push notification de criação de aula"
                  );
                });
              }
            });
        }
      });
  };
};

export const updateEvents = (
  details,
  isSingle,
  deletedParticipants,
  sendNotification
) => {
  return (dispatch, getState) => {
    const events = getState().calendar.current;
    const token = getState().user.userValidation.token;
    const user = getState().user.name;
    let requests = [];
    let emails = [];
    let current = [];
    let notifications = [];

    let updatedEvents = null;
    let erasedEvents = null;
    let newEvents = null;

    if (isSingle) {
      //Cria novo evento, com os detalhes modificados
      updatedEvents = [createEventObject(details)];
      if (deletedParticipants && deletedParticipants.length > 0) {
        erasedEvents = [createEventObject(details, true)];
      }
      newEvents = events.filter(
        (event) => event.details.id !== updatedEvents[0].details.id
      );
    } else {
      //Cria novos eventos, com os detalhes modificados
      updatedEvents = createNewEvent(details);
      if (deletedParticipants && deletedParticipants.length > 0) {
        //Cria novos eventos, com os detalhes modificados
        erasedEvents = createNewEvent(details, true);
      }
      newEvents = eraseEvents(events, details);
    }
    //----------------------------------------------------- AULAS -----------------------------------------------------------
    if (details.type === "class") {
      let object = _.omit(details.tutor, ["answered", "accepted"]);
      //Requests para atualizar agenda de alunos e tutores
      updatedEvents.forEach((updatedEvent) => {
        const id = updatedEvent.details.id;
        requests.push(
          axios.put(
            `/agendas/${details.tutor.localId}/aulas/${id}.json?auth=${token}`,
            updatedEvent
          )
        );
        requests.push(
          axios.put(
            `/agendas/${details.student.localId}/aulas/${id}.json?auth=${token}`,
            updatedEvent
          )
        );
      });

      requests.push(
        axios.patch(`/usuarios/${details.tutor.localId}.json?auth=${token}`, {
          ...object,
          color: details.color[details.creator.localId],
        })
      );

      if (sendNotification) {
        if (details.tutor.email) {
          emails.push(
            axios({
              url: "calendarNotificationEmail",
              baseURL: functionsBaseURL,
              method: "post",
              data: {
                to: details.tutor,
                from: { name: user },
                details: details,
                option: "changeInClass",
                date: moment(details.start).format("DD/MM/YYYY"),
                time: details.allDay
                  ? "00:00"
                  : moment(details.start).format("HH:mm"),
              },
            })
          );
        }
        if (details.tutor.tokenNotification) {
          notifications.push(
            axios({
              url: "calendarPushNotifications",
              baseURL: functionsBaseURL,
              method: "post",
              data: {
                expoPushToken: [details.tutor.tokenNotification],
                details: details,
                option: "changeInClass",
                date: moment(details.start).format("DD/MM/YYYY"),
              },
            })
          );
        }
      }
      let tutors = getState().register.tutors.filter(
        (tutor) => tutor.localId !== details.tutor.localId
      );
      tutors = [
        ...tutors,
        {
          ...object,
          color: details.color[details.creator.localId],
        },
      ];
      dispatch(setTutors(tutors));
      axios
        .all(requests)
        .catch((err) => {
          message.error("Erro ao atualizar aula.");
          console.log(err);
        })
        //Get da agenda de aulas do tutor para o qual a aula foi criada
        .then((res) => {
          axios
            .get(`/agendas/${details.tutor.localId}/aulas.json?auth=${token}`)
            .then((res) => {
              let userClasses = [];
              //Recuperação das aulas
              for (let key in res.data) {
                const classes = res.data[key];
                if (classes && !classes.deleted) {
                  classes.mainInfo.start = new Date(classes.mainInfo.start);
                  classes.mainInfo.end = new Date(classes.mainInfo.end);
                  userClasses.push({ ...classes });
                }
              }
              //Apaga todos os eventos que foram trocados
              current = newEvents.filter(
                (event) =>
                  event.details.type !== "class" ||
                  event.details.tutor.localId !== details.tutor.localId
              );
              dispatch(setEvent(null, current.concat(userClasses)));
              if (emails.length > 0) {
                axios
                  .all(emails)
                  .catch((err) => {
                    message.error(
                      "Erro ao enviar e-mails de notificação de alteração."
                    );
                    console.log(err);
                  })
                  .then(() => {
                    if (notifications.length > 0) {
                      axios
                        .all(notifications)
                        .then((res) => console.log(res))
                        .catch((err) => {
                          message.error(
                            "Erro ao enviar pushs de notificação de alteração."
                          );
                          console.log(err);
                        });
                    }
                  });
              }
            });
        });
    }
    //----------------------------------------------------- EVENTOS -----------------------------------------------------------------
    else if (details.type === "event") {
      details.participants.forEach((participant) => {
        updatedEvents.forEach((updatedEvent) => {
          const id = updatedEvent.details.id;
          requests.push(
            axios.put(
              `/agendas/${participant.localId}/eventos/${id}.json?auth=${token}`,
              updatedEvent
            )
          );
        });
        if (!participant.creator && sendNotification) {
          if (participant.email) {
            emails.push(
              axios({
                url: "calendarNotificationEmail",
                baseURL: functionsBaseURL,
                method: "post",
                data: {
                  to: participant,
                  from: { name: details.creator.name },
                  details: details,
                  option: "changeInEvent",
                  date: moment(details.start).format("DD/MM/YYYY"),
                  time: details.allDay
                    ? "00:00"
                    : moment(details.start).format("HH:mm"),
                },
              })
            );
          }
          if (participant.tokenNotification) {
            notifications.push(
              axios({
                url: "calendarPushNotifications",
                baseURL: functionsBaseURL,
                method: "post",
                data: {
                  expoPushToken: [participant.tokenNotification],
                  details: details,
                  option: "changeInEvent",
                  date: moment(details.start).format("DD/MM/YYYY"),
                },
              })
            );
          }
        }
      });
      if (deletedParticipants && deletedParticipants.length > 0) {
        deletedParticipants.forEach((participant) => {
          erasedEvents.forEach((erased) => {
            const id = erased.details.id;
            requests.push(
              axios.patch(
                `/agendas/${participant.localId}/eventos/${id}.json?auth=${token}`,
                erased
              )
            );
          });
          if (participant.localId !== details.creator.localId) {
            if (participant.email) {
              emails.push(
                axios({
                  url: "calendarNotificationEmail",
                  baseURL: functionsBaseURL,
                  method: "post",
                  data: {
                    to: participant,
                    from: { name: details.creator.name },
                    details: details,
                    option: "eventCanceled",
                    date: moment(details.start).format("DD/MM/YYYY"),
                    time: details.allDay
                      ? "00:00"
                      : moment(details.start).format("HH:mm"),
                  },
                })
              );
            }
            if (participant.tokenNotification) {
              notifications.push(
                axios({
                  url: "calendarPushNotifications",
                  baseURL: functionsBaseURL,
                  method: "post",
                  data: {
                    expoPushToken: [participant.tokenNotification],
                    details: details,
                    option: "eventCanceled",
                    date: moment(details.start).format("DD/MM/YYYY"),
                  },
                })
              );
            }
          }
        });
      }
      //Faz todas as requisições simultaneamente.
      axios
        .all(requests)
        //Após atualização do bd, faz um novo fetch com os events atualizados
        .then(() => {
          dispatch(setEvent(null, newEvents.concat(updatedEvents)));
          if (emails.length > 0) {
            axios
              .all(emails)
              .catch((err) => {
                console.log(err);
                message.error(
                  "Erro ao enviar e-mails de notificação de evento"
                );
              })
              .then(() => {
                if (notifications.length > 0) {
                  axios
                    .all(notifications)
                    .then((res) => console.log(res))
                    .catch((err) => {
                      message.error(
                        "Erro ao enviar pushs de notificação de alteração."
                      );
                      console.log(err);
                    });
                }
              });
          }
        })
        .catch((err) => {
          console.log(err);
          message.error("Erro ao atualizar evento.");
        });
    } else {
      updatedEvents.forEach((updatedEvent) => {
        const id = updatedEvent.details.id;
        requests.push(
          axios.put(
            `/agendas/${details.creator.localId}/feriados/${id}.json?auth=${token}`,
            updatedEvent
          )
        );
      });
      axios
        .all(requests)
        .then(() => {
          dispatch(setEvent(null, newEvents.concat(updatedEvents)));
        })
        .catch((err) => {
          console.log(err);
          message.error("Erro ao atualizar feriado.");
        });
    }
  };
};

export const changeTutorInClass = (details, previousTutor, isSingle) => {
  return (dispatch, getState) => {
    const token = getState().user.userValidation.token;
    let updatedEvents = null;
    let erasedEvents = null;
    if (isSingle) {
      //Cria novo evento, com os detalhes modificados
      updatedEvents = [createEventObject(details)];
      //Cria novo evento, com a chave delete para a agenda do tutor que foi excluído
      erasedEvents = [{ ...updatedEvents[0], deleted: true }];
    } else {
      //Cria novo evento, com os detalhes modificados
      updatedEvents = createNewEvent(details);
      //Cria novo evento, com a chave delete para a agenda do tutor que foi excluído
      erasedEvents = createNewEvent(details, true);
    }

    const events = getState().calendar.current;
    const user = getState().user.name;
    let requests = [];
    let emails = [];
    let notifications = [];

    let object = _.omit(details.tutor, ["answered", "accepted"]);

    //Apaga todos os eventos que foram trocados, para evitar repetições
    let current = events.filter(
      (event) =>
        event.details.type !== "class" ||
        !erasedEvents.some((item) => item.details.id === event.details.id)
    );
    //Apaga todos os eventos que estão na agenda dos tutores selecionados, para evitar repetições
    current = current.filter(
      (event) => event.details.tutor.localId !== details.tutor.localId
    );
    //----------------------------------------------------- AULA -----------------------------------------------------------------
    //Requests para atualizar agenda de alunos e tutores
    updatedEvents.forEach((updatedEvent) => {
      const id = updatedEvent.details.id;
      requests.push(
        axios.put(
          `/agendas/${details.tutor.localId}/aulas/${id}.json?auth=${token}`,
          updatedEvent
        )
      );
      requests.push(
        axios.put(
          `/agendas/${details.student.localId}/aulas/${id}.json?auth=${token}`,
          updatedEvent
        )
      );
    });

    requests.push(
      axios.patch(`/usuarios/${details.tutor.localId}.json?auth=${token}`, {
        ...object,
        color: details.color[details.creator.localId],
      })
    );

    erasedEvents.forEach((erasedEvent) => {
      const id = erasedEvent.details.id;
      requests.push(
        axios.patch(
          `/agendas/${previousTutor.localId}/aulas/${id}.json?auth=${token}`,
          erasedEvent
        )
      );
    });

    if (details.tutor.email) {
      emails.push(
        axios({
          url: "calendarNotificationEmail",
          baseURL: functionsBaseURL,
          method: "post",
          data: {
            to: details.tutor,
            from: { name: details.creator.name },
            details: details,
            option: "newClass",
            date: moment(details.start).format("DD/MM/YYYY"),
            time: details.allDay
              ? "00:00"
              : moment(details.start).format("HH:mm"),
          },
        })
      );
    }
    if (previousTutor.email) {
      emails.push(
        axios({
          url: "calendarNotificationEmail",
          baseURL: functionsBaseURL,
          method: "post",
          data: {
            to: previousTutor,
            from: { name: details.creator.name },
            details: details,
            option: "classCanceled",
            date: moment(details.start).format("DD/MM/YYYY"),
            time: details.allDay
              ? "00:00"
              : moment(details.start).format("HH:mm"),
          },
        })
      );
    }
    if (details.tutor.tokenNotification) {
      notifications.push(
        axios({
          url: "calendarPushNotifications",
          baseURL: functionsBaseURL,
          method: "post",
          data: {
            expoPushToken: [details.tutor.tokenNotification],
            details: details,
            option: "newClass",
            date: moment(details.start).format("DD/MM/YYYY"),
          },
        })
      );
    }
    if (previousTutor.tokenNotification) {
      notifications.push(
        axios({
          url: "calendarPushNotifications",
          baseURL: functionsBaseURL,
          method: "post",
          data: {
            expoPushToken: [previousTutor.tokenNotification],
            details: details,
            option: "classCanceled",
            date: moment(details.start).format("DD/MM/YYYY"),
          },
        })
      );
    }
    let tutors = getState().register.tutors.filter(
      (tutor) => tutor.localId !== details.tutor.localId
    );
    tutors = [
      ...tutors,
      {
        ...object,
        color: details.color[details.creator.localId],
      },
    ];
    dispatch(setTutors(tutors));
    axios
      .all(requests)
      .catch((err) => {
        message.error("Erro ao atualizar o banco de dados");
        console.log(err);
      })
      //Get da agenda de aulas do tutor para o qual a aula foi criada
      .then((res) => {
        axios
          .get(`/agendas/${details.tutor.localId}/aulas.json?auth=${token}`)
          .then((res) => {
            let userClasses = [];
            //Recuperação das aulas
            for (let key in res.data) {
              const classes = res.data[key];
              if (classes && !classes.deleted) {
                classes.mainInfo.start = new Date(classes.mainInfo.start);
                classes.mainInfo.end = new Date(classes.mainInfo.end);
                userClasses.push({ ...classes });
              }
            }
            dispatch(setEvent(null, current.concat(userClasses)));
            axios
              .all(emails)
              .then(() => {
                axios.all(notifications).catch((err) => {
                  message.error("Erro ao enviar pushs de notificação.");
                  console.log(err);
                });
              })
              .catch((err) => {
                message.error("Erro ao enviar e-mails de notificação.");
                console.log(err);
              });
          });
      });
    //----------------------------------------------------- EVENTO -----------------------------------------------------------------
  };
};

export const deleteEvents = (details, isSingle, sendNotification) => {
  return (dispatch, getState) => {
    const token = getState().user.userValidation.token;
    const events = getState().calendar.current;
    const user = getState().user;
    let erasedEvents = null;
    let newEvents = null;
    let requests = [];
    let emails = [];
    let notifications = [];

    if (isSingle) {
      //Cria novo evento, com os detalhes modificados
      erasedEvents = [createEventObject(details, true)];
      newEvents = events.filter(
        (event) => event.details.id !== erasedEvents[0].details.id
      );
    } else {
      //Cria novos eventos, com os detalhes modificados
      erasedEvents = createNewEvent(details, true);
      newEvents = eraseEvents(events, details);
    }
    // ---------------------------------------------------- AULAS -----------------------------------------------------------
    if (details.type === "class") {
      erasedEvents.forEach((erased) => {
        const id = erased.details.id;
        requests.push(
          axios.put(
            `/agendas/${erased.details.tutor.localId}/aulas/${id}.json?auth=${token}`,
            erased
          )
        );
        requests.push(
          axios.put(
            `/agendas/${erased.details.student.localId}/aulas/${id}.json?auth=${token}`,
            erased
          )
        );
      });
      axios
        .all(requests)
        .then(() => {
          dispatch(setEvent(null, newEvents));
          if (sendNotification) {
            if (details.tutor.email) {
              axios({
                url: "calendarNotificationEmail",
                baseURL: functionsBaseURL,
                method: "post",
                data: {
                  to: details.tutor,
                  from: { name: details.creator.name },
                  details: details,
                  option: "classCanceled",
                  date: moment(details.start).format("DD/MM/YYYY"),
                  time: details.allDay
                    ? "00:00"
                    : moment(details.start).format("HH:mm"),
                },
              }).catch((err) => {
                console.log(err);
                message.error("Erro ao enviar e-mails de criação de aula");
              });
            }
            if (details.tutor.tokenNotification) {
              axios({
                url: "calendarPushNotifications",
                baseURL: functionsBaseURL,
                method: "post",
                data: {
                  expoPushToken: [details.tutor.tokenNotification],
                  details: details,
                  option: "classCanceled",
                  date: moment(details.start).format("DD/MM/YYYY"),
                },
              }).catch((err) => {
                console.log(err);
                message.error("Erro ao enviar e-mails de criação de aula");
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
          message.error("Ocorreu um erro ao apagar as aulas");
        });
    }
    // ---------------------------------------------------- EVENTOS -----------------------------------------------------------
    else if (details.type === "event") {
      details.participants.forEach((participant) => {
        if (
          moment(details.end).isAfter(moment()) &&
          details.creator.localId === user.localId
        ) {
          erasedEvents.forEach((erased) => {
            const id = erased.details.id;
            requests.push(
              axios.patch(
                `/agendas/${participant.localId}/eventos/${id}.json?auth=${token}`,
                erased
              )
            );
          });
          if (!participant.creator) {
            if (participant.email) {
              emails.push(
                axios({
                  url: "calendarNotificationEmail",
                  baseURL: functionsBaseURL,
                  method: "post",
                  data: {
                    to: participant,
                    from: { name: details.creator.name },
                    details: details,
                    option: "eventCanceled",
                    date: moment(details.start).format("DD/MM/YYYY"),
                    time: details.allDay
                      ? "00:00"
                      : moment(details.start).format("HH:mm"),
                  },
                })
              );
            }
            if (participant.tokenNotification) {
              notifications.push(
                axios({
                  url: "calendarPushNotifications",
                  baseURL: functionsBaseURL,
                  method: "post",
                  data: {
                    expoPushToken: [participant.tokenNotification],
                    details: details,
                    option: "eventCanceled",
                    date: moment(details.start).format("DD/MM/YYYY"),
                  },
                })
              );
            }
          }
        } else {
          erasedEvents.forEach((erased) => {
            const id = erased.details.id;
            requests.push(
              axios.patch(
                `/agendas/${user.localId}/eventos/${id}.json?auth=${token}`,
                erased
              )
            );
          });
        }
      });
      axios
        .all(requests)
        .then(() => {
          dispatch(setEvent(null, newEvents));
          if (moment(details.end).isAfter(moment())) {
            if (sendNotification) {
              if (emails.length > 0) {
                axios
                  .all(emails)
                  .then((res) => {
                    console.log(res);
                    if (notifications.length > 0) {
                      axios.all(notifications).catch((err) => {
                        console.log(err);
                        message.error(
                          "Erro ao enviar pushs de notificação de exclusão de evento"
                        );
                      });
                    }
                  })
                  .catch((err) => {
                    message.error(
                      "Erro ao enviar e-mails de exclusão de evento"
                    );
                  });
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
          message.error("Ocorreu um erro ao apagar as aulas");
        });
    } else {
      erasedEvents.forEach((erased) => {
        const id = erased.details.id;
        requests.push(
          axios.put(
            `/agendas/${erased.details.creator.localId}/feriados/${id}.json`,
            erased
          )
        );
      });
      axios
        .all(requests)
        .then(() => {
          dispatch(setEvent(null, newEvents));
        })
        .catch((err) => {
          console.log(err);
          message.error("Erro ao atualizar banco de dados");
        });
    }
  };
};

export const changeOfPace = (current, previous, sendNotification) => {
  return (dispatch, getState) => {
    const events = getState().calendar.current;
    const user = getState().user.name;
    const token = getState().user.userValidation.token;
    let requests = [];
    let emails = [];
    let notifications = [];

    let updatedEvents = null;
    let erasedEvents = null;
    let newEvents = null;

    let split = current.id.split("-");
    let isSingle = parseInt(split[2]) === 1;

    //Para o novo evento sendo criado
    if (isSingle) {
      //Cria novo evento, com os detalhes modificados
      updatedEvents = [createEventObject(current)];
    } else {
      //Cria novos eventos, com os detalhes modificados
      updatedEvents = createNewEvent(current);
    }

    //Para o evento antigo sendo excluído
    split = previous.id.split("-");
    isSingle = parseInt(split[2]) === 1;
    if (isSingle) {
      erasedEvents = [createEventObject(previous, true)];
      newEvents = events.filter(
        (event) => event.details.id !== erasedEvents[0].details.id
      );
    } else {
      erasedEvents = createNewEvent(previous, true);
      newEvents = eraseEvents(events, previous);
    }
    //----------------------------------------------------- AULAS -----------------------------------------------------------
    if (current.type === "class") {
      let object = _.omit(current.tutor, ["answered", "accepted"]);
      //Requests para atualizar agenda de alunos e tutores
      updatedEvents.forEach((updatedEvent) => {
        const id = updatedEvent.details.id;
        requests.push(
          axios.put(
            `/agendas/${current.tutor.localId}/aulas/${id}.json?auth=${token}`,
            updatedEvent
          )
        );
        requests.push(
          axios.put(
            `/agendas/${current.student.localId}/aulas/${id}.json?auth=${token}`,
            updatedEvent
          )
        );
      });

      requests.push(
        axios.patch(`/usuarios/${current.tutor.localId}.json?auth=${token}`, {
          ...object,
          color: current.color[current.creator.localId],
        })
      );

      erasedEvents.forEach((erased) => {
        const id = erased.details.id;
        requests.push(
          axios.put(
            `/agendas/${previous.tutor.localId}/aulas/${id}.json?auth=${token}`,
            erased
          )
        );
        requests.push(
          axios.put(
            `/agendas/${previous.student.localId}/aulas/${id}.json?auth=${token}`,
            erased
          )
        );
      });

      if (current.tutor.email) {
        emails.push(
          axios({
            url: "calendarNotificationEmail",
            baseURL: functionsBaseURL,
            method: "post",
            data: {
              to: current.tutor,
              from: { name: user },
              details: current,
              option: "changeInClass",
              date: moment(current.start).format("DD/MM/YYYY"),
              time: current.allDay
                ? "00:00"
                : moment(current.start).format("HH:mm"),
            },
          })
        );
      }
      if (current.tutor.tokenNotification) {
        notifications.push(
          axios({
            url: "calendarPushNotifications",
            baseURL: functionsBaseURL,
            method: "post",
            data: {
              expoPushToken: [current.tutor.tokenNotification],
              details: current,
              option: "changeInClass",
              date: moment(current.start).format("DD/MM/YYYY"),
            },
          })
        );
      }

      let tutors = getState().register.tutors.filter(
        (tutor) => tutor.localId !== current.tutor.localId
      );
      tutors = [
        ...tutors,
        {
          ...object,
          color: current.color[current.creator.localId],
        },
      ];
      dispatch(setTutors(tutors));

      axios
        .all(requests)
        .catch((err) => {
          message.error("Erro ao atualizar o banco de dados");
          console.log(err);
        })
        //Get da agenda de aulas do tutor para o qual a aula foi criada
        .then((res) => {
          axios
            .get(`/agendas/${current.tutor.localId}/aulas.json?auth=${token}`)
            .then((res) => {
              let userClasses = [];
              //Recuperação das aulas
              for (let key in res.data) {
                const classes = res.data[key];
                if (classes && !classes.deleted) {
                  classes.mainInfo.start = new Date(classes.mainInfo.start);
                  classes.mainInfo.end = new Date(classes.mainInfo.end);
                  userClasses.push({ ...classes });
                }
              }
              dispatch(setEvent(null, newEvents.concat(userClasses)));
              if (emails.length > 0) {
                axios
                  .all(emails)
                  .catch((err) => {
                    message.error(
                      "Erro ao enviar e-mails de notificação de alteração."
                    );
                    console.log(err);
                  })
                  .then(() => {
                    if (notifications.length > 0) {
                      axios
                        .all(notifications)
                        .then((res) => console.log(res))
                        .catch((err) => {
                          message.error(
                            "Erro ao enviar pushs de notificação de alteração."
                          );
                          console.log(err);
                        });
                    }
                  });
              }
            });
        });
    }
    //----------------------------------------------------- EVENTOS -----------------------------------------------------------------
    else if (current.type === "event") {
      current.participants.forEach((participant) => {
        updatedEvents.forEach((updatedEvent) => {
          const id = updatedEvent.details.id;
          requests.push(
            axios.put(
              `/agendas/${participant.localId}/eventos/${id}.json?auth=${token}`,
              updatedEvent
            )
          );
        });
        if (!participant.creator) {
          if (participant.email) {
            emails.push(
              axios({
                url: "calendarNotificationEmail",
                baseURL: functionsBaseURL,
                method: "post",
                data: {
                  to: participant,
                  from: { name: current.creator.name },
                  details: current,
                  option: "changeInEvent",
                  date: moment(current.start).format("DD/MM/YYYY"),
                  time: current.allDay
                    ? "00:00"
                    : moment(current.start).format("HH:mm"),
                },
              })
            );
          }
          if (participant.tokenNotification) {
            notifications.push(
              axios({
                url: "calendarPushNotifications",
                baseURL: functionsBaseURL,
                method: "post",
                data: {
                  expoPushToken: [participant.tokenNotification],
                  details: current,
                  option: "changeInEvent",
                  date: moment(current.start).format("DD/MM/YYYY"),
                },
              })
            );
          }
        }
      });
      previous.participants.forEach((participant) => {
        erasedEvents.forEach((erased) => {
          const id = erased.details.id;
          requests.push(
            axios.patch(
              `/agendas/${participant.localId}/eventos/${id}.json?auth=${token}`,
              erased
            )
          );
        });
        if (!participant.creator) {
          if (participant.email) {
            emails.push(
              axios({
                url: "calendarNotificationEmail",
                baseURL: functionsBaseURL,
                method: "post",
                data: {
                  to: participant,
                  from: { name: previous.creator.name },
                  details: previous,
                  option: "eventCanceled",
                  date: moment(previous.start).format("DD/MM/YYYY"),
                  time: previous.allDay
                    ? "00:00"
                    : moment(previous.start).format("HH:mm"),
                },
              })
            );
          }
          if (participant.tokenNotification) {
            notifications.push(
              axios({
                url: "calendarPushNotifications",
                baseURL: functionsBaseURL,
                method: "post",
                data: {
                  expoPushToken: [participant.tokenNotification],
                  details: previous,
                  option: "eventCanceled",
                  date: moment(previous.start).format("DD/MM/YYYY"),
                },
              })
            );
          }
        }
      });
      //Faz todas as requisições simultaneamente.
      axios
        .all(requests)
        //Após atualização do bd, faz um novo fetch com os events atualizados
        .then(() => {
          dispatch(setEvent(null, newEvents.concat(updatedEvents)));
          if (emails.length > 0) {
            axios
              .all(emails)
              .catch((err) => {
                message.error(
                  "Erro ao enviar e-mails de notificação de alteração."
                );
                console.log(err);
              })
              .then(() => {
                if (notifications.length > 0) {
                  axios
                    .all(notifications)
                    .then((res) => console.log(res))
                    .catch((err) => {
                      message.error(
                        "Erro ao enviar pushs de notificação de alteração."
                      );
                      console.log(err);
                    });
                }
              });
          }
        })
        .catch((err) => {
          console.log(err);
          message.error("Erro ao atualizar banco de dados");
        });
    } else {
      updatedEvents.forEach((updatedEvent) => {
        const id = updatedEvent.details.id;
        requests.push(
          axios.put(
            `/agendas/${current.creator.localId}/feriados/${id}.json?auth=${token}`,
            updatedEvent
          )
        );
      });

      erasedEvents.forEach((erased) => {
        const id = erased.details.id;
        requests.push(
          axios.put(
            `/agendas/${current.creator.localId}/feriados/${id}.json?auth=${token}`,
            erased
          )
        );
      });

      axios
        .all(requests)
        .then(() => {
          dispatch(setEvent(null, newEvents.concat(updatedEvents)));
        })
        .catch((err) => {
          console.log(err);
          message.error("Erro ao atualizar banco de dados");
        });
    }
  };
};

export const answerInvite = (details, answer, isSingle) => {
  return (dispatch, getState) => {
    const events = getState().calendar.current;
    const user = getState().user.name;
    const token = getState().user.userValidation.token;
    let requests = [];
    let emails = [];
    let current = [];
    let notifications = [];

    let updatedEvents = null;
    let newEvents = null;

    if (isSingle) {
      //Cria novo evento, com os detalhes modificados
      updatedEvents = [createEventObject(details)];
      newEvents = events.filter(
        (event) => event.details.id !== updatedEvents[0].details.id
      );
    } else {
      //Cria novos eventos, com os detalhes modificados
      updatedEvents = createNewEvent(details);
      newEvents = eraseEvents(events, details);
    }
    //----------------------------------------------------- AULAS -----------------------------------------------------------
    if (details.type === "class") {
      //Requests para atualizar agenda de alunos e tutores
      updatedEvents.forEach((updatedEvent) => {
        const id = updatedEvent.details.id;
        requests.push(
          axios.put(
            `/agendas/${details.tutor.localId}/aulas/${id}.json?auth=${token}`,
            updatedEvent
          )
        );
        requests.push(
          axios.put(
            `/agendas/${details.student.localId}/aulas/${id}.json?auth=${token}`,
            updatedEvent
          )
        );
      });
      if (details.creator.email) {
        emails.push(
          axios({
            url: "calendarNotificationEmail",
            baseURL: functionsBaseURL,
            method: "post",
            data: {
              to: details.creator,
              from: { name: user },
              details: details,
              option: answer ? "classAccepted" : "classRefused",
              date: moment(details.start).format("DD/MM/YYYY"),
              time: details.allDay
                ? "00:00"
                : moment(details.start).format("HH:mm"),
            },
          })
        );
      }
      if (details.creator.tokenNotification) {
        notifications.push(
          axios({
            url: "calendarPushNotifications",
            baseURL: functionsBaseURL,
            method: "post",
            data: {
              expoPushToken: [details.creator.tokenNotification],
              details: details,
              option: answer ? "classAccepted" : "classRefused",
              date: moment(details.start).format("DD/MM/YYYY"),
            },
          })
        );
      }
      axios
        .all(requests)
        .catch((err) => {
          message.error("Erro ao atualizar o banco de dados");
          console.log(err);
        })
        //Get da agenda de aulas do tutor para o qual a aula foi criada
        .then((res) => {
          dispatch(setEvent(null, newEvents.concat(updatedEvents)));
          if (emails.length > 0) {
            axios
              .all(emails)
              .catch((err) => {
                message.error(
                  "Erro ao enviar e-mails de notificação de alteração."
                );
                console.log(err);
              })
              .then(() => {
                if (notifications.length > 0) {
                  axios
                    .all(notifications)
                    .then((res) => console.log(res))
                    .catch((err) => {
                      message.error(
                        "Erro ao enviar pushs de notificação de alteração."
                      );
                      console.log(err);
                    });
                }
              });
          }
        });
    }
    //----------------------------------------------------- EVENTOS -----------------------------------------------------------------
    else if (details.type === "event") {
      updatedEvents.forEach((updatedEvent) => {
        const id = updatedEvent.details.id;
        updatedEvent.details.participants.forEach((participant) => {
          requests.push(
            axios.put(
              `/agendas/${participant.localId}/eventos/${id}.json?auth=${token}`,
              updatedEvent
            )
          );
        });
      });
      if (details.creator.email) {
        emails.push(
          axios({
            url: "calendarNotificationEmail",
            baseURL: functionsBaseURL,
            method: "post",
            data: {
              to: details.creator,
              from: { name: user },
              details: details,
              option: answer ? "eventAccepted" : "eventRefused",
              date: moment(details.start).format("DD/MM/YYYY"),
              time: details.allDay
                ? "00:00"
                : moment(details.start).format("HH:mm"),
            },
          })
        );
      }
      if (details.creator.tokenNotification) {
        notifications.push(
          axios({
            url: "calendarPushNotifications",
            baseURL: functionsBaseURL,
            method: "post",
            data: {
              expoPushToken: [details.creator.tokenNotification],
              details: details,
              option: answer ? "eventAccepted" : "eventRefused",
              date: moment(details.start).format("DD/MM/YYYY"),
            },
          })
        );
      }

      //Faz todas as requisições simultaneamente.
      axios
        .all(requests)
        //Após atualização do bd, faz um novo fetch com os events atualizados
        .then(() => {
          dispatch(setEvent(null, newEvents.concat(updatedEvents)));
          if (emails.length > 0) {
            axios
              .all(emails)
              .catch((err) => {
                console.log(err);
                message.error(
                  "Erro ao enviar e-mails de notificação de evento"
                );
              })
              .then(() => {
                if (notifications.length > 0) {
                  axios
                    .all(notifications)
                    .then((res) => console.log(res))
                    .catch((err) => {
                      message.error(
                        "Erro ao enviar pushs de notificação de alteração."
                      );
                      console.log(err);
                    });
                }
              });
          }
        })
        .catch((err) => {
          console.log(err);
          message.error("Erro ao atualizar banco de dados");
        });
    }
  };
};

export const setEvent = (permanent, current) => {
  return {
    type: NEW_EVENT,
    payload: { permanent, current },
  };
};

export const setVisible = (users) => {
  return {
    type: SET_VISIBLE,
    payload: users,
  };
};

const loadingInfo = () => {
  return {
    type: FETCHING_INFO,
  };
};

const infoLoaded = () => {
  return {
    type: INFO_LOADED,
  };
};

export const fetchUserClasses = (type, id) => {
  return (dispatch, getState) => {
    let currentEvents = getState().calendar.current;
    const token = getState().user.userValidation.token;
    //Apaga todos os eventos que estão na agenda do tutor/aluno selecionado, para evitar repetições
    let current = [];
    if (type === "tutor") {
      current = currentEvents.filter(
        (event) =>
          event.details.type !== "class" || event.details.tutor.localId !== id
      );
    } else {
      current = currentEvents.filter(
        (event) =>
          event.details.type !== "class" || event.details.student.localId !== id
      );
    }

    //Requisição dos eventos do usuário
    axios.get(`/agendas/${id}/aulas.json?auth=${token}`).then((res) => {
      const events = res.data;
      let userClasses = [];
      for (let key in events) {
        const classes = events[key];
        if (classes && !classes.deleted) {
          classes.mainInfo.start = new Date(classes.mainInfo.start);
          classes.mainInfo.end = new Date(classes.mainInfo.end);
          userClasses.push({ ...classes });
        }
      }
      dispatch(setEvent(null, current.concat(userClasses)));
    });
  };
};

export const fetchUserUnavailability = (id) => {
  return (dispatch, getState) => {
    let currentEvents = getState().calendar.current;
    const token = getState().user.userValidation.token;
    //Apaga todos os eventos que estão na agenda do tutor/aluno selecionado, para evitar repetições
    let current = [];
    current = currentEvents.filter(
      (event) =>
        event.details.type !== "unavailability" ||
        (event.details.type === "unavailability" &&
          event.details.creator.localId !== id)
    );

    //Requisição dos eventos do usuário
    axios
      .get(`/agendas/${id}/indisponibilidades.json?auth=${token}`)
      .then((res) => {
        const events = res.data;
        let userClasses = [];
        for (let key in events) {
          const classes = events[key];
          if (classes && !classes.deleted) {
            classes.mainInfo.start = new Date(classes.mainInfo.start);
            classes.mainInfo.end = new Date(classes.mainInfo.end);
            userClasses.push({ ...classes });
          }
        }
        console.log(userClasses);
        dispatch(setEvent(null, current.concat(userClasses)));
      });
  };
};

export const fetchSingleClass = (userId, classId) => {
  return (dispatch, getState) => {
    dispatch(loadingInfo());
    const token = getState().user.userValidation.token;
    const splits = classId.split("-");
    const root = `${splits[0]}-${splits[1]}-${splits[2]}`;
    axios
      .get(`/agendas/${userId}/${root}/${splits[3]}.json?auth=${token}`)
      .then((res) => {
        const userClasses = [{ ...res.data.details }];
        dispatch(setUserClasses(userClasses));
      });
  };
};

export const refresh = (thisUser, users, unavailabilities) => {
  return (dispatch, getState) => {
    const token = getState().user.userValidation.token;
    let requests = [];
    if (thisUser) {
      requests.push(
        axios.get(`/agendas/${thisUser.localId}/eventos.json?auth=${token}`)
      );
    }
    //Checa se, entre os calendários na store, já existem eventos do usuário
    if (users && users.length > 0) {
      users.forEach((user) => {
        requests.push(
          axios.get(`/agendas/${user.localId}/aulas.json?auth=${token}`)
        );
      });
    }
    if (unavailabilities && unavailabilities.length > 0) {
      unavailabilities.forEach((user) => {
        requests.push(
          axios.get(
            `/agendas/${user.localId}/indisponibilidades.json?auth=${token}`
          )
        );
      });
    }

    axios
      .all(requests)
      .then(
        axios.spread((...responses) => {
          let allEvents = [];
          responses.forEach((res) => {
            const events = res.data;
            for (let key in events) {
              const classes = events[key];
              //Só adiciona se o evento não estiver na store, já que o mesmo evento pode pertencer a agendas de diferentes usuários.
              if (!classes.deleted) {
                classes.mainInfo.start = new Date(classes.mainInfo.start);
                classes.mainInfo.end = new Date(classes.mainInfo.end);
                allEvents.push({ ...classes });
              }
            }
          });
          console.log(allEvents.length);
          dispatch(setEvent(null, allEvents));
        })
      )
      .catch((err) => {
        console.log(err);
        message.error("Erro ao recuperar as agendas.");
      });
  };
};

export const changeColor = (details, isSingle) => {
  return (dispatch, getState) => {
    const events = getState().calendar.current;
    const token = getState().user.userValidation.token;
    const user = getState().user
    let requests = [];

    let updatedEvents = null;
    let newEvents = null;

    if (isSingle) {
      //Cria novo evento, com os detalhes modificados
      updatedEvents = [createEventObject(details)];
      newEvents = events.filter(
        (event) => event.details.id !== updatedEvents[0].details.id
      );
    } else {
      //Cria novos eventos, com os detalhes modificados
      updatedEvents = createNewEvent(details);
      newEvents = eraseEvents(events, details);
    }
    //----------------------------------------------------- AULAS -----------------------------------------------------------
    if (details.type === "event") {
      dispatch(setEvent(null, newEvents.concat(updatedEvents)));
      details.participants.forEach((participant) => {
        updatedEvents.forEach((updatedEvent) => {
          const id = updatedEvent.details.id;
          requests.push(
            axios.patch(
              `/agendas/${participant.localId}/eventos/${id}.json?auth=${token}`,
              updatedEvent
            )
          );
        });
      });
      //Faz todas as requisições simultaneamente.
      axios.all(requests).catch((err) => {
        dispatch(setEvent(null, events));
        alert("Erro ao atualizar banco de dados");
        console.log(err);
      });
    }
    if (details.type === "unavailability") {
      dispatch(setEvent(null, newEvents.concat(updatedEvents)));
      const tutorId = details.creator.localId;

      let allTutors = getState().register.tutors.filter(
        (tutor) => tutor.localId !== tutorId
      );
      let thisTutor = getState().register.tutors.filter(
        (tutor) => tutor.localId === tutorId
      );

      let tutors = [
        ...allTutors,
        {
          ...thisTutor[0],
          unavailabilityColor: details.color[user.localId],
        },
      ];

      dispatch(setTutors(tutors));

      requests.push(
        axios.patch(`/usuarios/${tutorId}.json?auth=${token}`, {
          ...thisTutor[0],
          unavailabilityColor: details.color[user.localId],
        })
      );
      //Faz todas as requisições simultaneamente.
      axios.all(requests).catch((err) => {
        message.error("Erro ao atualizar banco de dados");
        dispatch(
          setTutors([
            ...allTutors,
            {
              ...thisTutor[0],
            },
          ])
        );
        console.log(err);
      });
    }
  };
};

export const setUserClasses = (classes) => {
  return {
    type: SET_USER_CLASSES,
    payload: classes,
  };
};

export const fetchPlans = (id) => {
  return (dispatch, getState) => {
    const token = getState().user.userValidation.token;
    axios
      .get(`/planos/${id}.json?auth=${token}`)
      .then((res) => {
        const rawData = res.data;
        let plans = [];
        for (let key in rawData) {
          plans.push({ ...rawData[key], id: key });
        }
        dispatch(setPlans(plans));
      })
      .catch((err) => {
        console.log(err);
        message.error("Erro ao acessar os planos do usuário");
      });
  };
};

export const setPlans = (plans) => {
  return {
    type: SET_USER_PLANS,
    payload: plans,
  };
};
