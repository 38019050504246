import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import Header from "../../../utils/template/Header/Header";
import Logo from "../../../utils/template/Logo/Logo";
import Menu from "../../../utils/template/Menu/Menu";
import {
  cpfMask,
  dateMask,
  phoneMask,
  cellPhoneMask,
  CEPMask,
} from "../../../utils/masks";
import { fillStudentObject } from "../../../utils/objects";
import {
  fetchUserInfos,
  updateUser,
} from "../../../utils/store/actions/registerActions";
import {
  Button,
  message,
  Select,
  Tag,
  TimePicker,
  Menu as DropdownMenu,
  Dropdown,
  Tabs,
  Table,
  Radio
} from "antd";
import { PlusCircleOutlined, DownOutlined } from "@ant-design/icons";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import {
  fetchUserClasses,
  fetchPlans,
  updateClass,
} from "../../../utils/store/actions/calendarActions";
import moment from "moment";

const { TabPane } = Tabs;
const { Option } = Select;
const { RangePicker } = TimePicker;

const options = (key) => {
  switch (key) {
    case "yes":
      return "Aula dada";
    case "no":
      return "Aluno faltou";
    case "suspended":
      return "Suspensa";
    case "holiday":
      return "Feriado";
    default:
      return "Aula dada";
  }
};

const weekDays = [
  "Segunda-feira",
  "Terça-feira",
  "Quarta-feira",
  "Quinta-feira",
  "Sexta-feira",
  "Sábado",
];

const initialState = {
  loading: true,
  redirect: false,
  sending: false,
  error: {
    name: false,
    email: false,
    birthDate: false,
    gender: false,
    cellPhone: false,
    street: false,
    number: false,
    neighborhood: false,
    complement: false,
    city: false,
    state: false,
    school: false,
    schoolYear: false,
    type: false
  },
  userInfos: {
    type: "",
    name: "",
    email: "",
    rg: "",
    cpf: "",
    birthDate: "",
    phone: "",
    cellPhone: "",
    gender: "",
    timeDisponibility: [],
    address: {
      street: "",
      number: "",
      complement: "",
      neighborhood: "",
      city: "",
      state: "",
      cep: "",
    },
    schoolYear: "",
    school: "",
    observation: "",
    plan: {
      total: "",
      hiredPlan: "",
      hoursLeft: "",
      hoursUsed: "",
    },
  },
  day: "",
  time: [null, null],
  timeString: ["", ""],
};

const status = ["yes", "no", "suspended", "holiday"];

class AtualizacaoCadastro extends Component {
  state = {
    ...initialState,
    time: [null, null],
    timeString: ["", ""],
    registration: { ...initialState.registration },
    userInfos: {
      ...initialState.userInfos,
      address: { ...initialState.userInfos.address },
      timeDisponibility: [],
    },
    error: { ...initialState.error },
  };

  componentDidMount() {
    const localId = this.props.match.params.id;
    this.props.onfetchUser("aluno", localId);
    this.props.onFetchUserClasses("student", localId);
    this.props.onFetchPlans(localId);
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.loading && !this.props.loading) {
      const userInfos = fillStudentObject(this.props.selectedUser);
      this.setState({
        ...this.state,
        userInfos: { ...userInfos },
        loading: false,
      });
    }
    if (prevProps.registering && !this.props.registering) {
      const userInfos = fillStudentObject(this.props.selectedUser);
      this.setState({ ...this.state, userInfos, sending: false });
      message.success("Informações atualizadas com sucesso!");
    }
  };

  componentWillUnmount() {
    this.setState({
      ...initialState,
      time: [null, null],
      timeString: ["", ""],
      registration: { ...initialState.registration },
      userInfos: {
        ...initialState.userInfos,
        address: { ...initialState.userInfos.address },
        timeDisponibility: [],
      },
      error: { ...initialState.error },
    });
  }

  getaddress = () => {
    axios
      .get(
        `https://api.pagar.me/1/zipcodes/${this.state.userInfos.address.cep}`
      )
      .catch((err) => {
        const state = { ...this.state };
        message.error("CEP não encontrado");
        this.setState({ ...state });
      })
      .then((res) => {
        if (res) {
          const state = { ...this.state };
          state.userInfos.address.street = res.data.street;
          state.userInfos.address.city = res.data.city;
          state.userInfos.address.neighborhood = res.data.neighborhood;
          state.userInfos.address.state = res.data.state;
          this.setState({ ...state });
        }
      });
  };

  menuGender = (
    <DropdownMenu onClick={(e) => this.handleChange(e, "gender")}>
      <DropdownMenu.Item key="Feminino">Feminino</DropdownMenu.Item>
      <DropdownMenu.Item key="Masculino">Masculino</DropdownMenu.Item>
    </DropdownMenu>
  );

  menuStatusClasses = (details) => (
    <DropdownMenu>
      {status.map((classStatus) => (
        <DropdownMenu.Item
          key={classStatus}
          onClick={() => this.handleStatus(details, classStatus)}
        >
          {options(classStatus)}
        </DropdownMenu.Item>
      ))}
    </DropdownMenu>
  );

  handleChange = (e, option) => {
    let state = { ...this.state };
    switch (option) {
      case "name":
        state.userInfos.name = e.target.value;
        this.setState({ ...state });
        break;
      case "email":
        state.userInfos.email = e.target.value;
        this.setState({ ...state });
        break;
      case "rg":
        state.userInfos.rg = e.target.value;
        this.setState({ ...state });
        break;
      case "cpf":
        state.userInfos.cpf = cpfMask(e.target.value);
        this.setState({ ...state });
        break;
      case "birthDate":
        state.userInfos.birthDate = dateMask(e.target.value);
        this.setState({ ...state });
        break;
      case "gender":
        state.userInfos.gender = e.key;
        this.setState({ ...state });
        break;
      case "phone":
        state.userInfos.phone = phoneMask(e.target.value);
        this.setState({ ...state });
        break;
      case "cellPhone":
        state.userInfos.cellPhone = cellPhoneMask(e.target.value);
        this.setState({ ...state });
        break;
      case "day":
        state.day = e;
        this.setState({ ...state });
        break;
      case "school":
        state.userInfos.school = e.target.value;
        this.setState({ ...state });
        break;
      case "schoolYear":
        state.userInfos.schoolYear = e.target.value;
        this.setState({ ...state });
        break;
      case "observation":
        state.userInfos.observation = e.target.value;
        this.setState({ ...state });
        break;
      case "category":
        state.userInfos.type = e.target.value;
        this.setState({ ...state });
        break;
      default:
        this.setState({ ...state });
        break;
    }
  };

  handleaddress = (e, option) => {
    let state = { ...this.state };
    switch (option) {
      case "street":
        state.userInfos.address.street = e.target.value;
        this.setState({ ...state });
        break;
      case "number":
        state.userInfos.address.number = e.target.value;
        this.setState({ ...state });
        break;
      case "complement":
        state.userInfos.address.complement = e.target.value;
        this.setState({ ...state });
        break;
      case "neighborhood":
        state.userInfos.address.neighborhood = e.target.value;
        this.setState({ ...state });
        break;
      case "city":
        state.userInfos.address.city = e.target.value;
        this.setState({ ...state });
        break;
      case "state":
        state.userInfos.address.state = e.target.value;
        this.setState({ ...state });
        break;
      case "cep":
        state.userInfos.address.cep = e.target.value;
        this.setState({ ...state });
        break;
      default:
        this.setState({ ...state });
        break;
    }
  };

  checkRequired = () => {
    let state = { ...this.state };
    let flag = false;

    //Checa quais campos da página foram preenchidos
    state.error.name = state.userInfos.name.trim() === "";
    state.error.email = state.userInfos.email.trim() === "";
    //state.error.birthDate = state.userInfos.birthDate.trim() === "";
    // state.error.gender = state.userInfos.gender.trim() === "";
    // state.error.cellPhone = state.userInfos.cellPhone.trim() === "";
    // state.error.street = state.userInfos.address.street.trim() === "";
    // state.error.number = state.userInfos.address.number.trim() === "";
    // state.error.neighborhood = state.userInfos.address.neighborhood.trim() === "";
    // state.error.city = state.userInfos.address.city.trim() === "";
    // state.error.state = state.userInfos.address.state.trim() === "";

    for (let key in state.error) {
      if (state.error[key] === true) {
        flag = true;
      }
    }

    if (flag) {
      message.error("Todos os campos obrigatórios precisam ser preenchidos.");
      this.setState({ ...state });
    } else {
      state.sending = true;
      this.setState({ ...state });
      this.props.onLoadUser(
        state.userInfos,
        state.userInfos.type,
        this.props.match.params.id
      );
    }
  };

  handleTime(time, timeString) {
    let state = { ...this.state };
    state.time = [time[0], time[1]];
    state.timeString = [timeString[0], timeString[1]];
    this.setState({ ...state });
  }

  handleDisponibility() {
    let state = { ...this.state };
    state.userInfos.timeDisponibility.push(
      `${state.day}, ${state.timeString[0]} - ${state.timeString[1]}`
    );
    state.day = "";
    state.time = [null, null];
    state.timeString = ["", ""];
    this.setState({ ...state });
  }

  handleStatus = (details, status) => {
    if(details.confirmed === "yes" && !details.reposition){
      details.originalDate = {
        date: "",
        start: "",
        end: "",
        moment: "",
      }
      details.originalDate.date = details.date;
      details.originalDate.start = details.start;
      details.originalDate.end = details.end;
      details.originalDate.moment = details.moment;
      details.reposition = status
    } else if (details.confirmed === "yes" && details.reposition){
      details.date = details.originalDate.date;
      details.start = details.originalDate.start;
      details.end = details.originalDate.end;
      details.moment = details.originalDate.moment;
    }
    details.confirmed = status;
    this.props.onUpdateClass(details, this.props.match.params.id, true);
  };

  handlePlan = (details, id) => {
    this.props.userPlans.forEach((plan) => {
      if (id === plan.id) {
        details.plan = { ...plan };
      }
    });
    this.props.onUpdateClass(details, this.props.match.params.id);
  };

  menuGender = (
    <DropdownMenu onClick={(e) => this.handleChange(e, "gender")}>
      <DropdownMenu.Item key="Feminino">Feminino</DropdownMenu.Item>
      <DropdownMenu.Item key="Masculino">Masculino</DropdownMenu.Item>
    </DropdownMenu>
  );

  removeItem(type, item) {
    let state = { ...this.state };
    for (let i = 0; i < state.userInfos.timeDisponibility.length; i++) {
      if (state.userInfos.timeDisponibility[i] === item) {
        state.userInfos.timeDisponibility.splice(i, 1);
      }
    }
    this.setState({ ...state });
  }

  pastClasses = (events) => {
    let pastClasses = [];
    const now = moment();
    if (events.length > 0) {
      events.forEach((event) => {
        if (moment(event.moment).isBefore(now)) {
          pastClasses.push(event);
        }
      });
      pastClasses.sort((a, b) => moment(b.moment) - moment(a.moment));
      return pastClasses;
    } else {
      return events;
    }
  };

  checkHours = () => {
    let plans = {};
    const today = moment();
    this.props.userPlans.forEach((userPlan) => {
      let key = userPlan.id;
      plans[key] = 0;
    });
    this.props.userClasses.forEach((userClass) => {
      if (
        moment(userClass.moment).isBefore(today) &&
        userClass.confirmed !== "suspended"
      ) {
        plans[userClass.plan.id] += userClass.duration;
      }
    });
    let updatedPlans = this.props.userPlans;
    updatedPlans.forEach((userPlan) => {
      userPlan.usedHours = plans[userPlan.id];
    });
    return updatedPlans;
  };

  render() {
    const data = this.pastClasses(this.props.userClasses);
    const menuPlans = (details) => (
      <DropdownMenu>
        {this.props.userPlans.map((plan) => (
          <DropdownMenu.Item
            key={plan.id}
            onClick={() => this.handlePlan(details, plan.id)}
          >
            {plan.id}
          </DropdownMenu.Item>
        ))}
      </DropdownMenu>
    );
    const columns = [
      {
        title: "Id",
        dataIndex: "id",
        key: "id",
        render: (text, record) => (
          <Link to={`/agenda/detalhes/${record.student.localId}/${record.id}`}>{record.id}</Link>
        ),
      },
      {
        title: "Aula data em",
        dataIndex: "date",
        key: "date",
        render: (text, record) => record.confirmed === "yes" ? record.date : "-"
      },
      {
        title: "Horário",
        dataIndex: "start",
        key: "start",
      },
      {
        title: "Tutor",
        dataIndex: "tutor",
        key: "tutor",
        render: (text, record) => record.tutor.name,
      },
      {
        title: "Duração",
        dataIndex: "duration",
        key: "duration",
      },
      {
        title: "Status",
        dataIndex: "confirmed",
        key: "confirmed",
        filters: [
          {
            text: 'Suspensas',
            value: 'suspended',
          },
          {
            text: 'Aula dada',
            value: "yes"
          },
          {
            text: "Aluno faltou",
            value: "no",
          },
          {
            text: "Feriado",
            value: "holiday"
          }
        ],
        onFilter: (value, record) => record.confirmed === value,
        render: (text, record) => (
          <Dropdown overlay={this.menuStatusClasses(record)}>
            <Button size="small" className="acoes-massa">
              {options(text)}
              <DownOutlined />
            </Button>
          </Dropdown>
        ),
      },
      {
        title: "Reposição por",
        dataIndex: "reposition",
        key: "reposition",
        render: (text, record) => (record.reposition && record.confirmed === "yes" ? options(record.reposition) : "-")
      },
      {
        title: "Data original",
        dataIndex: "originalDate",
        key: "confirmed",
        render: (text, record) => (record.reposition ? record.originalDate.date : "-")
      },
      {
        title: "Plano",
        render: (text, record) => (
          <Dropdown overlay={menuPlans(record)}>
            <Button size="small" className="acoes-massa">
              {record.plan.id}
              <DownOutlined />
            </Button>
          </Dropdown>
        ),
      },
    ];
    const columns_summary = [
      {
        title: "Id",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Tipo de serviço",
        dataIndex: "hiredPlan",
        key: "hiredPlan",
      },
      {
        title: "Início",
        dataIndex: "start",
        key: "start",
      },
      {
        title: "Fim",
        dataIndex: "end",
        key: "end",
        render: (text, record) => {
          if (!record.end) {
            return "-";
          } else {
            return record.end;
          }
        },
      },
      {
        title: "Total de horas",
        dataIndex: "total",
        key: "total",
        render: (text, record) => {
          if (record.hiredPlan === "Plano de recorrência") {
            return "-";
          } else {
            return record.total;
          }
        },
      },
      {
        title: "Horas contabilizadas",
        dataIndex: "usedHours",
        key: "usedHours",
      },
      {
        title: "Horas restantes",
        dataIndex: "hoursLeft",
        key: "hoursLeft",
        render: (text, record) => {
          if (record.hiredPlan === "Plano de recorrência") {
            return "-";
          } else {
            return record.total - record.usedHours;
          }
        },
      },
    ];
    const plans = this.checkHours();

    return this.state.loading ? null : (
      <div className="page student">
        {/*-------------------------------------------- HEADER ----------------------------------------- */}
        <Header />
        <Logo />
        <Menu />
        <div className="content mt-4 ml-4">
          <Tabs defaultActiveKey="2">
            <TabPane tab="Informações de cadastro" key="1">
                        {/*--------------------------------- TIPO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Vê o próprio relatório?</h3>
            </div>
            <div
              className={`sectionContent full-border ${
                this.state.error.type ? "error" : ""
              }`}
            >
              <Radio.Group
                buttonStyle="solid"
                name="radiogroup"
                value={this.state.userInfos.type}
                onChange={(e) => this.handleChange(e, "category")}
              >
                <Radio value="aluno-responsavel">Sim</Radio>
                <Radio value="aluno">Não</Radio>
              </Radio.Group>
            </div>
          </div>
              {/* --------------------------------- NOME  -----------------------------------------*/}
              <div className="section">
                <div className="sectionTitle">
                  <h3 className="item-title">Nome</h3>
                </div>
                <div className="sectionContent">
                  <Input
                    value={this.state.userInfos.name}
                    onChange={(e) => this.handleChange(e, "name")}
                    error={this.state.error.name}
                    required
                    fullWidth
                  />
                </div>
              </div>
              {/*--------------------------------- EMAIL ----------------------------------------- */}
              <div className="section">
                <div className="sectionTitle">
                  <h3 className="item-title">E-mail</h3>
                </div>
                <div className="sectionContent">
                  <Input
                    value={this.state.userInfos.email}
                    placeholder="exemplo@email.com"
                    onChange={(e) => this.handleChange(e, "email")}
                    error={this.state.error.email}
                    required
                    fullWidth
                  />
                </div>
              </div>
              {/*--------------------------------- RG ----------------------------------------- */}
              <div className="section">
                <div className="sectionTitle">
                  <h3 className="item-title">RG</h3>
                </div>
                <div className="sectionContent">
                  <Input
                    value={this.state.userInfos.rg}
                    placeholder="xxxxxxxxx-x"
                    onChange={(e) => this.handleChange(e, "rg")}
                    fullWidth
                    required={this.type === "aluno" ? false : true}
                    error={this.state.error.rg}
                  />
                </div>
              </div>
              {/*--------------------------------- CPF ----------------------------------------- */}
              <div className="section">
                <div className="sectionTitle">
                  <h3 className="item-title">CPF</h3>
                </div>
                <div className="sectionContent">
                  <Input
                    value={this.state.userInfos.cpf}
                    placeholder="xxx.xxx.xxx-xx"
                    onChange={(e) => this.handleChange(e, "cpf")}
                    required={this.type === "aluno" ? false : true}
                    fullWidth
                    error={this.state.error.cpf}
                  />
                </div>
              </div>
              {/*--------------------------------- NASCIMENTO ----------------------------------------- */}
              <div className="section">
                <div className="sectionTitle">
                  <h3 className="item-title">Data de nascimento</h3>
                </div>
                <div className="sectionContent">
                  <Input
                    value={this.state.userInfos.birthDate}
                    placeholder="dd/mm/aaaa"
                    onChange={(e) => this.handleChange(e, "birthDate")}
                    error={this.state.error.birthDate}
                    required
                    fullWidth
                  />
                </div>
              </div>
              {/*--------------------------------- GÊNERO ----------------------------------------- */}
              <div className="section">
                <div className="sectionTitle">
                  <h3 className="item-title">Gênero</h3>
                </div>
                <div className="sectionContent">
                  <Dropdown overlay={this.menuGender}>
                    <Button size="small" className="acoes-massa">
                      {this.state.userInfos.gender
                        ? this.state.userInfos.gender
                        : "Gênero"}
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                </div>
              </div>
              {/*--------------------------------- TELEFONE ----------------------------------------- */}
              <div className="section">
                <div className="sectionTitle">
                  <h3 className="item-title">Telefone</h3>
                </div>
                <div className="sectionContent">
                  <Input
                    value={this.state.userInfos.phone}
                    placeholder="(xx) xxxx-xxxx"
                    onChange={(e) => this.handleChange(e, "phone")}
                    fullWidth
                  />
                </div>
              </div>
              {/*--------------------------------- CELULAR ----------------------------------------- */}
              <div className="section">
                <div className="sectionTitle">
                  <h3 className="item-title">Celular</h3>
                </div>
                <div className="sectionContent">
                  <Input
                    value={this.state.userInfos.cellPhone}
                    placeholder="(xx) xxxxx-xxxx"
                    onChange={(e) => this.handleChange(e, "cellPhone")}
                    error={this.state.error.cellPhone}
                    fullWidth
                  />
                </div>
              </div>
              {/*--------------------------------- ENDEREÇO ----------------------------------------- */}
              <div className="section">
                <div className="sectionTitle">
                  <h3 className="item-title">Endereço</h3>
                </div>
                <div className="sectionContent address">
                  <Input
                    value={
                      this.state.userInfos.address
                        ? CEPMask(this.state.userInfos.address.cep)
                        : ""
                    }
                    placeholder="CEP"
                    onChange={(e) => this.handleaddress(e, "cep")}
                    required
                    fullWidth
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={(e) => this.getaddress()}>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <Input
                    value={
                      this.state.userInfos.address
                        ? this.state.userInfos.address.street
                        : ""
                    }
                    placeholder="Rua/Avenida"
                    onChange={(e) => this.handleaddress(e, "street")}
                    error={this.state.error.street}
                    required
                    fullWidth
                  />
                  <Input
                    value={
                      this.state.userInfos.address
                        ? this.state.userInfos.address.number
                        : ""
                    }
                    placeholder="Número"
                    onChange={(e) => this.handleaddress(e, "number")}
                    error={this.state.error.number}
                    required
                    fullWidth
                  />
                  <Input
                    value={
                      this.state.userInfos.address
                        ? this.state.userInfos.address.complement
                        : ""
                    }
                    placeholder="Complemento"
                    onChange={(e) => this.handleaddress(e, "complement")}
                    required
                    fullWidth
                  />
                  <Input
                    value={
                      this.state.userInfos.address
                        ? this.state.userInfos.address.neighborhood
                        : ""
                    }
                    placeholder="Bairro"
                    onChange={(e) => this.handleaddress(e, "neighborhood")}
                    error={this.state.error.neighborhood}
                    required
                    fullWidth
                  />
                  <Input
                    value={
                      this.state.userInfos.address
                        ? this.state.userInfos.address.city
                        : ""
                    }
                    placeholder="Cidade"
                    onChange={(e) => this.handleaddress(e, "city")}
                    error={this.state.error.city}
                    required
                    fullWidth
                  />
                  <Input
                    value={
                      this.state.userInfos.address
                        ? this.state.userInfos.address.state
                        : ""
                    }
                    placeholder="Estado"
                    onChange={(e) => this.handleaddress(e, "state")}
                    error={this.state.error.state}
                    required
                    fullWidth
                  />
                </div>
              </div>
              {/*--------------------------------- ESCOLA ----------------------------------------- */}
              <div className="section">
                <div className="sectionTitle">
                  <h3 className="item-title">Instituição de ensino</h3>
                </div>
                <div className="sectionContent">
                  <Input
                    value={this.state.userInfos.school}
                    onChange={(e) => this.handleChange(e, "school")}
                    error={this.state.error.school}
                    required
                    fullWidth
                  />
                </div>
              </div>
              {/*--------------------------------- ANO ----------------------------------------- */}
              <div className="section">
                <div className="sectionTitle">
                  <h3 className="item-title">Ano escolar</h3>
                </div>
                <div className="sectionContent">
                  <Input
                    value={this.state.userInfos.schoolYear}
                    onChange={(e) => this.handleChange(e, "schoolYear")}
                    required
                    fullWidth
                  />
                </div>
              </div>
              {/*--------------------------------- DISPONIBILIDADE DE HORÁRIO ----------------------------------------- */}
              <div className="section">
                <div className="sectionTitle">
                  <h3 className="item-title">Disponibilidade de horário</h3>
                </div>
                <div className="sectionContent time">
                  {this.state.userInfos.timeDisponibility.map(
                    (timeDisponibility) => (
                      <Tag
                        closable
                        onClose={() =>
                          this.removeItem("disponibility", timeDisponibility)
                        }
                      >
                        {timeDisponibility}
                      </Tag>
                    )
                  )}
                </div>
                <div className="sectionContent time-form">
                  <Select
                    value={this.state.day}
                    style={{ width: 200 }}
                    placeholder="Selecione um dia da semana"
                    onChange={(value) => this.handleChange(value, "day")}
                  >
                    {weekDays.map((day) => (
                      <Option key={`day`} value={day}>
                        {day}
                      </Option>
                    ))}
                  </Select>
                  <RangePicker
                    size="large"
                    placeholder={["Horário inicial", "Horário final"]}
                    value={[this.state.time[0], this.state.time[1]]}
                    onChange={(time, timeString) =>
                      this.handleTime(time, timeString)
                    }
                    format={"HH:mm"}
                  />
                  <Button onClick={(e) => this.handleDisponibility()}>
                    <PlusCircleOutlined />
                  </Button>
                  <p>
                    *Pressione o botão de ok para confirmar a seleção dos
                    horários
                  </p>
                </div>
              </div>
              {/*--------------------------------- OBSERVAÇÃO ----------------------------------------- */}
              <div className="section">
                <div className="sectionTitle">
                  <h3 className="item-title">Observação</h3>
                </div>
                <div className="sectionContent">
                  <Input
                    value={this.state.userInfos.observation}
                    onChange={(e) => this.handleChange(e, "observation")}
                    error={this.state.error.observation}
                    required
                    fullWidth
                    multiline
                  />
                </div>
              </div>
              {/*--------------------------------- ENVIAR ----------------------------------------- */}
              <Button
                type="primary"
                className="submit"
                onClick={(e) => this.checkRequired()}
                loading={this.state.sending}
              >
                Salvar
              </Button>
            </TabPane>
            <TabPane tab="Aulas" key="2">
              <div className="mt-5 mr-5 ">
                <div className="mb-5">
                  <Table
                    rowClassName={(record, index) =>
                      index % 2 === 0 ? "table-row-dark" : "table-row-light"
                    }
                    dataSource={plans}
                    columns={columns_summary}
                    locale={{
                      emptyText: (
                        <img
                          src={require("../../../assets/images/no-data.png")}
                          alt="no-data"
                        />
                      ),
                    }}
                  />
                </div>
                <Table
                  rowClassName={(record, index) =>
                    index % 2 === 0 ? "table-row-dark" : "table-row-light"
                  }
                  rowSelection={{ type: "checkbox" }}
                  dataSource={data}
                  columns={columns}
                  locale={{
                    emptyText: (
                      <img
                        src={require("../../../assets/images/no-data.png")}
                        alt="no-data"
                      />
                    ),
                  }}
                />
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ register, calendar }) => ({
  users: register.users,
  selectedUser: register.selectedUser,
  loading: register.loading,
  calendarLoading: calendar.loading,
  registering: register.registering,
  userClasses: calendar.userClasses,
  userPlans: calendar.userPlans,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onfetchUser: (type, localId) => dispatch(fetchUserInfos(type, localId)),
    onLoadUser: (user, type, localId) =>
      dispatch(updateUser(user, type, localId)),
    onFetchUserClasses: (type, localId) => dispatch(fetchUserClasses(type, localId)),
    onFetchPlans: (id) => dispatch(fetchPlans(id)),
    onUpdateClass: (details, localId, sendNotification) =>
      dispatch(updateClass(details, localId, sendNotification)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AtualizacaoCadastro);
