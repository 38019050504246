import React from "react";
import { Modal } from "antd";
import ItemExibicao from "./ItemExibicao";
import BotoesHeader from "./BotoesHeader";

import moment from "moment";

const Title = ({
  details,
  onDelete,
  onEdit,
  onAnswer,
  userInfos,
  onPickColor,
  color,
}) => {
  return (
    <div className="row mx-0 px-4">
      <div className="col-8">
        <p className="mb-0" style={{ lineHeight: 2 }}>
          {details.type === "event"
            ? "Detalhes do evento"
            : details.type === "class"
            ? "Detalhes da aula"
            : details.type === "holiday"
            ? "Feriado"
            : "Indisponibilidade"}
        </p>
      </div>
      {(details.type === "class" ||
        details.creator.localId === userInfos.localId) && (
        <BotoesHeader type="edit" title="Editar evento" click={onEdit} />
      )}
      {((details.type === "class" && moment(details.end).isAfter(moment())) ||
        details.type === "event" ||
        details.type === "holiday") && (
        <BotoesHeader type="delete" title="Apagar evento" click={onDelete} />
      )}
      {details.type === "event" &&
        details.creator.localId !== userInfos.localId && (
          <BotoesHeader
            type="accept"
            title="Aceitar convite"
            click={() => onAnswer(details, true)}
          />
        )}
      {details.type === "event" &&
        details.creator.localId !== userInfos.localId && (
          <BotoesHeader
            type="refuse"
            title="Recusar convite"
            click={() => onAnswer(details, false)}
          />
        )}
      {(details.type === "event" || details.type === "unavailability") && (
        <button
          className="button-color-picker col-1 mx-1 px-0 mt-1"
          style={{ backgroundColor: color }}
          onClick={onPickColor}
        />
      )}
    </div>
  );
};

export default ({
  visible,
  closeModal,
  details,
  handleStatus,
  onDelete,
  userInfos,
  onEdit,
  onAnswer,
  onPickColor,
  color,
}) => {
  return (
    <Modal
      title={
        <Title
          details={details}
          onDelete={onDelete}
          onEdit={onEdit}
          onAnswer={onAnswer}
          userInfos={userInfos}
          onPickColor={onPickColor}
          color={color}
        />
      }
      visible={visible}
      onOk={closeModal}
      onCancel={closeModal}
      footer={null}
      okText={"Ok"}
      cancelText={"Voltar"}
      centered
      maskClosable
    >
      <div className="mx-0 px-4 mb-2">
        {details.type === "event" ||
          (details.type === "holiday" && (
            <ItemExibicao title="Título" value={details.title} type="input" />
          ))}
        {details.type === "class" && (
          <ItemExibicao title="Aluno" value={details.student} type="student" />
        )}
        {details.type === "class" && (
          <ItemExibicao title="Tutor" value={details.tutor} type="tutor" />
        )}
        {details.type === "class" && (
          <ItemExibicao
            title="Status"
            value={details}
            type="status"
            handleChange={handleStatus}
          />
        )}
        <ItemExibicao
          title="Data"
          value={moment(details.start).format("DD/MM/YYYY")}
          type="date"
        />
        <ItemExibicao
          title="Horário"
          value={`${moment(details.start).format("HH:mm")} - 
            ${moment(details.end).format("HH:mm")}`}
          type="time"
        />
        <ItemExibicao
          title="Frequência"
          value={details.frequency.description}
          type="time"
        />
        {details.type !== "indisponibility" && (
          <ItemExibicao
            title="Descrição"
            value={details.description}
            type="input"
            disable={
              details.type === "class" &&
              !(details.attachments && details.attachments.length > 0) &&
              !details.log
            }
          />
        )}
        {details.attachments && details.attachments.length > 0 && (
          <ItemExibicao
            title="Anexos"
            value={details.attachments}
            type="file"
          />
        )}
        {details.type === "event" && (
          <ItemExibicao
            title="Participantes"
            value={details.participants}
            type="participants"
            disable
          />
        )}
        {details.type === "class" && details.log && (
          <ItemExibicao
            title="Histórico de alterações"
            value={details.log}
            type="log"
            disable
          />
        )}
      </div>
    </Modal>
  );
};
