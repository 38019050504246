import React, { Component } from "react";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import Logo from "../../utils/template/Logo/Logo";
import Menu from "../../utils/template/Menu/Menu";
import Header from "../../utils/template/Header/Header";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import { message, Button } from "antd";
import { updatePassword } from "../../utils/store/actions/userActions"

class AlterarSenha extends Component {
  state = {
    redirect: false,
    showPassword: [false, false],
    password: "",
    confirmation: "",
    error: [false, false],
    sending: false
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.loading && !this.props.loading) {
        message.success("Senha alterada com sucesso!");
      this.setState({ redirect: true, sending: false });
    }
  };

  handleClickShowPassword = (e, index) => {
    let state = { ...this.state };
    state.showPassword[index] = !state.showPassword[index];
    this.setState({ ...state });
  };

  handleChange = (e, option) => {
    let state = { ...this.state };
    switch (option) {
      case "password":
        state.password = e.target.value;
        this.setState({ ...state });
        break;
      case "confirmation":
        state.confirmation = e.target.value;
        this.setState({ ...state });
        break;
      default:
        this.setState({ ...state });
        break;
    }
  };

  checkRequired = () => {
    this.setState({...this.state, sendig: true})
    let state = { ...this.state };
    if (
      this.state.password.trim() !== "" &&
      this.state.confirmation.trim() !== ""
    ) {
      if (this.state.password.length < 6) {
        message.error("A senha precisa ter no mínimo 6 caracteres.");
        state.error[0] = true;
        state.error[1] = true;
        this.setState({ ...state });
      } else {
        if (this.state.password === this.state.confirmation) {
          this.props.onChangePassword(this.props.localId, this.state.password);
        } else {
          message.error("As senhas precisam ser iguais.");
          state.error[0] = true;
          state.error[1] = true;
          this.setState({ ...state });
        }
      }
    } else {
      message.error("Preencha todos os campos.");
      state.error[0] = this.state.password.trim() === "";
      state.error[1] = this.state.confirmation.trim() === "";
      this.setState({ ...state });
    }
  };

  render() {
    return this.state.redirect ? (
      <Redirect to={`/`} />
    ) : (
      <div className="page">
        <Logo />
        <Menu />
        <Header />
        <div className="content mt-4 ml-4">
          <h1 className="title">Alterar senha</h1>
          {/*--------------------------------- SENHA ----------------------------------------- */}
          <div className="section password">
            <div className="sectionTitle password">
              <h3 className="item-title">Senha</h3>
            </div>
            <div className="sectionContent password">
              <Input
                placeholder="Insira a nova senha."
                value={this.state.password}
                onChange={(e) => this.handleChange(e, "password")}
                fullWidth
                type={this.state.showPassword[0] ? "text" : "password"}
                error={this.state.error[0]}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={(e) => this.handleClickShowPassword(e, 0)}
                    >
                      {this.state.showPassword[0] ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <p className="password" style={{ marginTop: 10 }}>
                *Mínimo de 6 caracteres
              </p>
            </div>
          </div>
          {/*--------------------------------- CONFIRMAÇÃO ----------------------------------------- */}
          <div className="section password">
            <div className="sectionTitle password">
              <h3 className="item-title">Confirmação</h3>
            </div>
            <div className="sectionContent password">
              <Input
                placeholder="Repita a nova senha."
                value={this.state.confirmation}
                onChange={(e) => this.handleChange(e, "confirmation")}
                fullWidth
                type={this.state.showPassword[1] ? "text" : "password"}
                error={this.state.error[1]}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={(e) => this.handleClickShowPassword(e, 1)}
                    >
                      {this.state.showPassword[1] ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <p className="password" style={{ marginTop: 10 }}>
                *Mínimo de 6 caracteres
              </p>
            </div>
          </div>
          {/*--------------------------------- ENVIAR ----------------------------------------- */}
          <Button
            type="primary"
            className="submit"
            onClick={(e) => this.checkRequired()}
            loading={this.state.sending}
          >
            Alterar
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  localId: user.localId,
  loading: user.isLoading
});

const mapDispatchToProps = (dispatch) => {
  return {
    onChangePassword: (localId, newPassword) =>
      dispatch(updatePassword(localId, newPassword)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AlterarSenha);
