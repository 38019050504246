import React, { Component } from "react";
import Header from "../../../utils/template/Header/Header";
import Logo from "../../../utils/template/Logo/Logo";
import Menu from "../../../utils/template/Menu/Menu";
import { connect } from "react-redux";
import Linkify from 'react-linkify'

const initialState = {
  id: null,
  student: null,
  tutor: null,
  date: null,
  startOfClass: null,
  endOfClass: null,
  subject: [],
  content: null,
  workedInClass: [],
  behavior: [],
  perfomance: null,
  observation: null,
  given: null,
  orientation: null,
  verified: false,
  lido: false,
  messages: [],
  loading: true,
};

class Leitura extends Component {
  state = initialState;

  //Seta o relatório do estado da tela como o selecionado pelo usuário na página de Buscas
  componentDidMount() {
    const report = this.findReport();
    this.setState({
      ...report,
      loading: false,
    });
  }

  //Encontra o relatório selecionado pelo usuário na store
  findReport = () => {
    //O id do relatório deve vir dos parâmetros passados na navegação
    const id = this.props.match.params.id;
    let thisReport = {};
    //O relatório correto é aquele que tem o mesmo id passado como parâmetro
    let reports = this.props.reports.concat(this.props.oldReports)
    reports.forEach((report) => {
      if (report.id === id) {
        thisReport = { ...report };
      }
    });
    return thisReport;
  };

  render() {
    return this.state.loading ? null : (
      <div className="page reading">
        {/*--------------------------------- HEADER ----------------------------------------- */}
        <Header />
        <Logo />
        <Menu />
        <div className="content mt-4 ml-4">
          <h1 className="title">Relatório de aula</h1>
          {/*--------------------------------- ALUNO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Aluno</h3>
            </div>
            <div className="sectionContent">
              <p>{this.state.student}</p>
            </div>
          </div>
          {/*--------------------------------- TUTOR ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Tutor</h3>
            </div>
            <div className="sectionContent">
              <p>{this.state.tutor}</p>
            </div>
          </div>
          {/*--------------------------------- DATA ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Data da aula</h3>
            </div>
            <div className="sectionContent">
              <p>{this.state.date}</p>
            </div>
          </div>
          {/*--------------------------------- HORÁRIO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Horário da aula</h3>
            </div>
            <div className="sectionContent">
              <p>{`${this.state.startOfClass} - ${this.state.endOfClass}`}</p>
            </div>
          </div>
          {/*--------------------------------- MATÉRIAS ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Matérias</h3>
            </div>
            <div className="sectionContent">
              {this.state.subject.map((subject) => (
                <p>{subject}</p>
              ))}
            </div>
          </div>
          {/*--------------------------------- CONTEÚDO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Conteúdo</h3>
            </div>
            <div className="sectionContent">
              <p>{this.state.content}</p>
            </div>
          </div>
          {/*--------------------------------- O QUE FOI TRABALHADO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">O que foi trabalhado</h3>
            </div>
            <div className="sectionContent">
              {this.state.workedInClass.map((workedInClass) => (
                <p>{workedInClass}</p>
              ))}
            </div>
          </div>
          {/*--------------------------------- COMPORTAMENTO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Comportamento</h3>
            </div>
            <div className="sectionContent">
              {this.state.behavior.map((behavior) => (
                <p>{behavior}</p>
              ))}
            </div>
          </div>
          {/*--------------------------------- DESEMPENHO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Desempenho do aluno</h3>
            </div>
            <div className="sectionContent">
              <p>{this.state.perfomance}</p>
            </div>
          </div>
          {/*--------------------------------- OBSERVAÇÃO ----------------------------------------- */}
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target="blank" href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}
          >
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Observação</h3>
            </div>
            <div className="sectionContent">
              <p>{this.state.observation}</p>
            </div>
          </div>
          {/*--------------------------------- DADO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">O que foi dado</h3>
            </div>
            <div className="sectionContent">
              <p>{this.state.given}</p>
            </div>
          </div>
          {/*--------------------------------- ORIENTAÇÃO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Orientação para a próxima aula</h3>
            </div>
            <div className="sectionContent last">
              <p>{this.state.orientation}</p>
            </div>
          </div>
          </Linkify>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ reports }) => ({
  reports: reports.reports,
  oldReports: reports.oldReports
});

export default connect(mapStateToProps)(Leitura);
