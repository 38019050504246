import React, { useState } from "react";
import { Form, Input, Button, Menu as DropdownMenu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";

const months = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

const FormLayoutDemo = (props) => {
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");

  const menuMonths = (months) => (
    <DropdownMenu>
      {months.map((month, index) => (
        <DropdownMenu.Item
          key={month}
          onClick={() => props.changeMonth(index, "month")}
        >
          {month}
        </DropdownMenu.Item>
      ))}
    </DropdownMenu>
  );

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            span: 4,
          },
          wrapperCol: {
            span: 14,
          },
        }
      : null;
  const buttonItemLayout =
    formLayout === "horizontal"
      ? {
          wrapperCol: {
            span: 14,
            offset: 4,
          },
        }
      : null;

  return (
    <div className="mb-5">
      <Form
        layout={"inline"}
        form={form}
        className="form-tutor-hours d-flex align-items-center"
      >
        <Form.Item label="Total de horas dadas" name="layout">
          <div className="totalHours"><p className="content">{props.total}</p></div>
        </Form.Item>
        <Form.Item label="Mês">
          <Dropdown overlay={menuMonths(months)}>
            <Button size="small" className="acoes-massa">
              {months[props.month]}
              <DownOutlined />
            </Button>
          </Dropdown>
        </Form.Item>
        <Form.Item label="Ano">
          <Input
            placeholder="2020"
            value={props.year}
            onChange={(e) => props.changeYear(e, "year")}
          />
        </Form.Item>
        <Form.Item {...buttonItemLayout}>
          <Button
            type="primary"
            onClick={(e) => props.changeTotal(e, "totalHours")}
          >
            Filtrar
          </Button>
        </Form.Item>
        <Form.Item {...buttonItemLayout}>
          <Button
            type="primary"
            onClick={(e) => props.clear(e, "clear")}
          >
            Limpar
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default FormLayoutDemo;
