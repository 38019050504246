import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import Header from "../../../utils/template/Header/Header";
import Logo from "../../../utils/template/Logo/Logo";
import Menu from "../../../utils/template/Menu/Menu";
import {
  cpfMask,
  dateMask,
  phoneMask,
  cellPhoneMask,
  CEPMask,
} from "../../../utils/masks";
import { fillTutorObject } from "../../../utils/objects";
import {
  fetchUserInfos,
  updateUser,
} from "../../../utils/store/actions/registerActions";
import {
  Button,
  message,
  Select,
  Tag,
  TimePicker,
  Menu as DropdownMenu,
  Radio,
  Dropdown,
  Tabs,
  Table,
} from "antd";
import { PlusCircleOutlined, DownOutlined } from "@ant-design/icons";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import { fetchUserClasses } from "../../../utils/store/actions/calendarActions";
import Form from "./Form";
import moment from "moment";

const { TabPane } = Tabs;
const { Option } = Select;
const { RangePicker } = TimePicker;

const options = (key) => {
  switch (key) {
    case "yes":
      return "Aula dada";
    case "no":
      return "Aluno faltou";
    case "suspended":
      return "Suspensa";
    case "holiday":
      return "Feriado";
    default:
      return "Aula dada";
  }
};

const status = ["yes", "no", "suspended", "holiday"];

const weekDays = [
  "Segunda-feira",
  "Terça-feira",
  "Quarta-feira",
  "Quinta-feira",
  "Sexta-feira",
  "Sábado",
];

const initialState = {
  redirect: false,
  showPassword: false,
  sending: false,
  time: [null, null],
  timeString: ["", ""],
  day: "",
  type: "",
  registration: {
    email: "",
    password: "",
  },
  userInfos: {
    name: "",
    email: "",
    rg: "",
    gender: "",
    cpf: "",
    birthDate: "",
    phone: "",
    cellPhone: "",
    address: {
      street: "",
      number: "",
      complement: "",
      neighborhood: "",
      city: "",
      state: "",
      cep: "",
    },
    timeDisponibility: [],
    bankAccount: [
      {
        bank: "",
        numberAccount: "",
        agency: "",
      },
      {
        bank: "",
        numberAccount: "",
        agency: "",
      },
    ],
  },
  error: {
    type: false,
    name: false,
    email: false,
    password: false,
    rg: false,
    cpf: false,
    birthDate: false,
    gender: false,
    cellPhone: false,
    street: false,
    number: false,
    neighborhood: false,
    complement: false,
    city: false,
    state: false,
  },
  day: "",
  time: [null, null],
  timeString: ["", ""],
  type: "",
  year: moment().year(),
  month: moment().month(),
  totalHours: 0,
  filtered: [],
  filter: false,
};

class AtualizacaoCadastro extends Component {
  state = {
    ...initialState,
    time: [null, null],
    timeString: ["", ""],
    registration: { ...initialState.registration },
    userInfos: {
      ...initialState.userInfos,
      address: { ...initialState.userInfos.address },
      timeDisponibility: [],
      bankAccount: [
        { ...initialState.userInfos.bankAccount[0] },
        { ...initialState.userInfos.bankAccount[1] },
      ],
    },
    error: { ...initialState.error },
  };

  componentDidMount() {
    const localId = this.props.match.params.id;
    const type = this.props.location.pathname.includes("tutor")
      ? "tutor"
      : this.props.location.pathname.includes("orientador")
      ? "orientador"
      : "admin";
    this.setState({ ...this.state, type });
    this.props.onfetchUser(type, localId);
    this.props.onFetchUserClasses("tutor", localId);
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.loading && !this.props.loading) {
      const totalHours = this.totalHours(moment().month(), moment().year());
      const userInfos = fillTutorObject(this.props.selectedUser);
      this.setState({ ...this.state, userInfos, loading: false, totalHours });
    }
    if (prevProps.registering && !this.props.registering) {
      const userInfos = fillTutorObject(this.props.selectedUser);
      this.setState({ ...this.state, userInfos, sending: false });
      message.success("Informações atualizadas com sucesso!");
    }
  };

  getaddress = () => {
    axios
      .get(
        `https://api.pagar.me/1/zipcodes/${this.state.userInfos.address.cep}`
      )
      .catch((err) => {
        const state = { ...this.state };
        message.error("CEP não encontrado");
        this.setState({ ...state });
      })
      .then((res) => {
        if (res) {
          const state = { ...this.state };
          state.userInfos.address.street = res.data.street;
          state.userInfos.address.city = res.data.city;
          state.userInfos.address.neighborhood = res.data.neighborhood;
          state.userInfos.address.state = res.data.state;
          this.setState({ ...state });
        }
      });
  };

  menuGender = (
    <DropdownMenu onClick={(e) => this.handleChange(e, "gender")}>
      <DropdownMenu.Item key="Feminino">Feminino</DropdownMenu.Item>
      <DropdownMenu.Item key="Masculino">Masculino</DropdownMenu.Item>
    </DropdownMenu>
  );

  handleChange = (e, option) => {
    let state = { ...this.state };
    switch (option) {
      case "name":
        state.userInfos.name = e.target.value;
        this.setState({ ...state });
        break;
      case "email":
        state.userInfos.email = e.target.value;
        this.setState({ ...state });
        break;
      case "rg":
        state.userInfos.rg = e.target.value;
        this.setState({ ...state });
        break;
      case "cpf":
        state.userInfos.cpf = cpfMask(e.target.value);
        this.setState({ ...state });
        break;
      case "birthDate":
        state.userInfos.birthDate = dateMask(e.target.value);
        this.setState({ ...state });
        break;
      case "gender":
        state.userInfos.gender = e.key;
        this.setState({ ...state });
        break;
      case "phone":
        state.userInfos.phone = phoneMask(e.target.value);
        this.setState({ ...state });
        break;
      case "cellPhone":
        state.userInfos.cellPhone = cellPhoneMask(e.target.value);
        this.setState({ ...state });
        break;
      case "numberAccount":
        state.userInfos.bankAccount.numberAccount = e.target.value;
        this.setState({ ...state });
        break;
      case "agency":
        state.userInfos.bankAccount.agency = e.target.value;
        this.setState({ ...state });
        break;
      case "bank":
        state.userInfos.bankAccount.bank = e.target.value;
        this.setState({ ...state });
        break;
      case "month":
        console.log("month called");
        state.month = e;
        this.setState({ ...state });
        break;
      case "year":
        console.log("year called");
        state.year = e.target.value;
        this.setState({ ...state });
        break;
      case "totalHours":
        state.totalHours = this.totalHours(state.month, state.year);
        state.filtered = this.filterClasses(state.month, state.year);
        state.filter = true;
        this.setState({ ...state });
        break;
      case "clear":
        state.totalHours = 0;
        state.filtered = [];
        state.year = moment().year();
        state.month = moment().month();
        state.filter = false;
        this.setState({ ...state });
        break;
      default:
        this.setState({ ...state });
        break;
    }
  };

  handleaddress = (e, option) => {
    let state = { ...this.state };
    switch (option) {
      case "street":
        state.userInfos.address.street = e.target.value;
        this.setState({ ...state });
        break;
      case "number":
        state.userInfos.address.number = e.target.value;
        this.setState({ ...state });
        break;
      case "complement":
        state.userInfos.address.complement = e.target.value;
        this.setState({ ...state });
        break;
      case "neighborhood":
        state.userInfos.address.neighborhood = e.target.value;
        this.setState({ ...state });
        break;
      case "city":
        state.userInfos.address.city = e.target.value;
        this.setState({ ...state });
        break;
      case "state":
        state.userInfos.address.state = e.target.value;
        this.setState({ ...state });
        break;
      case "cep":
        state.userInfos.address.cep = e.target.value;
        this.setState({ ...state });
        break;
      default:
        this.setState({ ...state });
        break;
    }
  };

  checkRequired = () => {
    let state = { ...this.state };
    let flag = false;

    //Checa quais campos da página foram preenchidos
    state.error.name = state.userInfos.name.trim() === "";
    state.error.email = state.userInfos.email.trim() === "";
    // state.error.rg = state.userInfos.rg.trim() === "";
    // state.error.cpf = state.userInfos.cpf.trim() === "";
    // state.error.birthDate = state.userInfos.birthDate.trim() === "";
    // state.error.gender = state.userInfos.gender.trim() === "";
    // state.error.cellPhone = state.userInfos.cellPhone.trim() === "";
    // state.error.street = state.userInfos.address.street.trim() === "";
    // state.error.number = state.userInfos.address.number.trim() === "";
    // state.error.neighborhood = state.userInfos.address.neighborhood.trim() === "";
    // state.error.city = state.userInfos.address.city.trim() === "";
    // state.error.state = state.userInfos.address.state.trim() === "";
    // state.error.type = state.type.trim() === "";

    for (let key in state.error) {
      if (state.error[key] === true) {
        flag = true;
      }
    }

    if (flag) {
      message.error("Todos os campos obrigatórios precisam ser preenchidos.");
      this.setState({ ...state });
    } else {
      state.sending = true;
      this.setState({ ...state });
      this.props.onLoadUser(
        state.userInfos,
        this.state.type,
        this.props.match.params.id
      );
    }
  };

  menuGender = (
    <DropdownMenu onClick={(e) => this.handleChange(e, "gender")}>
      <DropdownMenu.Item key="Feminino">Feminino</DropdownMenu.Item>
      <DropdownMenu.Item key="Masculino">Masculino</DropdownMenu.Item>
    </DropdownMenu>
  );

  handleTime(time, timeString) {
    let state = { ...this.state };
    state.time = [time[0], time[1]];
    state.timeString = [timeString[0], timeString[1]];
    this.setState({ ...state });
  }

  handleDisponibility() {
    let state = { ...this.state };
    state.userInfos.timeDisponibility.push(
      `${state.day}, ${state.timeString[0]} - ${state.timeString[1]}`
    );
    state.day = "";
    state.time = [null, null];
    state.timeString = ["", ""];
    this.setState({ ...state });
  }

  removeItem(type, item) {
    let state = { ...this.state };
    for (let i = 0; i < state.userInfos.timeDisponibility.length; i++) {
      if (state.userInfos.timeDisponibility[i] === item) {
        state.userInfos.timeDisponibility.splice(i, 1);
      }
    }
    this.setState({ ...state });
  }

  menuStatusClasses = (details) => (
    <DropdownMenu>
      {status.map((classStatus) => (
        <DropdownMenu.Item
          key={classStatus}
          onClick={() => this.handleStatus(details, classStatus)}
        >
          {options(classStatus)}
        </DropdownMenu.Item>
      ))}
    </DropdownMenu>
  );

  pastClasses = (events) => {
    let pastClasses = [];
    const now = moment();
    if (events.length > 0) {
      events.forEach((event) => {
        if (moment(event.moment).isBefore(now)) {
          pastClasses.push(event);
        }
      });
      pastClasses.sort((a, b) => moment(b.moment) - moment(a.moment));
      return pastClasses;
    } else {
      return events;
    }
  };

  totalHours = (month, year) => {
    let hours = 0;
    const events = this.props.userClasses;
    const start = moment().month(month).year(year).startOf("month");
    const end = moment().month(month).year(year).endOf("month");
    if (events.length > 0) {
      events.forEach((event) => {
        if (
          moment(event.moment).isBetween(start, end) &&
          event.confirmed === "yes"
        ) {
          hours = hours + event.duration;
        }
      });
    }
    return hours;
  };

  filterClasses = (month, year) => {
    const events = this.props.userClasses;
    let filtered = [];
    const start = moment().month(month).year(year).startOf("month");
    const end = moment().month(month).year(year).endOf("month");
    if (events.length > 0) {
      filtered = events.filter((event) =>
        moment(event.moment).isBetween(start, end)
      );
    }
    return filtered;
  };

  render() {
    const data = this.state.filter
      ? this.pastClasses(this.state.filtered)
      : this.pastClasses(this.props.userClasses);
    const columns = [
      {
        title: "Id",
        dataIndex: "id",
        key: "id",
        render: (text, record) => (
          <Link to={`/agenda/detalhes/${record.tutor.localId}/${record.id}`}>
            {record.id}
          </Link>
        ),
      },
      {
        title: "Data",
        dataIndex: "date",
        key: "date",
      },
      {
        title: "Horário",
        dataIndex: "start",
        key: "start",
      },
      {
        title: "Aluno",
        dataIndex: "student",
        key: "student",
        render: (text, record) => record.student.name,
      },
      {
        title: "Duração",
        dataIndex: "duration",
        key: "duration",
      },
      {
        title: "Status",
        dataIndex: "confirmed",
        key: "confirmed",
        render: (text, record) => options(text),
      },
    ];
    return this.state.loading ? null : (
      <div className="page tutor">
        {/*-------------------------------------------- HEADER ----------------------------------------- */}
        <Header />
        <Logo />
        <Menu />
        <div className="content mt-4 ml-4">
          <Tabs defaultActiveKey="2">
            <TabPane tab="Informações de cadastro" key="1">
              <h1 className="title">Atualizar informações de cadastro</h1>
              {/*--------------------------------- TIPO ----------------------------------------- */}
              <div className="section">
                <div className="sectionContent">
                  <Radio.Group
                    buttonStyle="solid"
                    name="radiogroup"
                    value={this.state.type}
                    onChange={(e) => this.handleChange(e, "category")}
                  >
                    <Radio value="tutor">Tutor</Radio>
                    <Radio value="orientador">Orientador</Radio>
                    <Radio value="admin">Administrador</Radio>
                  </Radio.Group>
                </div>
              </div>
              {/* --------------------------------- NOME  -----------------------------------------*/}
              <div className="section">
                <div className="sectionTitle">
                  <h3 className="item-title">Nome</h3>
                </div>
                <div className="sectionContent">
                  <Input
                    value={this.state.userInfos.name}
                    onChange={(e) => this.handleChange(e, "name")}
                    error={this.state.error.name}
                    required
                    fullWidth
                  />
                </div>
              </div>
              {/*--------------------------------- EMAIL ----------------------------------------- */}
              <div className="section">
                <div className="sectionTitle">
                  <h3 className="item-title">E-mail</h3>
                </div>
                <div className="sectionContent">
                  <Input
                    value={this.state.userInfos.email}
                    placeholder="exemplo@email.com"
                    onChange={(e) => this.handleChange(e, "email")}
                    error={this.state.error.email}
                    required
                    fullWidth
                  />
                </div>
              </div>
              {/*--------------------------------- RG ----------------------------------------- */}
              <div className="section">
                <div className="sectionTitle">
                  <h3 className="item-title">RG</h3>
                </div>
                <div className="sectionContent">
                  <Input
                    value={this.state.userInfos.rg}
                    placeholder="xxxxxxxxx-x"
                    onChange={(e) => this.handleChange(e, "rg")}
                    fullWidth
                    required={this.type === "aluno" ? false : true}
                    error={this.state.error.rg}
                  />
                </div>
              </div>
              {/*--------------------------------- CPF ----------------------------------------- */}
              <div className="section">
                <div className="sectionTitle">
                  <h3 className="item-title">CPF</h3>
                </div>
                <div className="sectionContent">
                  <Input
                    value={this.state.userInfos.cpf}
                    placeholder="xxx.xxx.xxx-xx"
                    onChange={(e) => this.handleChange(e, "cpf")}
                    required={this.type === "aluno" ? false : true}
                    fullWidth
                    error={this.state.error.cpf}
                  />
                </div>
              </div>
              {/*--------------------------------- NASCIMENTO ----------------------------------------- */}
              <div className="section">
                <div className="sectionTitle">
                  <h3 className="item-title">Data de nascimento</h3>
                </div>
                <div className="sectionContent">
                  <Input
                    value={this.state.userInfos.birthDate}
                    placeholder="dd/mm/aaaa"
                    onChange={(e) => this.handleChange(e, "birthDate")}
                    error={this.state.error.birthDate}
                    required
                    fullWidth
                  />
                </div>
              </div>
              {/*--------------------------------- GÊNERO ----------------------------------------- */}
              <div className="section">
                <div className="sectionTitle">
                  <h3 className="item-title">Gênero</h3>
                </div>
                <div className="sectionContent">
                  <Dropdown overlay={this.menuGender}>
                    <Button size="small" className="acoes-massa">
                      {this.state.userInfos.gender
                        ? this.state.userInfos.gender
                        : "Gênero"}
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                </div>
              </div>
              {/*--------------------------------- TELEFONE ----------------------------------------- */}
              <div className="section">
                <div className="sectionTitle">
                  <h3 className="item-title">Telefone</h3>
                </div>
                <div className="sectionContent">
                  <Input
                    value={this.state.userInfos.phone}
                    placeholder="(xx) xxxx-xxxx"
                    onChange={(e) => this.handleChange(e, "phone")}
                    fullWidth
                  />
                </div>
              </div>
              {/*--------------------------------- CELULAR ----------------------------------------- */}
              <div className="section">
                <div className="sectionTitle">
                  <h3 className="item-title">Celular</h3>
                </div>
                <div className="sectionContent">
                  <Input
                    value={this.state.userInfos.cellPhone}
                    placeholder="(xx) xxxxx-xxxx"
                    onChange={(e) => this.handleChange(e, "cellPhone")}
                    error={this.state.error.cellPhone}
                    fullWidth
                  />
                </div>
              </div>
              {/*--------------------------------- ENDEREÇO ----------------------------------------- */}
              <div className="section">
                <div className="sectionTitle">
                  <h3 className="item-title">Endereço</h3>
                </div>
                <div className="sectionContent address">
                  <Input
                    value={
                      this.state.userInfos.address
                        ? CEPMask(this.state.userInfos.address.cep)
                        : ""
                    }
                    placeholder="CEP"
                    onChange={(e) => this.handleaddress(e, "cep")}
                    required
                    fullWidth
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={(e) => this.getaddress()}>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <Input
                    value={
                      this.state.userInfos.address
                        ? this.state.userInfos.address.street
                        : ""
                    }
                    placeholder="Rua/Avenida"
                    onChange={(e) => this.handleaddress(e, "street")}
                    error={this.state.error.street}
                    required
                    fullWidth
                  />
                  <Input
                    value={
                      this.state.userInfos.address
                        ? this.state.userInfos.address.number
                        : ""
                    }
                    placeholder="Número"
                    onChange={(e) => this.handleaddress(e, "number")}
                    error={this.state.error.number}
                    required
                    fullWidth
                  />
                  <Input
                    value={
                      this.state.userInfos.address
                        ? this.state.userInfos.address.complement
                        : ""
                    }
                    placeholder="Complemento"
                    onChange={(e) => this.handleaddress(e, "complement")}
                    required
                    fullWidth
                  />
                  <Input
                    value={
                      this.state.userInfos.address
                        ? this.state.userInfos.address.neighborhood
                        : ""
                    }
                    placeholder="Bairro"
                    onChange={(e) => this.handleaddress(e, "neighborhood")}
                    error={this.state.error.neighborhood}
                    required
                    fullWidth
                  />
                  <Input
                    value={
                      this.state.userInfos.address
                        ? this.state.userInfos.address.city
                        : ""
                    }
                    placeholder="Cidade"
                    onChange={(e) => this.handleaddress(e, "city")}
                    error={this.state.error.city}
                    required
                    fullWidth
                  />
                  <Input
                    value={
                      this.state.userInfos.address
                        ? this.state.userInfos.address.state
                        : ""
                    }
                    placeholder="Estado"
                    onChange={(e) => this.handleaddress(e, "state")}
                    error={this.state.error.state}
                    required
                    fullWidth
                  />
                </div>
              </div>
              {/*--------------------------------- DISPONIBILIDADE DE HORÁRIO ----------------------------------------- */}
              <div className="section">
                <div className="sectionTitle">
                  <h3 className="item-title">Disponibilidade de horário</h3>
                </div>
                <div className="sectionContent time">
                  {this.state.userInfos.timeDisponibility.map(
                    (timeDisponibility) => (
                      <Tag
                        closable
                        onClose={() =>
                          this.removeItem("disponibility", timeDisponibility)
                        }
                      >
                        {timeDisponibility}
                      </Tag>
                    )
                  )}
                </div>
                <div className="sectionContent time-form">
                  <Select
                    value={this.state.day}
                    style={{ width: 200 }}
                    placeholder="Selecione um dia da semana"
                    onChange={(value) => this.handleChange(value, "day")}
                  >
                    {weekDays.map((day) => (
                      <Option key={`day`} value={day}>
                        {day}
                      </Option>
                    ))}
                  </Select>
                  <RangePicker
                    size="large"
                    placeholder={["Horário inicial", "Horário final"]}
                    value={[this.state.time[0], this.state.time[1]]}
                    onChange={(time, timeString) =>
                      this.handleTime(time, timeString)
                    }
                    format={"HH:mm"}
                  />
                  <Button onClick={(e) => this.handleDisponibility()}>
                    <PlusCircleOutlined />
                  </Button>
                  <p>
                    *Pressione o botão de ok para confirmar a seleção dos
                    horários
                  </p>
                </div>
              </div>
              {/*--------------------------------- CONTA BANCÁRIA ----------------------------------------- */}
              <div className="section">
                <div className="sectionTitle">
                  <h3 className="item-title">Conta bancária</h3>
                </div>
                <div className="sectionContent">
                  <Input
                    value={
                      this.state.userInfos.bankAccount[0]
                        ? this.state.userInfos.bankAccount[0].bank
                        : ""
                    }
                    placeholder="Banco"
                    onChange={(e) => this.handleChange(e, "bank1")}
                    fullWidth
                  />
                  <Input
                    value={
                      this.state.userInfos.bankAccount[0]
                        ? this.state.userInfos.bankAccount[0].numberAccount
                        : ""
                    }
                    placeholder="Número da conta"
                    onChange={(e) => this.handleChange(e, "numberAccount1")}
                    required
                    fullWidth
                  />
                  <Input
                    value={
                      this.state.userInfos.bankAccount[0]
                        ? this.state.userInfos.bankAccount[0].agency
                        : ""
                    }
                    placeholder="Agência"
                    onChange={(e) => this.handleChange(e, "agency1")}
                    required
                    fullWidth
                  />
                </div>
                <div className="sectionContent">
                  <Input
                    value={
                      this.state.userInfos.bankAccount[1]
                        ? this.state.userInfos.bankAccount[1].bank
                        : ""
                    }
                    placeholder="Banco"
                    onChange={(e) => this.handleChange(e, "bank2")}
                    fullWidth
                  />
                  <Input
                    value={
                      this.state.userInfos.bankAccount[1]
                        ? this.state.userInfos.bankAccount[1].numberAccount
                        : ""
                    }
                    placeholder="Número da conta"
                    onChange={(e) => this.handleChange(e, "numberAccount2")}
                    required
                    fullWidth
                  />
                  <Input
                    value={
                      this.state.userInfos.bankAccount[1]
                        ? this.state.userInfos.bankAccount[1].agency
                        : ""
                    }
                    placeholder="Agência"
                    onChange={(e) => this.handleChange(e, "agency2")}
                    required
                    fullWidth
                  />
                </div>
              </div>
              {/*--------------------------------- ENVIAR ----------------------------------------- */}
              <Button
                type="primary"
                className="submit"
                onClick={(e) => this.checkRequired()}
                loading={this.state.sending}
              >
                Enviar
              </Button>
            </TabPane>
            <TabPane tab="Aulas" key="2">
              <div className="mt-5 mr-5 ">
                <Form
                  month={this.state.month}
                  changeMonth={this.handleChange}
                  year={this.state.year}
                  changeYear={this.handleChange}
                  total={this.state.totalHours}
                  changeTotal={this.handleChange}
                  clear={this.handleChange}
                />
                <Table
                  rowClassName={(record, index) =>
                    index % 2 === 0 ? "table-row-dark" : "table-row-light"
                  }
                  rowSelection={{ type: "checkbox" }}
                  dataSource={data}
                  columns={columns}
                  locale={{
                    emptyText: (
                      <img
                        src={require("../../../assets/images/no-data.png")}
                      />
                    ),
                  }}
                />
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ register, calendar }) => ({
  users: register.users,
  selectedUser: register.selectedUser,
  loading: calendar.loading,
  userClasses: calendar.userClasses,
  registering: register.registering,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onfetchUser: (type, localId) => dispatch(fetchUserInfos(type, localId)),
    onLoadUser: (user, type, localId) =>
      dispatch(updateUser(user, type, localId)),
    onFetchUserClasses: (type, localId) => dispatch(fetchUserClasses(type, localId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AtualizacaoCadastro);
