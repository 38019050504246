import React, { Component } from "react";

import moment from "moment";
import { Modal, Form, Radio, Tag, message } from "antd";
import { SliderPicker } from "react-color";
import ItemFormulario from "./ItemFormulario";
import SelecaoFrequencias from "./SelecaoFrequencias";
import { colors } from "../../utils/helpers/estilosGerais";
import { customFrequency } from "./helpers/constants";
import {
  generateMessage,
  generateDaysOfWeek,
  generateFrequencies,
} from "./helpers/functions";

import { OpcoesNotificacao } from "./ModalExclusao";

const formItemLayout = {
  wrapperCol: { span: 20 },
};

// visible,
// this.props.details,
// this.props.isLoading,
// handleOk,
// this.props.handleCancel,
// students,
// tutors,
// allUsers,

export default class Edicao extends Component {
  state = {
    edited: {
      color: colors.classDefault,
      selectedDate: moment(),
      selectedTime: ["00:00", "00:00"],
      type: "class",
      student: { name: "Selecione o aluno" },
      tutor: { name: "Selecione o tutor" },
      visible: {
        frequency: false,
      },
      participants: [],
      attachments: [],
      frequency: { description: "Não se repete", index: 0 },
      title: "",
      description: "",
      moment: null,
      allDay: true,
    },
    error: {
      title: false,
      time: false,
      date: false,
      description: false,
      student: false,
      tutor: false,
      frequency: false,
    },
    isSingle: false,
    showNotificationOptions: false,
    editionType: "this",
    sendNotification: "no",
    loadingFiles: false,
    visible: {
      frequency: false,
      colorPicker: false,
    },
  };

  componentDidMount() {
    if (this.props.details) {
      const split = this.props.details.id.split("-");
      const isSingle = parseInt(split[2]) === 1;
      const showNotificationOptions =
        this.props.details.type === "event" &&
        this.props.userInfos.localId === this.props.details.creator.localId &&
        moment(this.props.details.end).isAfter(moment()) &&
        this.props.details.participants.length > 1;
      this.setState({
        ...this.state,
        isSingle,
        showNotificationOptions,
        edited: {
          ...this.state.edited,
          ...this.props.details,
          selectedTime: [
            moment(this.props.details.start).format("HH:mm"),
            moment(this.props.details.end).format("HH:mm"),
          ],
          selectedDate: moment(this.props.details.start),
        },
      });
    }
  }

  //   componentDidUpdate(prevProps) {
  //     if (prevProps.details !== this.props.details) {
  //       const split = this.props.details.id.split("-");
  //       const isSingle = parseInt(split[2]) === 0;
  //       const showNotificationOptions =
  //         this.props.details.type === "event" &&
  //         this.props.userInfos.localId === this.props.details.creator.localId &&
  //         moment(this.props.details.end).isAfter(moment()) &&
  //         this.props.details.participants.length > 1;
  //       this.setState({
  //         ...this.state,
  //         isSingle,
  //         showNotificationOptions,
  //         edited: {
  //           ...this.state.edited,
  //           ...this.props.details,
  //           selectedTime: [moment(this.props.details.start).format('HH:mm'), moment(this.props.details.end).format('HH:mm')],
  //           selectedDate: moment(this.props.details.start),
  //         },
  //       });
  //     }
  //   }

  handleChange = (e, option) => {
    let state = { ...this.state };
    let auxArray = [];

    switch (option) {
      case "title":
        state.edited.title = e.target.value;
        this.setState({ ...state });
        break;
      case "description":
        state.edited.description = e.target.value;
        this.setState({ ...state });
        break;
      case "date":
        state.edited.selectedDate = e;
        this.setState({ ...state });
        break;
      case "start":
        state.edited.selectedTime[0] = e;
        this.setState({ ...state });
        break;
      case "end":
        state.edited.selectedTime[1] = e;
        if (
          moment(state.edited.selectedTime[1], "HH:mm").isSameOrBefore(
            moment(state.edited.selectedTime[0], "HH:mm")
          )
        ) {
          state.edited.selectedTime[1] = state.edited.selectedTime[0];
          state.edited.selectedTime[0] = e;
        }
        this.setState({ ...state });
        break;
      case "student":
        auxArray = this.props.students.filter((student) => student.name === e);
        state.edited.student = auxArray[0];
        this.setState({ ...state });
        break;
      case "tutor":
        auxArray = this.props.tutors.filter((tutor) => tutor.name === e);
        state.edited.tutor = auxArray[0];
        state.edited.color[this.props.userInfos.localId] = auxArray[0].color;
        this.setState({ ...state });
        break;
      case "participants":
        auxArray = this.props.allUsers.filter((user) => user.name === e);
        if (auxArray.length > 0) {
          state.edited.participants.push(auxArray[0]);
        }
        this.setState({ ...state });
        break;
      case "removeParticipant":
        state.edited.participants = state.edited.participants.filter(
          (user) => user.localId !== e.localId
        );
        this.setState({ ...state });
        break;
      case "frequency":
        auxArray = generateFrequencies(state.edited.selectedDate);
        if (e === 4) {
          state.visible.frequency = true;
          if (state.edited.frequency.index !== 4) {
            state.edited.frequency = {
              ...customFrequency,
              date: state.edited.selectedDate,
              endOn: state.edited.selectedDate,
              index: e,
            };
          }
        } else {
          state.edited.frequency = auxArray[e];
        }
        this.setState({ ...state });
        break;
      case "allDay":
        state.edited.allDay = !state.edited.allDay;
        this.setState({ ...state });
        break;
      case "color":
        state.edited.color[this.props.userInfos.localId] = e;
        this.setState({ ...state });
        break;
      case "attachments":
        state.edited.attachments = e;
        console.log("parent", state.edited.attachments);
        this.setState({ ...state });
        break;
      default:
      case "sendNotification":
        state.sendNotification = e.target.value;
        this.setState({ ...state });
        break;
      case "editionType":
        state.editionType = e.target.value;
        this.setState({ ...state });
        break;
    }
  };

  saveFrequency = (frequency) => {
    let repetionType = "";
    let onDay = null;
    let description = "";
    //Organização dos item do objeto para ser enviado
    switch (frequency.repetionType) {
      case "diariamente":
        repetionType = "day";
        onDay = frequency.onDay;
        description = "Todos os dias";
        break;
      case "semanalmente":
        repetionType = "week";
        onDay = frequency.onDay;
        description = "Semanal: cada " + generateDaysOfWeek(frequency.onDay);
        break;
      //Para os casos mensal e anual, onDay é um dia do mês ou ano, não da semana.
      case "mensalmente":
        repetionType = "month";
        onDay = frequency.date;
        description =
          "Mensal: todo dia " +
          generateMessage(frequency.date, frequency.repetionType);
        break;
      case "anualmente":
        repetionType = "year";
        onDay = frequency.date;
        description =
          "Anual: todo dia " +
          generateMessage(frequency.date, frequency.repetionType);
        break;
      default:
        break;
    }
    this.setState({
      ...this.state,
      edited: {
        ...this.state.edited,
        frequency: {
          ...frequency,
          repetionType,
          endType: frequency.endType,
          description,
          onDay,
        },
      },
      visible: { ...this.state.edited.visible, frequency: false },
    });
  };

  loadingFiles = (status) => {
    this.setState({ ...this.state, loadingFiles: status });
  };

  checkRequisitions = () => {
    //Verifica se todos os campos obrigatórios foram preenchidos
    let check = { ...this.state.error };
    if (this.state.edited.type === "class") {
      check.student =
        this.state.edited.student.name.trim() === "" ||
        this.state.edited.student.name === "Selecione o aluno";
      check.tutor =
        this.state.edited.tutor.name.trim() === "" ||
        this.state.edited.tutor.name === "Selecione o tutor";
      check.date = this.state.edited.selectedDate === null;
      check.time =
        moment(this.state.edited.selectedTime[1], "HH:mm").isSameOrBefore(
          moment(this.state.edited.selectedTime[0], "HH:mm")
        ) && !this.state.edited.allDay;
    } else {
      check.date = this.state.edited.selectedDate === null;
      check.time =
        moment(this.state.edited.selectedTime[1], "HH:mm").isSameOrBefore(
          moment(this.state.edited.selectedTime[0], "HH:mm")
        ) && !this.state.edited.allDay;
    }

    const exists = Object.keys(check).some(function (k) {
      return check[k];
    });

    //Se tudo estiver preenchido, prossegue com a criação do evento
    if (!exists) {
      this.props.handleOk(
        this.state.edited,
        this.state.editionType,
        this.state.sendNotification
      );
    } else {
      message.error("Preencha todos os campos obrigatórios");
      this.setState({ ...this.state, error: { ...check } });
    }
  };
  render() {
    return (
      <React.Fragment>
        <Modal
          title={
            this.state.edited.type === "class"
              ? "Editar aula"
              : this.state.edited.type === "event"
              ? "Editar evento"
              : "Editar feriado"
          }
          okButtonProps={{ loading: this.state.loadingFiles }}
          visible={this.props.visible}
          onOk={this.checkRequisitions}
          onCancel={this.props.handleCancel}
          okText="Salvar"
          cancelText="Cancelar"
          centered
          maskClosable
        >
          {/*-------------------------------- OPÇÕES -------------------------------------------------- */}
          <Form {...formItemLayout}>
            {/*-------------------------------- TÍTULO -------------------------------------------------- */}
            {this.state.edited.type === "event" || this.state.edited.type === "holiday" && (
              <div className="row align-items-baseline mx-0">
                <div className="col-8 px-0">
                  <ItemFormulario
                    type="input"
                    error={!this.state.error.title ? "" : "error"}
                    placeholder="Título do evento"
                    handleChange={(e) => this.handleChange(e, "title")}
                    value={this.state.edited.title}
                    space
                  />
                </div>
                <div className="col-1 px-0">
                  <button
                    className="col-1 button-color-picker mx-0"
                    style={{
                      backgroundColor: this.state.edited.color[
                        this.props.userInfos.localId
                      ],
                    }}
                    onClick={() =>
                      this.setState({
                        ...this.state,
                        visible: { ...this.state.visible, colorPicker: true },
                      })
                    }
                  />
                </div>
              </div>
            )}
            {/*-------------------------------- ALUNO -------------------------------------------------- */}
            {this.state.edited.type === "class" && (
              <ItemFormulario
                type="person"
                list={this.props.students}
                error={!this.state.error.student ? "" : "error"}
                placeholder="Selecione o aluno"
                handleChange={(value) => this.handleChange(value, "student")}
                value={this.state.edited.student}
                disabled
              />
            )}
            {/*-------------------------------- TUTOR -------------------------------------------------- */}
            {this.state.edited.type === "class" && (
              <div className="row align-items-baseline mx-0">
                <div className="col-8 px-0">
                  <ItemFormulario
                    type="person"
                    list={this.props.tutors}
                    error={!this.state.error.tutor ? "" : "error"}
                    placeholder="Selecione o tutor"
                    handleChange={(value) => this.handleChange(value, "tutor")}
                    value={this.state.edited.tutor}
                    space
                  />
                </div>
                <div className="col-1 px-0">
                  <button
                    className="col-1 button-color-picker mx-0"
                    style={{
                      backgroundColor: this.state.edited.color[
                        this.props.userInfos.localId
                      ],
                    }}
                    onClick={() =>
                      this.setState({
                        ...this.state,
                        visible: { ...this.state.visible, colorPicker: true },
                      })
                    }
                  />
                </div>
              </div>
            )}
            {/*-------------------------------- DATA -------------------------------------------------- */}
            <ItemFormulario
              type="date"
              error={!this.state.error.date ? "" : "error"}
              placeholder="Selecione uma data"
              handleChange={(date, dateString) =>
                this.handleChange(date, "date")
              }
              value={this.state.edited.selectedDate}
            />
            {/*-------------------------------- HORÁRIO------------------------------------------------ */}
            <ItemFormulario
              type="time"
              error={!this.state.error.time ? "" : "error"}
              value={[
                this.state.edited.selectedTime[0],
                this.state.edited.selectedTime[1],
              ]}
              handleChange={[
                (time, timesString) => this.handleChange(timesString, "start"),
                (time, timesString) => this.handleChange(timesString, "end"),
              ]}
              checkBox={() => this.handleChange(null, "allDay")}
              allDay={this.state.edited.allDay}
              disabled={this.state.edited.type === "class"}
            />
            {/*-------------------------------- FREQUÊNCIA ------------------------------------------------ */}
            <ItemFormulario
              type="frequency"
              list={generateFrequencies(this.state.edited.selectedDate)}
              error={!this.state.error.frequency ? "" : "error"}
              value={this.state.edited.frequency}
              handleChange={(value) => this.handleChange(value, "frequency")}
              placeholder="Selecione a frequência"
            />
            {/*-------------------------------- PARTICIPANTES -------------------------------------------------- */}
            {this.state.edited.type === "event" && (
              <React.Fragment>
                <ItemFormulario
                  type="person"
                  list={this.props.allUsers}
                  error={!this.state.error.participants ? "" : "error"}
                  placeholder="Adicione participantes"
                  handleChange={(value) =>
                    this.handleChange(value, "participants")
                  }
                  value={{ name: "Adicione participantes" }}
                />
                <div className="participants-tags">
                  {this.state.edited.participants.length > 0 &&
                    this.state.edited.participants.map((item) => {
                      return (
                        item.localId !== this.props.userInfos.localId && (
                          <Tag
                            closable
                            key={`tag-participant-${item.localId}`}
                            onClose={() =>
                              this.handleChange(item, "removeParticipant")
                            }
                          >
                            {item.name} ({item.type.toUpperCase()})
                          </Tag>
                        )
                      );
                    })}
                </div>
              </React.Fragment>
            )}
            {/*-------------------------------- DESCRIÇÃO------------------------------------------------ */}
            <ItemFormulario
              type="input"
              placeholder="Descrição"
              handleChange={(e) => this.handleChange(e, "description")}
              value={this.state.edited.description}
            />
          </Form>
          {/*-------------------------------- ANEXOS -------------------------------------------------- */}
          {this.state.type === "event" || this.state.type === "class"  && (
            <ItemFormulario
              type="file"
              disableOk={this.loadingFiles}
              handleChange={this.handleChange}
              attachmentsFile={this.state.edited.attachments}
            />
          )}
          {!this.state.isSingle && (
            <React.Fragment>
              <React.Fragment>
                <p>
                  {this.state.edited.type === "class"
                    ? "Deseja alterar somente esta ou esta e os próximas aulas?"
                    : "Deseja alterar somente este ou este e os próximos eventos?"}
                </p>
                <Radio.Group
                  buttonStyle="solid"
                  name="exclusion-option"
                  value={this.state.editionType}
                  onChange={(e) => this.handleChange(e, "editionType")}
                  className={"exclusion"}
                >
                  <div className="row mx-0">
                    <div>
                      <Radio value="this">
                        {this.state.edited.type === "class"
                          ? "Esta aula"
                          : "Este evento"}
                      </Radio>
                    </div>
                    <div>
                      <Radio value="all">
                        {this.state.edited.type === "class"
                          ? "Esta e as próximas aulas"
                          : "Este e os próximos eventos"}
                      </Radio>
                    </div>
                  </div>
                </Radio.Group>
              </React.Fragment>
            </React.Fragment>
          )}
          {this.state.showNotificationOptions && (
            <div className="mt-4">
              <p>
                Deseja enviar notificação para os demais convidados do evento?
              </p>
              <OpcoesNotificacao
                value={this.state.sendNotification}
                handleChange={this.handleChange}
              />
            </div>
          )}
        </Modal>
        {/*-------------------------------- MODAL - FREQUÊNCIA -------------------------------------------------- */}
        <SelecaoFrequencias
          visible={this.state.visible.frequency}
          onOk={this.saveFrequency}
          onCancel={() =>
            this.setState({
              ...this.state,
              visible: { ...this.state.visible, frequency: false },
            })
          }
          currentFrequency={this.state.edited.frequency}
        />
        {/*-------------------------------- MODAL - COLOR PICKER -------------------------------------------------- */}
        <Modal
          title="Selecione uma nova cor"
          visible={this.state.visible.colorPicker}
          onOk={() =>
            this.setState({
              ...this.state,
              visible: { ...this.state.visible, colorPicker: false },
            })
          }
          onCancel={() =>
            this.setState({
              ...this.state,
              visible: { ...this.state.visible, colorPicker: false },
            })
          }
          okText="Selecionar"
          cancelText="Cancelar"
          centered
          maskClosable
        >
          <SliderPicker
            color={this.state.edited.color[this.props.userInfos.localId]}
            onChange={(e) => this.handleChange(e.hex, "color")}
          />
        </Modal>
      </React.Fragment>
    );
  }
}
