import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../../../utils/template/Header/Header";
import Logo from "../../../utils/template/Logo/Logo";
import Menu from "../../../utils/template/Menu/Menu";
import { Dropdown, Button, message, Table, Menu as DropdownMenu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  setFilteredUsers,
  clearFilter,
} from "../../../utils/store/actions/registerActions";
const { Search } = Input;

function handleMenuClick(e) {
  message.info("Click on menu item.");
  console.log("click", e);
}

const menu = (
  <DropdownMenu onClick={handleMenuClick}>
    <DropdownMenu.Item key="1">Exluir selecionados</DropdownMenu.Item>
  </DropdownMenu>
);

class BuscaUsuarios extends Component {
  state = {
    filter: "",
    loading: true,
    students: [],
    search: false,
    width: 1080
  };

  componentDidMount() {
    window.addEventListener("resize", () => this.updateWindowDimensions());
  }

  componentWillUnmount() {
    window.removeEventListener("resize", () => this.updateWindowDimensions());
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  columns_lg = [
    {
      title: "Id",
      dataIndex: "localId",
      key: "localId",
      render: (localId) => (
        <Link to={`/usuarios/aluno/${localId}`}>{localId}</Link>
      ),
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
    },
  ];

  columns_xs = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link to={`/usuarios/aluno/${record.localId}`}>{record.name}</Link>
      ),
    },
  ];

  handleChange = (e) => {
    this.setState({ filter: e.target.value });
  };

  clear = () => {
    this.props.onClear();
    this.setState({
      filter: "",
      search: false,
    });
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.handleSearch(this.state.filter);
    }
  };

  handleSearch = (search) => {
    this.setState({ search: true });
    this.props.onFilterByName(search, "aluno");
  };

  render() {
    const filter = this.props.filteredUsers.length;
    let data = [];
    if (this.state.search && filter > 0) {
      this.props.filteredUsers.forEach((user, index) =>
        data.push({ ...user, key: index })
      );
    } else if (!this.state.search) {
      this.props.students.forEach((user, index) =>
        data.push({ ...user, key: index })
      );
    } else if (this.state.search && filter === 0) {
      data = [];
    }
    return (
      <div className="page">
        <Logo />
        <Menu />
        {/*--------------------------------- HEADER ----------------------------------------- */}
        <Header />
        <div className="content mt-4 ml-4">
          <h1 className="title">Alunos</h1>
          {/*--------------------------------- BOTÕES SUPERIORES ------------------------------ */}
          <Row className="mx-0">
            {/*..................................... Ação em massa .............................. */}
            <Col lg={6} md={11} xs={11} className="pl-0 pt-2 my-1">
              <Dropdown overlay={menu}>
                <Button size="small" className="acoes-massa">
                  Ações em massa <DownOutlined />
                </Button>
              </Dropdown>
            </Col>
            {/*..................................... Limpar .................................... */}
            <Col lg={1} md={5} xs={5} className={this.state.width > 540 ? "pr-5 my-1" : "my-1 pl-0"}>
              <Button size="middle" onClick={this.clear}>
                Limpar
              </Button>
            </Col>
            {/*.............................. Campo de pesquisa .................................... */}
            <Col lg={4} md={10} xs={11} className="pl-0 my-1">
              <Search
                size="large"
                value={this.state.filter}
                onChange={this.handleChange}
                onSearch={(value) => this.handleSearch(value)}
                enterButton
                onKeyPress={(e) => this.handleKeyPress(e)}
                placeholder="Buscar aluno"
              />
            </Col>
          </Row>
          {/*--------------------------------- TABELA DE RESULTADOS ------------------------------ */}
          <div className="mt-5 mr-5">
            <Table
              rowClassName={(record, index) =>
                index % 2 === 0 ? "table-row-dark" : "table-row-light"
              }
              rowSelection={{ type: "checkbox" }}
              dataSource={data}
              columns={this.state.width >= 935 ? this.columns_lg : this.columns_xs}
              locale={{
                emptyText: (
                  <img src={require("../../../assets/images/no-data.png")} alt="Imagem 'não encontrado'"/>
                ),
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ register }) => ({
  students: register.students,
  filteredUsers: register.filteredUsers,
  loading: register.loading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onFilterByName: (filter, type) => dispatch(setFilteredUsers(filter, type)),
    onClear: () => dispatch(clearFilter()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuscaUsuarios);
