import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../../../utils/template/Header/Header";
import Logo from "../../../utils/template/Logo/Logo";
import Menu from "../../../utils/template/Menu/Menu";
import { Button, Table, Spin, Space, Select, AutoComplete } from "antd";
import { Input } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { CheckOutlined, CalendarTwoTone } from "@ant-design/icons";
import {
  fetchReports,
  fetchReportsByStudent,
} from "../../../utils/store/actions/reportsActions";
import { filterByName } from "../../../utils/store/reducers/reportReducer";
import {
  fetchStudents,
  fetchTutors,
} from "../../../utils/store/actions/registerActions";
import moment from "moment";
import _ from "lodash";

const { Search } = Input;
const { Option } = Select;

function HeaderWithDate(props) {
  let bday = null;
  let bmonth = null;
  let byear = null;
  let fday = null;
  let fmonth = null;
  let fyear = null;

  if (moment(props.older).date() < 10) {
    bday = `0${moment(props.older).date()}`;
  } else {
    bday = `${moment(props.older).date()}`;
  }

  if (moment(props.newer).date() < 10) {
    fday = `0${moment(props.newer).date()}`;
  } else {
    fday = `${moment(props.newer).date()}`;
  }

  if (moment(props.older).month() + 1 < 10) {
    bmonth = `0${moment(props.older).month() + 1}`;
  } else {
    bmonth = `${moment(props.older).month() + 1}`;
  }

  if (moment(props.newer).month() + 1 < 10) {
    fmonth = `0${moment(props.newer).month() + 1}`;
  } else {
    fmonth = `${moment(props.newer).month() + 1}`;
  }

  byear = moment(props.older).year();
  fyear = moment(props.newer).year();

  return (
    bday && (
      <div className="table-title-date">
        <CalendarTwoTone />
        <p>
          Resultados entre {bday}/{bmonth}/{byear} e {fday}/{fmonth}/{fyear}
        </p>
      </div>
    )
  );
}

class BuscaRelatorios extends Component {
  state = {
    filter: "",
    dates: [null, null],
    firstDate: null,
    finalDate: null,
    search: false,
    width: 1080,
    data: [],
    currentPage: 1,
    options: [],
  };

  componentDidMount() {
    window.addEventListener("resize", () => this.updateWindowDimensions());
  }

  componentWillUnmount() {
    window.removeEventListener("resize", () => this.updateWindowDimensions());
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.isLoading && !this.props.isLoading) {
      this.updateSearch();
    }
    if (prevProps.students.length !== this.props.students.length) {
      let options = this.setOptions(this.props.students);
      this.setState({ ...this.state, options });
    }
  };

  columns_lg = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      render: (text, record) =>
        record.verified ? (
          <Link to={`/relatorios/visualizar/${record.id}`}>{record.id}</Link>
        ) : (
          <Link to={`/relatorios/editar/${record.id}`}>{record.id}</Link>
        ),
    },
    {
      title: "Aluno",
      dataIndex: "student",
      key: "student",
    },
    {
      title: "Data",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Horário",
      dataIndex: "startOfClass",
      key: "startOfClass",
    },
    {
      title: "Tutor",
      dataIndex: "tutor",
      key: "tutor",
    },
    {
      title: "Enviado",
      dataIndex: "verified",
      key: "verified",
      render: (verified) => (verified ? <CheckOutlined /> : null),
    },
  ];

  columns_md = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      render: (text, record) =>
        record.verified ? (
          <Link to={`/relatorios/visualizar/${record.id}`}>{record.id}</Link>
        ) : (
          <Link to={`/relatorios/editar/${record.id}`}>{record.id}</Link>
        ),
    },
    {
      title: "Aluno",
      dataIndex: "student",
      key: "student",
    },
    {
      title: "Data",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Enviado",
      dataIndex: "verified",
      key: "verified",
      render: (verified) => (verified ? <CheckOutlined /> : null),
    },
  ];

  columns_xs = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      render: (text, record) =>
        record.verified ? (
          <Link to={`/relatorios/visualizar/${record.id}`}>{record.id}</Link>
        ) : (
          <Link to={`/relatorios/editar/${record.id}`}>{record.id}</Link>
        ),
    },
    {
      title: "Enviado",
      dataIndex: "verified",
      key: "verified",
      render: (verified) => (verified ? <CheckOutlined /> : null),
    },
  ];

  clear = () => {
    this.props.onClear();
    this.setState({
      filter: "",
      dates: [null, null],
      firstDate: null,
      finalDate: null,
      search: false,
    });
  };

  onSelect = (e) => {
    let filtered = [];
    let found = false;

    filtered = filterByName(this.props.oldReports, e);

    filtered.forEach(report => {
      if(report.student === e){
        found = true;
      }     
    });

    if(!found){
      this.props.onFetchReportsByStudent(e)
    }

    this.setState({
      ...this.state,
      filter: e,
      data: [...filtered],
    });
  };

  updateSearch = () => {
    let filtered = [];

    //Filtra os relatórios mostrados na tabela
    filtered = filterByName(this.props.oldReports, this.state.filter);

    this.setState({
      ...this.state,
      data: [...filtered],
    });
  };

  changeTablePage = (page) => {
    this.setState({
      ...this.state,
      currentPage: page,
    });
  };

  fetchMoreResults = () => {
    this.props.onFetchReports(this.props.older);
  };

  setNumberOfPages = (data) => {
    let numberOfPages = 1;
    if (data.length % 10 === 0) {
      numberOfPages = data.length / 10;
    } else {
      numberOfPages = Math.floor(data.length / 10) + 1;
    }
    return numberOfPages;
  };

  setOptions = (studentsArray) => {
    let options = [];
    studentsArray.forEach((student) => {
      options.push({ value: student.name });
    });
    return options;
  };

  handleSearch = (value) => {
    let allOptions = this.setOptions(this.props.students);
    let options = [];
    let filtered = [];

    //Filtra as opções para o campo de autocomplete
    if (value !== "") {
      allOptions.forEach((option) => {
        const name = _.lowerCase(option.value);
        const toCompare = _.lowerCase(value);
        if (name.includes(toCompare)) {
          options.push(option);
        }
      });
    } else {
      options = this.setOptions(this.props.students);
    }

    //Filtra os relatórios mostrados na tabela
    filtered = filterByName(this.props.oldReports, value);
    this.props.reports.forEach((report) => {
      const name = _.lowerCase(report.student);
      const toCompare = _.lowerCase(value);
      if (name.includes(toCompare)) {
        filtered.push(report);
      }
    });    

    this.setState({
      ...this.state,
      options,
      filter: value,
      data: [...filtered],
    });
  };

  render() {
    let numberOfPages = this.state.filter
      ? this.setNumberOfPages(this.state.data)
      : this.setNumberOfPages(this.props.reports);

    return (
      <div className="page">
        <Logo />
        <Menu />
        {/*--------------------------------- HEADER ----------------------------------------- */}
        <Header />
        <div className="content mt-4 ml-4">
          <h1 className="title">Relatórios de aula</h1>
          {/*--------------------------------- BOTÕES SUPERIORES ------------------------------ */}
          <Row className="mx-0 justify-content-between">
            {/*..................................... Atualizar ................................... */}
            <Col lg={1} md={2} xs={1} className="pl-0 my-1">
              <Button
                size="middle"
                className="mr-4"
                onClick={() => this.props.onFetchReports()}
              >
                Atualizar
              </Button>
            </Col>
            {/*.............................. Campo de pesquisa .................................... */}
            <Col lg={5} md={4} xs={4} className="pl-0 my-1 mr-4">
              <AutoComplete
                onSearch={this.handleSearch}
                onSelect={(e) => this.onSelect(e)}
                placeholder="Buscar aluno"
                options={this.state.options}
                allowClear
              />
            </Col>
          </Row>
          {/*--------------------------------- TABELA DE RESULTADOS ------------------------------ */}
          <div className="mt-5 mr-5 ">
            {!this.props.isLoading ? (
              <Table
                showHeader
                title={() =>
                  HeaderWithDate({
                    older: this.props.older,
                    newer: this.props.newer,
                  })
                }
                pagination={{
                  defaultPageSize: 10,
                  onChange: this.changeTablePage,
                  current: this.state.currentPage,
                }}
                footer={() => {
                  if (this.state.currentPage === numberOfPages && !this.state.filter)
                    return (
                      <Space>
                        <Button
                          onClick={(e) => {
                            this.props.onFetchReports(this.props.older);
                          }}
                          type="primary"
                          shape="round"
                        >
                          Carregar mais resultados
                        </Button>
                      </Space>
                    );
                }}
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-dark" : "table-row-light"
                }
                rowSelection={{ type: "checkbox" }}
                dataSource={
                  this.state.filter
                    ? this.state.data
                    : this.props.reports.length > 0
                    ? this.props.reports
                    : []
                }
                columns={
                  this.state.width >= 935
                    ? this.columns_lg
                    : this.state.width >= 760 && this.state.width <= 935
                    ? this.columns_md
                    : this.columns_xs
                }
                locale={{
                  emptyText: (
                    <img
                      src={require("../../../assets/images/no-data.png")}
                      alt="Imagem 'não encontrado'"
                    />
                  ),
                }}
              />
            ) : (
              <Space
                direction="vertical"
                style={{
                  width: "100%",
                }}
                align="center"
              >
                <Spin tip="Carregando" size="large" />
              </Space>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ reports, register }) => ({
  reports: reports.reports,
  older: reports.timestamps.older,
  newer: reports.timestamps.newer,
  isLoading: reports.isLoading,
  students: register.students,
  oldReports: reports.oldReports
});

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchReports: (lastDate) => dispatch(fetchReports(lastDate)),
    onFetchStudent: () => dispatch(fetchStudents()),
    onFetchTutors: () => dispatch(fetchTutors()),
    onFetchReportsByStudent: (name) => dispatch(fetchReportsByStudent(name)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuscaRelatorios);
