import moment from "moment";

export const functionsBaseURL =
  "https://us-central1-banco-de-dados-realize.cloudfunctions.net";

export const customFrequency = {
  repetionType: "week",
  onDay: [],
  date: moment(),
  day: "",
  month: "",
  endOn: moment(),
  after: 1,
  endType: "never",
};


export const status = ["yes", "no", "suspended", "holiday"];


export const options = [
  "diariamente",
  "semanalmente",
  "mensalmente",
  "anualmente",
];

export const days = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];

export const monthOfTheYear = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];
