export const info = {
  subjects: [
    "Alfabetização",
    "Artes",
    "Biologia",
    "Cálculo",
    "Ciências",
    "Filosofia",
    "Física",
    "Geografia",
    "Gramática",
    "História",
    "Informática",
    "Inglês",
    "Literatura",
    "Matemática",
    "Português",
    "Química",
    "Redação",
    "Sociologia",
  ],
  workedInClass: [
    "Teoria",
    "Explicação do conteúdo",
    "Resolução dos exercícios do livro/apostila",
    "Resolução de exercícios da lista",
    "Solução de dúvidas",
    "Debate",
    "Discussão sobre a proposta de redação",
    "Resolução de prova",
    "Resolução de prova de concurso",
    "Resolução de prova de vestibular",
  ],
  behaviors: [
    "Prestou atenção na aula",
    "Participou da aula",
    "Teve proatividade",
    "Tirou suas dúvidas durante a aula",
    "Apresentou bastante atenção e concentração na aula de hoje",
    "Prestou atenção na aula, copiando e participando da resolução dos exercícios",
    "Anotou todos os exercícios",
    "Anotou tudo do quadro",
    "Aparentava cansaço",
    "Ficou mexendo no celular durante a aula",
    "Estava sem ânimo, mas ainda assim fez as atividades propostas",
    "Não copiou a matéria",
    "Não participou da aula",
    "Estava disperso(a)",
    "Não apresentou proatividade",
    "Interagiu pouco durante a aula"
  ],
  time: ["08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00"]
};

export const createObject = array => {
  let objectArray = []
  let id = 0
  array.forEach(element => {
    const object = {
      name: element,
      isChecked: false,
      id: id,
    }
    id++;
    objectArray.push(object)
  });
  return objectArray
}