import React, { useEffect, useState } from "react";
import { Select, Checkbox, Divider } from "antd";

const { Option } = Select;

export default ({
  type,
  list,
  title,
  selected,
  handleChange,
  selectAll,
  showSelectAll,
  eventType,
}) => {
  const [value, setValue] = useState("Selecione um usuário");
  useEffect(() => {
    if (type === "tutor") {
      setValue("Selecione um tutor");
    } else if (type === "student") {
      setValue("Selecione um aluno");
    } else {
      setValue("Selecione um usuário");
    }
  }, [type]);

  return (
    <div className="modal-schedules-section">
      <p className="modal-section-title">{title}</p>
      <Select
        showSearch
        notFoundContent={
          <img
            src={require("../../../assets/images/no-data-icon.png")}
            alt="Imagem 'não encontrado'"
            style={{ width: 60, marginRight: 30, marginLeft: 30 }}
          />
        }
        optionFilterProp="children"
        onSelect={(value) => {
          if (eventType === "calendar") {
            handleChange(value, "includeSchedule");
          } else {
            handleChange(value, "includeUnavailability");
          }
          setValue(
            type === "tutor" ? "Selecione um tutor" : "Selecione um aluno"
          );
        }}
        onChange={(e) => setValue(e)}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        value={value}
        dropdownRender={(menu) => (
          <React.Fragment>
            {showSelectAll && (
              <React.Fragment>
                <Checkbox
                  checked={selectAll}
                  onClick={() => {
                    if (eventType === "calendar") {
                      handleChange(list, "selectAll");
                    } else {
                      handleChange(list, "selectAllUnavailabilities");
                    }
                  }}
                  className="checkbox-select-all-schedules"
                >
                  Selecionar todos
                </Checkbox>
                <Divider className="divider-select-all-schedules" />
              </React.Fragment>
            )}
            {menu}
          </React.Fragment>
        )}
      >
        {!selectAll &&
          list.map(
            (user) =>
              !selected.includes(user.name) && (
                <Option key={`schedule-${user.name}`} value={user.name}>
                  {user.name}
                </Option>
              )
          )}
      </Select>
    </div>
  );
};
