import {
  SET_MESSAGES,
  SORT_MESSAGES,
  FILTER_MESSAGE_BY_NAME,
  CLEAR_FILTER,
  FILTER_MESSAGE_BY_DATE,
  CHECK_MESSAGES,
  MESSAGE_UPLOADING,
  MESSAGE_SENT,
} from "../actions/actionTypes";
import moment from "moment";
import _ from "lodash"

const initialState = {
  messages: [],
  filteredMessages: [],
  checked: false,
  isLoading: false,
};

const unverifiedMessages = (messages) => {
  let unverified = [];
  messages.forEach((message) => {
      if (!message.readAdmin) {
        unverified.push(message);
      }
    }
  );
  return unverified;
};

const verifiedMessages = (messages) => {
  let verified = [];
  messages.forEach((message) => {
      if (message.readAdmin) {
        verified.push(message);
      }
    }
  );
  return verified;
};

//Função para identificar as mensagens do usuário a partir do filtro (nome passado na busca)
export const filterByName = (messages, filter) => {
  let filtered = [];
  messages.forEach((message) => {
    if (filter !== "") {
      const name = _.lowerCase(message.user);
      const toCompare = _.lowerCase(filter)
      if (name.includes(toCompare)) {
        filtered.push(message);
      }
    }
  });
  return filtered;
};

const filterByDate = (messages, initialDate, finalDate) => {
  let filtered = [];
  const beggining = moment(initialDate, "DD-MM-YYYY").subtract(1, "d");
  const ending = moment(finalDate, "DD-MM-YYYY").add(1, "d");
  messages.forEach((message) => {
    if (moment(message.date, "DD-MM-YYYY").isBetween(beggining, ending)) {
      filtered.push(message);
    }
  });
  return filtered;
};

const reducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case SET_MESSAGES:
      return { ...newState, messages: action.payload, isLoading: false };
    case SORT_MESSAGES:
      const sortedMessages = unverifiedMessages(state.messages).concat(
        verifiedMessages(state.messages)
      );
      return {
        ...newState,
        messages: sortedMessages,
      };
    case FILTER_MESSAGE_BY_NAME:
      const namefiltered = filterByName(state.messages, action.payload);
      let filteredArrayName = null;
      if (namefiltered.length > 0) {
        filteredArrayName = unverifiedMessages(namefiltered).concat(
          verifiedMessages(namefiltered)
        );
      } else {
        filteredArrayName = [
          { notFound: true, verified: true, id: Math.random() },
        ];
      }
      return {
        ...state,
        filteredMessages: filteredArrayName,
      };
    case FILTER_MESSAGE_BY_DATE:
      const datefiltered = filterByDate(
        state.messages,
        action.payload.initialDate,
        action.payload.finalDate
      );
      let filteredArray = null;
      if (datefiltered.length > 0) {
        filteredArray = unverifiedMessages(datefiltered).concat(
          verifiedMessages(datefiltered)
        );
      } else {
        filteredArray = [
          { notFound: true, verified: true, id: Math.random(), idReport: Math.random() },
        ];
      }
      return {
        ...state,
        filteredMessages: filteredArray,
      };
    case CLEAR_FILTER:
      return {
        ...state,
        filteredMessages: [],
      };
    case CHECK_MESSAGES:
      return {
        ...state,
        checked: true,
      };
    case MESSAGE_UPLOADING:
      return {
        ...state,
        isLoading: true,
      };
    case MESSAGE_SENT:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
