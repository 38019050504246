import React from "react";
import {
  Modal,
  Radio,
} from "antd";
import moment from "moment";


export const OpcoesNotificacao = ({ value, handleChange }) => {
    return (
      <Radio.Group
        buttonStyle="solid"
        name="notification-option"
        value={value}
        onChange={(e) => handleChange(e, "sendNotification")}
        className={"exclusion col-9 ml-3"}
      >
        <div className="row">
          <div className="col-2 mr-3">
            <Radio value="yes">Sim</Radio>
          </div>
          <div className="col-2 mr-3">
            <Radio value="no">Não</Radio>
          </div>
        </div>
      </Radio.Group>
    );
  };

export default ({
  visible,
  onOk,
  onCancel,
  sendNotification,
  exclusionType,
  details,
  userInfos,
  handleChange,
}) => {
  const split = details.id.split("-");
  const isSingle = parseInt(split[2]) === 1;
  const showNotificationOptions =
    details.type === "event" &&
    userInfos.localId === details.creator.localId &&
    moment(details.end).isAfter(moment()) &&
    details.participants.length > 1;

  return isSingle ? (
    <Modal
      title="Confirmação de exclusão"
      visible={visible}
      closable={false}
      onOk={onOk}
      onCancel={onCancel}
      okText={showNotificationOptions ? "Excluir" : "Sim"}
      cancelText={showNotificationOptions ? "Cancelar" : "Não"}
      centered
      maskClosable
    >
      <React.Fragment>
        {!showNotificationOptions && (
          <p>Tem certeza que deseja apagar este evento?</p>
        )}
        {showNotificationOptions && (
          <React.Fragment>
            <p>
              Deseja enviar notificação da exclusão para os demais convidados do
              evento?
            </p>
            <OpcoesNotificacao
              value={sendNotification}
              handleChange={handleChange}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    </Modal>
  ) : (
    <Modal
      title="Confirmação de exclusão"
      visible={visible}
      onCancel={onCancel}
      onOk={onOk}
      okText="Excluir"
      cancelText="Cancelar"
      centered
      maskClosable
      closable={false}
    >
      <React.Fragment>
        <React.Fragment>
          <p>
            {details.type === "class"
              ? "Deseja apagar somente esta ou esta e os próximas aulas?"
              : "Deseja apagar somente este ou este e os próximos eventos?"}
          </p>
          <Radio.Group
            buttonStyle="solid"
            name="exclusion-option"
            value={exclusionType}
            onChange={(e) => handleChange(e, "exclusionType")}
            className={"exclusion"}
          >
            <div className="row mx-0">
              <div>
                <Radio value="this">
                  {details.type === "class" ? "Esta aula" : "Este evento"}
                </Radio>
              </div>
              <div>
                <Radio value="all">
                  {details.type === "class"
                    ? "Esta e as próximas aulas"
                    : "Este e os próximos eventos"}
                </Radio>
              </div>
            </div>
          </Radio.Group>
        </React.Fragment>
        {showNotificationOptions && (
          <React.Fragment>
            <p>
              Deseja enviar notificação da exclusão para os demais convidados do
              evento?
            </p>
            <OpcoesNotificacao
              value={sendNotification}
              handleChange={handleChange}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    </Modal>
  );
};
