import React from 'react'
import {Switch, Route, Redirect} from 'react-router'
 
import PaginaInicial from '../modules/PaginaInicial/PaginaInicial'
//Páginas dos relatórios
import Relatorios from '../modules/Relatorios/Busca/Busca'
import EdicaoRelatorio from '../modules/Relatorios/Edicao/Edicao'
import LeituraRelatorio from '../modules/Relatorios/Leitura/Leitura'
//Páginas das mensagens
import Mensagens from '../modules/Mensagens/Busca/Busca'
import MensagensRelatorio from '../modules/Mensagens/Leitura/Leitura'
//Páginas dos usuários
//--Cadastro
import CadastroAlunos from '../modules/Usuarios/Cadastro/Alunos'
import CadastroTutores from '../modules/Usuarios/Cadastro/Tutores'
import CadastroResponsaveis from '../modules/Usuarios/Cadastro/Responsaveis'
//--Perfis
import Aluno from '../modules/Usuarios/Perfis/Alunos'
import Tutor from '../modules/Usuarios/Perfis/Tutores'
import Responsavel from '../modules/Usuarios/Perfis/Responsaveis'
//--Busca
import BuscaAlunos from '../modules/Usuarios/Busca/Alunos'
import BuscaTutores from '../modules/Usuarios/Busca/Tutores'
import BuscaResponsaveis from '../modules/Usuarios/Busca/Responsaveis'
//Configurações
import AlterarSenha from '../modules/Configuracao/AlterarSenha'
//Agenda
import Detalhes from '../modules/Agenda/Detalhes'
import Agenda from '../modules/Agenda/Principal'


export default props => (
  <Switch>
    <Route exact path="/" component={PaginaInicial} />

    <Route exact path="/relatorios" component={Relatorios} />
    <Route exact path="/relatorios/editar/:id" component={EdicaoRelatorio} />
    <Route exact path="/relatorios/visualizar/:id" component={LeituraRelatorio} />

    {/* <Route exact path="/mensagens" component={Mensagens} />
    <Route exact path="/mensagens/visualizar/:id" component={(props) => <MensagensRelatorio {...props}/>} /> */}

    <Route exact path="/usuarios/novo/aluno" component={CadastroAlunos} />
    <Route exact path="/usuarios/novo/tutor" component={CadastroTutores} />
    <Route exact path="/usuarios/novo/responsavel" component={CadastroResponsaveis} />

    <Route exact path="/usuarios/aluno/:id" component={Aluno} />
    <Route exact path="/usuarios/tutor/:id" component={Tutor} />
    <Route exact path="/usuarios/orientador/:id" component={Tutor} />
    <Route exact path="/usuarios/admin/:id" component={Tutor} />
    <Route exact path="/usuarios/responsavel/:id" component={Responsavel} />

    <Route exact path="/usuarios/buscar/alunos" component={BuscaAlunos} />
    <Route exact path="/usuarios/buscar/tutores" component={BuscaTutores} />
    <Route exact path="/usuarios/buscar/responsaveis" component={BuscaResponsaveis} />

    <Route exact path="/configuracoes/alterar-senha" component={AlterarSenha} />

    {/* <Route exact path="/agenda/detalhes/:userId/:classId" component={Detalhes} />
    <Route exact path="/agenda" component={Agenda} /> */}

    <Redirect from="*" to="/"/>
  </Switch>
)
