import React, { Component } from "react";
import { Button } from "antd";
import { requestNewPassword } from "../../../utils/store/actions/userActions";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import MailOutline from "@material-ui/icons/MailOutline";
import { message } from "antd";

class Login extends Component {
  state = {
    email: "",
    error: false
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.loading && !this.props.loading) {
      this.setState({ loading: false });
    }
  };

  makeRequest = () => {
    if (this.state.email.trim() !== "") {
      requestNewPassword(this.state.email);
    } else {
        message.error('Informe um e-mail')
        this.setState({...this.state, error: true})
    }
  };

  handleClickShowPassword = () => {
    this.setState({ ...this.state, showPassword: !this.state.showPassword });
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.makeRequest();
    }
  };

  render() {
    return (
      <div className="login">
        <div className="form">
          <div className="info-password">
            <p>Esqueceu a senha?</p>
            <p>
              Informe seu e-mail cadastrado abaixo, para enviarmos informações
              sobre como recuperar sua senha.
            </p>
          </div>
          <Input
            placeholder="E-mail"
            value={this.state.email}
            onChange={(e) => this.setState({ email: e.target.value })}
            onKeyPress={(e) => this.handleKeyPress(e)}
            fullWidth
            error={this.state.error}
            helperText="Campo obrigatório."
            startAdornment={
              <InputAdornment position="start">
                <MailOutline />
              </InputAdornment>
            }
          />
          <div>
            <Button
              onClick={this.makeRequest}
              loading={this.state.loading}
              type="link"
              size="large"
            >
              Enviar
            </Button>
          </div>
          <div></div>
        </div>
      </div>
    );
  }
}


export default Login;
