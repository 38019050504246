export const cpfMask = value => {
    return value
      .replace(/\D/g, '') // Substitui qualquer caractere que não seja número por nada
      .replace(/(\d{3})(\d)/, '$1.$2') // Coloca um ponto após um grupo de 3 caracteres
      .replace(/(\d{3})(\d)/, '$1.$2') // Coloca um ponto após um grupo de 3 caracteres
      .replace(/(\d{3})(\d{1,2})/, '$1-$2') // Coloca um traço entre um grupo de 3 caracteres e outro de 2
      .replace(/(-\d{2})\d+?$/, '$1') // Captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  }

  export const dateMask = value => {
    return value
      .replace(/\D/g, '') // Substitui qualquer caractere que não seja número por nada
      .replace(/(\d{2})(\d)/, '$1/$2') // Coloca uma barra após um grupo de 2 caracteres
      .replace(/(\d{2})(\d)/, '$1/$2')// Coloca uma barra após um grupo de 2 caracteres
      .replace(/(\d{4})\d+?$/, '$1') // captura 4 numeros seguidos de uma barra e não deixa ser digitado mais nada
  }  

  export const phoneMask = value => {
    return value
    .replace(/D/g,"")                   //Substitui qualquer caractere que não seja número por nada
    .replace(/^(\d{2})(\d)/g,"($1) $2") //Coloca parênteses em volta dos dois primeiros dígitos
    .replace(/(-\d{4})\d+?$/, '$1')
    .replace(/(\d{4})(\d)$/,"$1-$2")    //Coloca hífen entre o quinto e o sexto dígitos
}

export const cellPhoneMask = value => {
    return value
    .replace(/D/g,"")                   //Substitui qualquer caractere que não seja número por nada
    .replace(/^(\d{2})(\d)/g,"($1) $2") //Coloca parênteses em volta dos dois primeiros dígitos
    .replace(/(\d{5})(\d)$/,"$1-$2")    //Coloca hífen entre o quinto e o sexto dígitos
    .replace(/(-\d{4})\d+?$/, '$1')
}

export const CEPMask = value => {
    return value
    .replace(/D/g,"")                   //Substitui qualquer caractere que não seja número por nada
    .replace(/^(\d{5})(\d)/g,"$1-$2") //Coloca hífen entre o quinto e o sexto dígitos
    .replace(/(-\d{3})(\d)$/,"$1")    //Captura 3 numeros seguidos de um traço e não deixa ser digitado mais nada
}