// Users actions types
export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const LOADING_USER = "LOADING_USER";
export const USER_LOADED = "USER_LOADED";
export const NEW_PICTURE = "NEW_PICTURE";
export const CHECK_TOKEN = "CHECK_TOKEN"
export const USER_NOT_AUTHORIZED = "USER_NOT_AUTHORIZED";
export const SET_PUSH_NOTIFICAITON_TOKEN = "SET_PUSH_NOTIFICAITON_TOKEN";
// Reports actions types
export const SORT_REPORTS = "SORT_REPORTS";
export const EDIT_REPORT = "EDIT_REPORT";
export const SET_REPORTS = "SET_REPORTS";
export const UPLOADING_REPORT = "UPLOADING_REPORT";
export const REPORT_SENT = "REPORT_SENT";
export const FILTER_REPORT_BY_DATE = "FILTER_REPORT_BY_DATE";
export const FILTER_REPORT_BY_NAME = "FILTER_REPORT_BY_NAME";
export const SET_OLD_REPORTS = "SET_OLD_REPORTS";
export const REPORT_LOGGED_OUT = "REPORT_LOGGED_OUT";
// Messages actions types
export const SET_MESSAGES = "SET_MESSAGES";
export const SORT_MESSAGES = "SORT_MESSAGES";
export const CHECK_MESSAGES = "CHECK_MESSAGES";
export const MESSAGE_UPLOADING = "MESSAGE_UPLOADING";
export const MESSAGE_SENT = "MESSAGE_SENT";
export const FILTER_MESSAGE_BY_DATE = "FILTER_MESSAGE_BY_DATE";
export const FILTER_MESSAGE_BY_NAME = "FILTER_MESSAGE_BY_NAME";
// Register infos
export const SET_USER_INFO = "SET_USER_INFO";
export const SET_USERS_FOR_REGISTER = "SET_USERS_FOR_REGISTER";
export const FETCHING_INFO = "FETCHING_INFO";
export const INFO_LOADED = "INFO_LOADED";
export const UPDATING_INFO = "UPDATING_INFO";
export const INFO_UPDATED = "INFO_UPDATED";
export const FILTER_USERS_BY_NAME = "FILTER_USERS_BY_NAME";
export const SET_TYPE_USER = "SET_TYPE_USER";
export const SET_STUDENTS = "SET_STUDENTS";
export const SET_TUTORS = "SET_TUTORS";
//Calendar
export const NEW_EVENT = 'NEW_EVENT'
export const SET_VISIBLE = "SET_VISIBLE"
export const SET_USER_CLASSES = "SET_USER_CLASSES"
export const SET_USER_PLANS = "SET_USER_PLANS"
// General actions types
export const CLEAR_FILTER = "CLEAR_FILTER";





