import React from 'react';
import ReactDOM from 'react-dom';
import AuthOrApp from './modules/Autentificacao/AuthOrApp/AuthOrApp';
import * as serviceWorker from './serviceWorker';
import {Provider} from  'react-redux'
import storeConfig from './utils/store/storeConfig'
import axios from 'axios'

const store = storeConfig();

axios.defaults.baseURL = 'https://banco-de-dados-realize.firebaseio.com/'

ReactDOM.render(<Provider store={store}><AuthOrApp /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();