import React, { Component } from "react";
import Header from "../../../utils/template/Header/Header";
import Logo from "../../../utils/template/Logo/Logo";
import Menu from "../../../utils/template/Menu/Menu";
import { connect } from "react-redux";
import { Input, Button } from "antd";
import {
  markAsSeen,
  newMessage,
} from "../../../utils/store/actions/messagesActions";
import { fetchReports } from "../../../utils/store/actions/reportsActions";
import Linkify from "react-linkify";

const { TextArea } = Input;

const initialState = {
  id: null,
  student: null,
  tutor: null,
  date: null,
  startOfClass: null,
  endOfClass: null,
  subject: [],
  content: null,
  workedInClass: [],
  behavior: [],
  perfomance: null,
  observation: null,
  given: null,
  orientation: null,
  verified: false,
  lido: false,
  messages: [],
  loading: true,
  sending: false,
  text: "",
};

class Leitura extends Component {
  state = initialState;

  //Seta o relatório do estado da tela como o selecionado pelo usuário na página de Buscas
  componentDidMount() {
    const report = this.findReport();
    const messages = report.messages;
    this.setState({
      ...this.state,
      report: { ...report },
      messages: messages,
      loading: false,
    });
    this.props.onSeen(report.id);
  }

  // componentDidUpdate = prevProps => {
  //   if(prevProps.loading && !this.props.loading){
  //     const report = this.findReport();
  //     const messages = [...report.messages];
  //     this.setState({
  //       ...report,
  //       messages,
  //       sending: false,
  //     });
  //   }
  // }

  //Encontra o relatório selecionado pelo usuário na store
  findReport = () => {
    //O id do relatório deve vir dos parâmetros passados na navegação
    const id = this.props.match.params.id;
    let thisReport = {};
    //O relatório correto é aquele que tem o mesmo id passado como parâmetro
    this.props.reports.forEach((report) => {
      if (report.id === id) {
        thisReport = { ...report };
      }
    });
    return thisReport;
  };

  SendNewMessage = () => {
    console.log("CALLED")
    let report = {};
    let messages = [];
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    const hour = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    let time = " ";
    if (hour < 10) {
      if (minutes < 10) {
        time = "0" + hour + ":0" + minutes;
      } else {
        time = "0" + hour + ":" + minutes;
      }
    } else {
      if (minutes < 10) {
        time = hour + ":0" + minutes;
      } else {
        time = hour + ":" + minutes;
      }
    }

    let date = " ";

    if (month < 10) {
      if (day < 10) {
        date = "0" + day + "/0" + month + "/" + year;
      } else {
        date = day + "/0" + month + "/" + year;
      }
    } else {
      if (day < 10) {
        date = "0" + day + "/" + month + "/" + year;
      } else {
        date = day + "/" + month + "/" + year;
      }
    }

    const message = {
      user: this.props.user,
      date: date,
      time: time,
      message: this.state.text,
      readAdmin: true,
      readUser: false,
    };

    messages = this.state.messages;
    messages.push({ ...message });
    report = { ...this.state.report, messages };

    this.props.onSendMessage(report, this.state.id);
    this.setState({
      ...this.state,
      text: "",
      report: { ...report },
      messages: [...messages],
    });
  };

  render() {
    return this.state.loading ? null : (
      <div className="page reading">
        <Header />
        <Logo />
        <Menu />
        <div className="content mt-4 ml-4">
          {/*--------------------------------- MENSAGENS----------------------------------------- */}
          <h1 className="title">Mensagens</h1>
          <div className="section">
            {this.state.messages.map((message, index) => (
              <div className="section">
                <div className="sectionTitle">
                  <h6 className="messageTitle">
                    {message.user}, {message.date}, às {message.time}
                  </h6>
                </div>
                <div className="sectionContent">
                  <p>{message.message}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="section">
            <div className="message-field">
              <TextArea
                placeholder="Digite sua mensagem aqui"
                onChange={(e) => this.setState({ text: e.target.value })}
                value={this.state.text}
              />
            </div>
          </div>
          {/*--------------------------------- ENVIAR ----------------------------------------- */}
          <div className="submit">
            <Button
              type="primary"
              loading={this.state.sending}
              onClick={(e) => this.SendNewMessage()}
              disabled={this.state.text.length === 0}
            >
              Enviar
            </Button>
          </div>
          {/*--------------------------------- RELATÓRIO----------------------------------------- */}
          <h1 className="title">Relatório de aula</h1>
          {/*--------------------------------- ALUNO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Aluno</h3>
            </div>
            <div className="sectionContent">
              <p>{this.state.report.student}</p>
            </div>
          </div>
          {/*--------------------------------- TUTOR ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Tutor</h3>
            </div>
            <div className="sectionContent">
              <p>{this.state.report.tutor}</p>
            </div>
          </div>
          {/*--------------------------------- DATA ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Data da aula</h3>
            </div>
            <div className="sectionContent">
              <p>{this.state.report.date}</p>
            </div>
          </div>
          {/*--------------------------------- HORÁRIO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Horário da aula</h3>
            </div>
            <div className="sectionContent">
              <p>{`${this.state.report.startOfClass} - ${this.state.report.endOfClass}`}</p>
            </div>
          </div>
          {/*--------------------------------- MATÉRIAS ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Matérias</h3>
            </div>
            <div className="sectionContent">
              {this.state.report.subject.map((subject) => (
                <p>{subject}</p>
              ))}
            </div>
          </div>
          {/*--------------------------------- CONTEÚDO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Conteúdo</h3>
            </div>
            <div className="sectionContent">
              <p>{this.state.report.content}</p>
            </div>
          </div>
          {/*--------------------------------- O QUE FOI TRABALHADO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">O que foi trabalhado</h3>
            </div>
            <div className="sectionContent">
              {this.state.report.workedInClass.map((workedInClass) => (
                <p>{workedInClass}</p>
              ))}
            </div>
          </div>
          {/*--------------------------------- COMPORTAMENTO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Comportamento</h3>
            </div>
            <div className="sectionContent">
              {this.state.report.behavior.map((behavior) => (
                <p>{behavior}</p>
              ))}
            </div>
          </div>
          {/*--------------------------------- DESEMPENHO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Desempenho do aluno</h3>
            </div>
            <div className="sectionContent">
              <p>{this.state.report.perfomance}</p>
            </div>
          </div>
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target="blank" href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}
          >
            {/*--------------------------------- OBSERVAÇÃO ----------------------------------------- */}
            <div className="section">
              <div className="sectionTitle">
                <h3 className="item-title">Observação</h3>
              </div>
              <div className="sectionContent">
                <p>{this.state.report.observation}</p>
              </div>
            </div>
            {/*--------------------------------- DADO ----------------------------------------- */}
            <div className="section">
              <div className="sectionTitle">
                <h3 className="item-title">O que foi dado</h3>
              </div>
              <div className="sectionContent">
                <p>{this.state.report.given}</p>
              </div>
            </div>
            {/*--------------------------------- ORIENTAÇÃO ----------------------------------------- */}
            <div className="section">
              <div className="sectionTitle">
                <h3 className="item-title">Orientação para a próxima aula</h3>
              </div>
              <div className="sectionContent">
                <p>{this.state.report.orientation}</p>
              </div>
            </div>
          </Linkify>
          {/*--------------------------------- ENVIAR ----------------------------------------- */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ reports, messages, user }) => ({
  reports: reports.reports,
  messages: messages.messages,
  loading: messages.isLoading,
  user: user.name,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onSeen: (idReport) => dispatch(markAsSeen(idReport)),
    onSendMessage: (report, idReport) =>
      dispatch(newMessage(report, idReport)),
    onFetchReports: (lastDate) => dispatch(fetchReports(lastDate)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Leitura);
