import {
  USER_LOGGED_IN,
  USER_LOGGED_OUT,
  NEW_PICTURE,
  USER_LOADED,
  LOADING_USER,
  USER_NOT_AUTHORIZED,
  SET_PUSH_NOTIFICAITON_TOKEN,
} from "../actions/actionTypes";

const initialState = {
  name: null,
  email: null,
  password: null,
  localId: null,
  type: null,
  isLoading: false,
  image: null,
  tokenNotification: null,
  userValidation: {
    token: null,
    tokenSetOn: null,
    tokenValid: false,
    refreshToken: null,
  }
};

const reducer = (state = initialState, action) => {
  let newState = {...state}
  switch (action.type) {
    case USER_LOGGED_IN:
      let image = action.payload.image ? action.payload.image : null
      return {
        ...state,
        name: action.payload.name,
        email: action.payload.email,
        localId: action.payload.localId,
        type: action.payload.type,
        image: image,
        userValidation: {
          token: action.payload.token,
          refreshToken: action.payload.refreshToken,
          tokenValid: true,
          tokenSetOn: action.payload.tokenSetOn
        },
        isLoading: false,
      };
    case USER_LOGGED_OUT:
      return {
        ...initialState,
      };
    case LOADING_USER:
      return {
        ...state,
        isLoading: true,
      };
    case USER_LOADED:
      newState.userValidation.tokenValid = true
      newState.isLoading = false
      return newState
    case USER_NOT_AUTHORIZED: 
    newState.userValidation.tokenValid = false
    newState.isLoading = false
    return newState
    case NEW_PICTURE:
      return {
        ...state,
        image: action.payload.image,
      };
    case SET_PUSH_NOTIFICAITON_TOKEN:
      return {
        ...state,
        tokenNotification: action.payload
      }
    default:
      return state;
  }
};

export default reducer;
