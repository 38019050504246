import moment from "moment";
import { colors } from "../../helpers/estilosGerais";

export const createEventObject = (details, deleted) => {
  let participants = [];
  // if (details.type === "event") {
  //   if (details.participants.length > 0) {
  //     details.participants.forEach((participant) =>
  //       participants.push(participant.name)
  //     );
  //   }
  // }
  const current = moment(details.start).toDate();

  const baseInfo = {
    title:
      details.type === "class"
        ? details.confirmed === "suspended"
          ? "[SUSPENSA] \n\n Aluno: " + details.student.name
          : details.confirmed === "no"
          ? "[FALTOU] \n\n Aluno: " + details.student.name
          : details.confirmed === "holiday"
          ? "[FERIADO] \n\n Aluno: " + details.student.name
          : details.confirmed === "yes" && details.reposition
          ? "[REPOSIÇÃO] \n\n Aluno: " + details.student.name
          : "Aluno: " + details.student.name
        : details.type === "unavailable"
        ? "[INDISPONÍVEL] \n\n Tutor: " + details.user.name
        : details.title.trim() !== ""
        ? details.title
        : "(Sem título)",
    subtitle:
      details.type === "class"
        ? "Tutor: " + details.tutor.name
        : details.type === "event"
        ? `Participantes: ${participants.join(", ")}`
        : "",
    start: details.start,
    end: details.end,
    allDay: details.allDay,
    id: details.id,
  };

  let event = {
    details: {
      ...details,
      ...baseInfo,
    },
    mainInfo: {
      ...baseInfo,
    },
    timestamp: current.getTime(),
  };

  if (deleted) {
    event = { ...event, deleted: true };
  }

  return event;
};

export const createNewEvent = (details, deleted) => {
  const event = createEventObject(details, deleted);
  let events = [];
  events.push(event);
  let repeatedEvent = {
    mainInfo: { ...event.mainInfo },
    details: { ...event.details },
    timestamp: event.timestamp,
  };
  if (deleted) {
    repeatedEvent = {
      mainInfo: { ...event.mainInfo },
      details: { ...event.details },
      timestamp: event.timestamp,
      deleted: event.deleted,
    };
  }
  let idArray = repeatedEvent.details.id.split("-");
  const end = moment(details.start).endOf("year");
  let start = null;
  let numEvents = 0;
  switch (details.frequency.index) {
    case 1:
      start = moment(details.start).startOf("day");
      numEvents = end.diff(start, "days");
      for (let i = parseInt(idArray[3]) + 1; i <= numEvents; i++) {
        repeatedEvent.details.id = `${idArray[0]}-${idArray[1]}-${idArray[2]}-${i}`;
        repeatedEvent.details.start = moment(repeatedEvent.details.start)
          .add(1, "d")
          .toDate();
        repeatedEvent.details.end = moment(repeatedEvent.details.end)
          .add(1, "d")
          .toDate();

        repeatedEvent.mainInfo.start = repeatedEvent.details.start;
        repeatedEvent.mainInfo.end = repeatedEvent.details.end;
        repeatedEvent.mainInfo.id = repeatedEvent.details.id;

        repeatedEvent.timestamp = repeatedEvent.details.start.getTime();

        events.push({
          ...repeatedEvent,
          details: { ...repeatedEvent.details },
          mainInfo: { ...repeatedEvent.mainInfo },
        });
      }
      break;
    case 2:
      start = moment(details.start).startOf("week");
      numEvents = end.diff(start, "weeks");
      for (let i = parseInt(idArray[3]) + 1; i <= numEvents; i++) {
        repeatedEvent.details.id = `${idArray[0]}-${idArray[1]}-${idArray[2]}-${i}`;

        repeatedEvent.details.start = moment(repeatedEvent.details.start)
          .add(7, "d")
          .toDate();
        repeatedEvent.details.end = moment(repeatedEvent.details.end)
          .add(7, "d")
          .toDate();
        repeatedEvent.timestamp = repeatedEvent.details.start.getTime();

        repeatedEvent.mainInfo.start = repeatedEvent.details.start;
        repeatedEvent.mainInfo.end = repeatedEvent.details.end;
        repeatedEvent.mainInfo.id = repeatedEvent.details.id;

        repeatedEvent.timestamp = repeatedEvent.details.start.getTime();

        events.push({
          ...repeatedEvent,
          details: { ...repeatedEvent.details },
          mainInfo: { ...repeatedEvent.mainInfo },
        });
      }
      break;
    case 3:
      start = moment(details.start).startOf("month");
      numEvents = end.diff(start, "months");
      for (let i = parseInt(idArray[3]) + 1; i <= numEvents; i++) {
        repeatedEvent.details.id = `${idArray[0]}-${idArray[1]}-${idArray[2]}-${i}`;

        repeatedEvent.details.start = moment(repeatedEvent.details.start)
          .add(1, "month")
          .toDate();
        repeatedEvent.details.end = moment(repeatedEvent.details.end)
          .add(1, "month")
          .toDate();
        repeatedEvent.timestamp = repeatedEvent.details.start.getTime();

        repeatedEvent.mainInfo.start = repeatedEvent.details.start;
        repeatedEvent.mainInfo.end = repeatedEvent.details.end;
        repeatedEvent.mainInfo.id = repeatedEvent.details.id;

        repeatedEvent.timestamp = repeatedEvent.details.start.getTime();

        events.push({
          ...repeatedEvent,
          details: { ...repeatedEvent.details },
          mainInfo: { ...repeatedEvent.mainInfo },
        });
      }
      break;
    case 4:
      const frequency = repeatedEvent.details.frequency;
      let repetions = 0;
      if (frequency.endType === "after") {
        repetions = frequency.after;
      } else if (frequency.endType === "on") {
        const endDate = moment(frequency.endOn).endOf(frequency.repetionType);
        repetions = endDate.diff(
          moment(repeatedEvent.details.start).startOf(frequency.repetionType),
          `${frequency.repetionType}s`
        );
      } else {
        repetions =  moment(repeatedEvent.details.start).endOf('year').diff(
          moment(repeatedEvent.details.start).startOf(frequency.repetionType),
          `${frequency.repetionType}s`
        );
      }
      if (frequency.repetionType === "day") {
        for (let i = parseInt(idArray[3]) + 1; i <= repetions; i++) {
          repeatedEvent.details.id = `${idArray[0]}-${idArray[1]}-${idArray[2]}-${i}`;
          repeatedEvent.details.start = moment(repeatedEvent.details.start)
            .add(1, "d")
            .toDate();
          repeatedEvent.details.end = moment(repeatedEvent.details.end)
            .add(1, "d")
            .toDate();

          repeatedEvent.mainInfo.start = repeatedEvent.details.start;
          repeatedEvent.mainInfo.end = repeatedEvent.details.end;
          repeatedEvent.mainInfo.id = repeatedEvent.details.id;

          repeatedEvent.timestamp = repeatedEvent.details.start.getTime();

          events.push({
            ...repeatedEvent,
            details: { ...repeatedEvent.details },
            mainInfo: { ...repeatedEvent.mainInfo },
          });
        }
      } else if (frequency.repetionType === "week") {
        frequency.onDay = orderDaysOfWeek(
          frequency.onDay,
          moment(repeatedEvent.details.start).day()
        );
        let k = parseInt(idArray[3]) + 1;
        for (let i = parseInt(idArray[3]) + 1; i <= repetions; i++) {
          frequency.onDay.forEach((day) => {
            let dayOfWeek = moment(repeatedEvent.details.start).day();
            if(k < repetions*frequency.onDay.length){
            while (dayOfWeek !== day) {
              repeatedEvent.details.start = moment(repeatedEvent.details.start)
                .add(1, "d")
                .toDate();
              repeatedEvent.details.end = moment(repeatedEvent.details.end)
                .add(1, "d")
                .toDate();

              repeatedEvent.mainInfo.start = repeatedEvent.details.start;
              repeatedEvent.mainInfo.end = repeatedEvent.details.end;
              repeatedEvent.timestamp = repeatedEvent.details.start.getTime();

              dayOfWeek = moment(repeatedEvent.details.start).day();
            }
            repeatedEvent.details.id = `${idArray[0]}-${idArray[1]}-${idArray[2]}-${k}`;
            repeatedEvent.mainInfo.id = repeatedEvent.details.id;

            k++;
            if (frequency.endType === "on") {
              if (
                moment(repeatedEvent.details.start).isBefore(
                  moment(frequency.endOn).add(1, "d")
                )
              ) {
                events.push({
                  ...repeatedEvent,
                  details: { ...repeatedEvent.details },
                  mainInfo: { ...repeatedEvent.mainInfo },
                });
              }
            } else {
              events.push({
                ...repeatedEvent,
                details: { ...repeatedEvent.details },
                mainInfo: { ...repeatedEvent.mainInfo },
              });
            }}
          });
        }
      } else if (frequency.repetionType === "month") {
        repeatedEvent.details.start = moment(
          `${moment(frequency.onDay).format("DD/MM/YYYY")} ${moment(
            repeatedEvent.details.start
          ).format("HH:mm")}`,
          "DD/MM/YYYY HH:mm"
        ).toDate();

        repeatedEvent.details.end = moment(
          `${moment(frequency.onDay).format("DD/MM/YYYY")} ${moment(
            repeatedEvent.details.end
          ).format("HH:mm")}`,
          "DD/MM/YYYY HH:mm"
        ).toDate();

        repeatedEvent.mainInfo.start = repeatedEvent.details.start;
        repeatedEvent.mainInfo.end = repeatedEvent.details.end;

        repeatedEvent.timestamp = repeatedEvent.details.start.getTime();

        for (let i = parseInt(idArray[3]) + 1; i <= repetions; i++) {
          repeatedEvent.details.id = `${idArray[0]}-${idArray[1]}-${idArray[2]}-${i}`;
          repeatedEvent.mainInfo.id = repeatedEvent.details.id;

          repeatedEvent.details.start = moment(repeatedEvent.details.start)
            .add(1, "month")
            .clone()
            .toDate();
          repeatedEvent.details.end = moment(repeatedEvent.details.end)
            .add(1, "month")
            .clone()
            .toDate();

          repeatedEvent.mainInfo.start = repeatedEvent.details.start;
          repeatedEvent.mainInfo.end = repeatedEvent.details.end;
          repeatedEvent.timestamp = repeatedEvent.details.start.getTime();

          if (frequency.endType === "on") {
            if (
              repeatedEvent.details.start.isBefore(
                moment(frequency.endOn).clone().add(1, "d")
              )
            ) {
              events.push({
                ...repeatedEvent,
                details: { ...repeatedEvent.details },
                mainInfo: { ...repeatedEvent.mainInfo },
              });
            }
          } else {
            events.push({
              ...repeatedEvent,
              details: { ...repeatedEvent.details },
              mainInfo: { ...repeatedEvent.mainInfo },
            });
          }
        }
      } else if (frequency.repetionType === "year") {
        repeatedEvent.details.start = moment(
          `${moment(frequency.onDay).format("DD/MM/YYYY")} ${moment(
            repeatedEvent.details.start
          ).format("HH:mm")}`,
          "DD/MM/YYYY HH:mm"
        ).toDate();

        repeatedEvent.details.end = moment(
          `${moment(frequency.onDay).format("DD/MM/YYYY")} ${moment(
            repeatedEvent.details.start
          ).format("HH:mm")}`,
          "DD/MM/YYYY HH:mm"
        ).toDate();

        repeatedEvent.mainInfo.start = repeatedEvent.details.start;
        repeatedEvent.mainInfo.end = repeatedEvent.details.end;

        repeatedEvent.timestamp = repeatedEvent.details.start.getTime();

        for (let i = parseInt(idArray[3]) + 1; i <= repetions; i++) {
          repeatedEvent.details.id = `${idArray[0]}-${idArray[1]}-${idArray[2]}-${i}`;
          repeatedEvent.mainInfo.id = repeatedEvent.details.id;

          repeatedEvent.details.start = moment(repeatedEvent.details.start)
            .add(1, "years")
            .clone()
            .toDate();
          repeatedEvent.details.end = moment(repeatedEvent.details.end)
            .add(1, "y")
            .clone()
            .toDate();

          repeatedEvent.mainInfo.start = repeatedEvent.details.start;
          repeatedEvent.mainInfo.end = repeatedEvent.details.end;

          repeatedEvent.timestamp = repeatedEvent.details.start.getTime();

          if (frequency.endType === "on") {
            if (
              repeatedEvent.details.start.isBefore(
                moment(frequency.endOn).add(1, "d").clone()
              )
            ) {
              events.push({
                ...repeatedEvent,
                details: { ...repeatedEvent.details },
                mainInfo: { ...repeatedEvent.mainInfo },
              });
            }
          } else {
            events.push({
              ...repeatedEvent,
              details: { ...repeatedEvent.details },
              mainInfo: { ...repeatedEvent.mainInfo },
            });
          }
        }
      }
      break;
  }
  return events;
};

export const eraseEvents = (original, details) => {
  let result = [];
  const splits = details.id.split("-");
  const rootId = splits[1];
  const rootIndex = parseInt(splits[3]);
  original.forEach((item) => {
    const originalSplit = item.details.id.split("-");
    const originalId = originalSplit[1];
    const originalIndex = parseInt(originalSplit[3]);
    if (originalId !== rootId) {
      result.push(item);
    } else if (originalId === rootId && originalIndex < rootIndex) {
      result.push(item);
    }
  });
  return result;
};

export const replaceSingleEvent = (original, replacement) => {
  let result = original.filter(item => item.details.id !== replacement.details.id )
  result = [...result, replacement]
  return result;
};

const orderDaysOfWeek = (array, day) => {
  array.sort();
  let smaller = [];
  let bigger = [];
  smaller = array.filter((item) => item <= day).sort();
  bigger = array.filter((item) => item > day).sort();
  return bigger.concat(smaller);
};

const setOpacity = (hex) =>
  `${hex}${Math.floor(0.2 * 255)
    .toString(16)
    .padStart(2, 0)}`;

export const selectColor = (colorObject, mainInfo, type, userId) => {
  const selectedColor = colorObject[userId]
    ? colorObject[userId]
    : type === "class"
    ? colors.classDefault
    : colors.eventDefault;

  const exibitionColor =
    moment().isAfter(moment(mainInfo.end)) && !mainInfo.allDay
      ? setOpacity(selectedColor)
      : selectedColor;

  return exibitionColor;
};

//   export const replaceEvents = (original, previous) => {
//     let result = [];
//     original.forEach((item) => {
//       if (!previous.some((element) => element.details.id === item.details.id)) {
//         result.push(item);
//       }
//     });
//     return result;
//   };

//   export const replaceSingleEvent = (original, replacement) => {
//     let result = [];
//     original.forEach((item) => {
//       if (item.details.id === replacement.details.id) {
//         result.push(replacement);
//       } else {
//         result.push(item);
//       }
//     });
//     return result;
//   };

//   export const eraseThisEvent = (original, deleted) => {
//     let result = [];
//     original.forEach((item) => {
//       if (item.details.id !== deleted.details.id) {
//         result.push(item);
//       }
//     });
//     return result;
//   };

//   export const eraseEvents = (original, details) => {
//     let result = [];
//     const splits = details.id.split("-");
//     const rootId = splits[1];
//     const rootIndex = parseInt(splits[3]);
//     original.forEach((item) => {
//       const originalSplit = item.details.id.split("-");
//       const originalId = originalSplit[1];
//       const originalIndex = parseInt(originalSplit[3]);
//       if (originalId !== rootId) {
//         result.push(item);
//       } else if (originalId === rootId && originalIndex < rootIndex) {
//         result.push(item);
//       }
//     });
//     return result;
//   };

//   export const checkColor = (events, details) => {
//     let color = "";
//     let userId = "";
//     //Verifica a chave do usuário (userId) e a cor que escolheu para a aula deste tutor
//     if(details.type === "class"){
//     for (let key in details.color) {
//       if (key !== details.tutor.localId) {
//         color = details.color[key];
//         userId = key;
//       }
//     }
//     //Troca a cor de todos os eventos para a cor selecionada acima
//     const updatedEvents = events.map((event) => {
//         if (event.details.type === "class") {
//           if (
//             event.subtitle === details.tutor.name &&
//             event.details.type === details.type
//           ) {
//             if (event.details.color[userId]) {
//               event.details.color[userId] = color;
//             } else {
//               event.details.color[userId] = commonStyles.color.classDefault
//             }
//           }
//         }
//       return event;
//     });
//     return updatedEvents
//   }
//   };
