export const createStudentObject = () => {
  const userInfos = {
    name: "",
    email: "",
    rg: "",
    cpf: "",
    birthDate: "",
    phone: "",
    cellPhone: "",
    gender: "",
    timeDisponibility: [],
    address: {
      street: "",
      number: "",
      complement: "",
      neighborhood: "",
      city: "",
      state: "",
      cep: "",
    },
    schoolYear: "",
    school: "",
    category: "",
  };
  return userInfos;
};

export const createTutorObject = () => {
  const userInfos = {
    name: "",
    email: "",
    rg: "",
    cpf: "",
    birthDate: "",
    phone: "",
    cellPhone: "",
    gender: "",
    timeDisponibility: [],
    address: {
      street: "",
      number: "",
      complement: "",
      neighborhood: "",
      city: "",
      state: "",
      cep: "",
    },
    bankAccount: {
      numberAccount: "",
      bank: "",
      agency: "",
    },
  };
  return userInfos;
};

export const createParentObject = () => {
  const userInfos = {
    name: "",
    email: "",
    rg: "",
    cpf: "",
    birthDate: "",
    phone: "",
    cellPhone: "",
    gender: "",
    address: {
      street: "",
      number: "",
      complement: "",
      neighborhood: "",
      city: "",
      state: "",
      cep: "",
    },
    children: [],
  };
  return userInfos;
};

export const fillStudentObject = (info) => {
  const userInfos = {
    name: info.name ? info.name : "",
    type: info.type ? info.type : "",
    email: info.email ? info.email : "",
    rg: info.rg ? info.rg : "",
    cpf: info.cpf ? info.cpf : "",
    birthDate: info.birthDate ? info.birthDate : "",
    phone: info.phone ? info.phone : "",
    cellPhone: info.cellPhone ? info.cellPhone : "",
    gender: info.gender ? info.gender : "",
    timeDisponibility: info.timeDisponibility ? info.timeDisponibility : [],
    address: info.address
      ? {
          street: info.address.street ? info.address.street : "",
          number: info.address.number ? info.address.number : "",
          complement: info.address.complement ? info.address.complement : "",
          neighborhood: info.address.neighborhood
            ? info.address.neighborhood
            : "",
          city: info.address.city ? info.address.city : "",
          state: info.address.state ? info.address.state : "",
          cep: info.address.cep ? info.address.cep : "",
        }
      : {
          street: "",
          number: "",
          complement: "",
          neighborhood: "",
          city: "",
          state: "",
          cep: "",
        },
    schoolYear: info.schoolYear ? info.schoolYear : "",
    school: info.school ? info.school : "",
    observation: info.observation ? info.observation : "",
  };
  return userInfos;
};

export const fillTutorObject = (info) => {
  const userInfos = {
    name: info.name ? info.name : "",
    email: info.email ? info.email : "",
    rg: info.rg ? info.rg : "",
    cpf: info.cpf ? info.cpf : "",
    birthDate: info.birthDate ? info.birthDate : "",
    phone: info.phone ? info.phone : "",
    cellPhone: info.cellPhone ? info.cellPhone : "",
    gender: info.gender ? info.gender : "",
    timeDisponibility: info.timeDisponibility ? info.timeDisponibility : [],
    address: info.address
      ? {
          street: info.address.street ? info.address.street : "",
          number: info.address.number ? info.address.number : "",
          complement: info.address.complement ? info.address.complement : "",
          neighborhood: info.address.neighborhood
            ? info.address.neighborhood
            : "",
          city: info.address.city ? info.address.city : "",
          state: info.address.state ? info.address.state : "",
          cep: info.address.cep ? info.address.cep : "",
        }
      : {
          street: "",
          number: "",
          complement: "",
          neighborhood: "",
          city: "",
          state: "",
          cep: "",
        },
    bankAccount: Array.isArray(info.bankAccount)
      ? info.bankAccount.length > 1
        ? [{ ...info.bankAccount[0] }, { ...info.bankAccount[1] }]
        : [
            { ...info.bankAccount[0] },
            { numberAccount: "", bank: "", agency: "" },
          ]
      : [{ ...info.bankAccount }, { numberAccount: "", bank: "", agency: "" }],
  };
  return userInfos;
};

export const fillParentObject = (info) => {
  const userInfos = {
    name: info.name ? info.name : "",
    email: info.email ? info.email : "",
    rg: info.rg ? info.rg : "",
    cpf: info.cpf ? info.cpf : "",
    birthDate: info.birthDate ? info.birthDate : "",
    phone: info.phone ? info.phone : "",
    cellPhone: info.cellPhone ? info.cellPhone : "",
    gender: info.gender ? info.gender : "",
    timeDisponibility: info.timeDisponibility ? info.timeDisponibility : [],
    address: info.address
      ? {
          street: info.address.street ? info.address.street : "",
          number: info.address.number ? info.address.number : "",
          complement: info.address.complement ? info.address.complement : "",
          neighborhood: info.address.neighborhood
            ? info.address.neighborhood
            : "",
          city: info.address.city ? info.address.city : "",
          state: info.address.state ? info.address.state : "",
          cep: info.address.cep ? info.address.cep : "",
        }
      : {
          street: "",
          number: "",
          complement: "",
          neighborhood: "",
          city: "",
          state: "",
          cep: "",
        },
    children: info.children ? info.children : [],
  };
  return userInfos;
};
