import React, { Component } from "react";
import Header from "../../utils/template/Header/Header";
import Logo from "../../utils/template/Logo/Logo";
import Menu from "../../utils/template/Menu/Menu";
import { connect } from "react-redux";
import { fetchSingleClass } from "../../utils/store/actions/calendarActions";

const options = (key) => {
  switch (key) {
    case "yes":
      return "Aula dada";
    case "no":
      return "Aluno faltou";
    case "suspended":
      return "Suspensa";
    case "holiday":
      return "Feriado";
    default:
      return "Aula dada";
  }
};

class Detalhes extends Component {
  state = { loading: true };

  //Seta o relatório do estado da tela como o selecionado pelo usuário na página de Buscas
  componentDidMount() {
    const userId = this.props.match.params.userId;
    const classId = this.props.match.params.classId;
    console.log(userId, classId);
    this.props.onFetchUserClasses(userId, classId);
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.loading && !this.props.loading) {
      this.setState({
        ...this.state,
        loading: false,
        details: { ...this.props.userClasses[0] },
      });
    }
  };

  render() {
    return this.state.loading ? null : (
      <div className="page reading">
        {/*--------------------------------- HEADER ----------------------------------------- */}
        <Header />
        <Logo />
        <Menu />
        <div className="content mt-4 ml-4">
          <h1 className="title">Detalhes da aula</h1>
          {/*--------------------------------- STATUS ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Status</h3>
            </div>
            <div className="sectionContent">
              <p>{options(this.state.details.confirmed)}</p>
            </div>
          </div>
          {/*--------------------------------- ALUNO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Aluno</h3>
            </div>
            <div className="sectionContent">
              <p>{this.state.details.student.name}</p>
            </div>
          </div>
          {/*--------------------------------- TUTOR ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Tutor</h3>
            </div>
            <div className="sectionContent">
              <p>{this.state.details.tutor.name}</p>
            </div>
          </div>
          {/*--------------------------------- DATA ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Data da aula</h3>
            </div>
            <div className="sectionContent">
              <p>
                {this.state.details.confimed === "yes"
                  ? this.state.details.date
                  : "-"}
              </p>
            </div>
          </div>
          {/*--------------------------------- HORÁRIO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Horário da aula</h3>
            </div>
            <div className="sectionContent">
              <p>{`${this.state.details.start} - ${this.state.details.end}`}</p>
            </div>
          </div>
          {/*--------------------------------- REPOSIÇÃO ----------------------------------------- */}
          {this.state.reposition && this.state.details.confirmed === "yes" && (
            <div className="section">
              <div className="sectionTitle">
                <h3 className="item-title">Data original da aula</h3>
              </div>
              <div className="sectionContent">
                <p>{this.state.details.originalDate.date}</p>
              </div>
              <div className="sectionTitle">
                <h3 className="item-title">Motivo da reposição</h3>
              </div>
              <div className="sectionContent">
                <p>{options(this.state.details.confirmed)}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ calendar }) => ({
  loading: calendar.loading,
  userClasses: calendar.userClasses,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchUserClasses: (userId, classId) =>
      dispatch(fetchSingleClass(userId, classId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Detalhes);
