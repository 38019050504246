import React from "react";
import Logo from "../../utils/template/Logo/Logo";
import Menu from "../../utils/template/Menu/Menu";
import Header from "../../utils/template/Header/Header";
import { Link } from "react-router-dom";
import { Card, Col, Row } from "antd";

export default (props) => (
  <div className="page">
    <Logo />
    <Menu />
    <Header />
    <div className="content mt-4 ml-4">
      <Row gutter={16}>
        <Col span={8} xs={12} md={6} lg={6} className="my-2">
          <Card title="Relatórios" bordered={false}>
            <Link to="/relatorios">Ver todos os relatórios de aula</Link>
          </Card>
        </Col>
        {/* <Col span={8} xs={12} md={6} lg={6} className="my-2">
          <Card title="Mensagens" bordered={false}>
            <Link to="/mensagens">Ver todas as mensagens recebidas</Link>
          </Card>
        </Col> */}
        {/* <Col span={8} xs={12} md={6} lg={6} className="my-2">
          <Card title="Agenda" bordered={false}>
            <Link to="/agenda">Visualizar agenda</Link>
          </Card>
        </Col> */}
      </Row>
      <Row gutter={16}>
        <Col span={8} xs={12} md={6} lg={6} className="my-2">
          <Card title="Alunos" bordered={false}>
            <Link to="/usuarios/novo/aluno">Cadastrar novo aluno</Link>
            <br />
            <Link to="/usuarios/buscar/alunos">Ver todos os alunos</Link>
            <br />
          </Card>
        </Col>
        <Col span={8} xs={12} md={6} lg={6} className="my-2">
          <Card title="Responsáveis" bordered={false}>
            <Link to="/usuarios/novo/responsavel">
              Cadastrar novo responsável
            </Link>
            <br />
            <Link to="/usuarios/buscar/responsaveis">
              Ver todos os responsáveis
            </Link>
            <br />
          </Card>
        </Col>
        <Col span={8} xs={12} md={6} lg={6} className="my-2">
          <Card title="Tutores" bordered={false}>
            <Link to="/usuarios/novo/tutor">Cadastrar novo tutor</Link>
            <br />
            <Link to="/usuarios/buscar/tutores">Ver todos os tutores</Link>
            <br />
          </Card>
        </Col>
      </Row>
    </div>
  </div>
);
