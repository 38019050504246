import React from "react";
import ShortText from "@material-ui/icons/ShortText";
import {
  ClockCircleOutlined,
  CalendarOutlined,
  UserOutlined,
  FileAddOutlined,
  QuestionCircleOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Divider, Select } from "antd";
import { status } from "./helpers/constants";
import { getStatus } from "./helpers/functions";

const { Option } = Select;

const orange = "#ff8c2e";
const fontSize = 16;
const smallIcon = 14;

export default ({ type, title, value, handleChange, disable }) => {
  let modifications = [];
  if (type === "log") {
    modifications = value.split("\n");
  }
  return (
    <div className="row align-items-center mx-0 px-4">
      {(type === "input" || type === "status" || type === "log") && (
        <div className="col-1 px-0 ">
          <ShortText style={{ color: orange }} />
        </div>
      )}
      {type === "date" && (
        <div className="col-1 px-0">
          <CalendarOutlined style={{ color: orange, fontSize: fontSize }} />
        </div>
      )}
      {type === "time" && (
        <div className="col-1 px-0">
          <ClockCircleOutlined style={{ color: orange, fontSize: fontSize }} />
        </div>
      )}
      {type === "student" && (
        <div className="col-1 px-0">
          <UserOutlined style={{ color: orange, fontSize: fontSize }} />
        </div>
      )}
      {type === "tutor" &&
        (value.answered ? (
          value.accepted ? (
            <div className="col-1 px-0">
              <CheckCircleOutlined
                style={{ color: orange, fontSize: fontSize }}
              />
            </div>
          ) : (
            <div className="col-1 px-0">
              <ExclamationCircleOutlined
                style={{ color: orange, fontSize: fontSize }}
              />
            </div>
          )
        ) : (
          <div className="col-1 px-0">
            <QuestionCircleOutlined
              style={{ color: orange, fontSize: fontSize }}
            />
          </div>
        ))}
      {type === "participants" && (
        <div className="col-1 px-0">
          <UserOutlined style={{ color: orange, fontSize: fontSize }} />
        </div>
      )}
      {type === "frequency" && (
        <div className="col-2 px-0">
          <ClockCircleOutlined style={{ color: orange, fontSize: fontSize }} />
        </div>
      )}
      {type === "file" && (
        <div className="col-1 px-0">
          <FileAddOutlined style={{ color: orange, fontSize: fontSize }} />
        </div>
      )}
      {type !== "participants" &&
        type !== "file" &&
        type !== "status" &&
        type !== "log" && (
          <React.Fragment>
            <div className="col-3 px-0">
              <p className="mb-0" style={{ lineHeight: 2, height: 24 }}>
                {title}:{" "}
              </p>
            </div>
            <div className="col-6 px-0 justify-content-start align">
              <p className="mb-0" style={{ lineHeight: 2, height: 24 }}>
                {type === "student" || type === "tutor" ? value.name : value}
              </p>
            </div>
          </React.Fragment>
        )}
      {type === "status" && (
        <React.Fragment>
          <div className="col-3 px-0">
            <p className="mb-0" style={{ lineHeight: 2, height: 24 }}>
              {title}:{" "}
            </p>
          </div>
          <div className="col-6 px-0 justify-content-start align">
            <Select
              optionFilterProp="children"
              defaultValue={value.confirmed}
              onChange={(e) => handleChange(value, e)}
            >
              {status.map((item) => (
                <Option key={item} value={item}>
                  {getStatus(item)}
                </Option>
              ))}
            </Select>
          </div>
        </React.Fragment>
      )}
      {(type === "participants" || type === "file") && (
        <div className="col pl-0">
          <div>
            <p className="mb-0" style={{ lineHeight: 2, height: 24 }}>
              {title}:{" "}
            </p>
          </div>
          {value.map((item) => (
            <div>
              {type === "participants" && (
                <div className="row align-items-center mx-0">
                  {item.creator ? (
                    <CheckCircleOutlined
                      style={{ color: orange, fontSize: smallIcon }}
                    />
                  ) : item.answered ? (
                    item.accepted ? (
                      <CheckCircleOutlined
                        style={{ color: orange, fontSize: smallIcon }}
                      />
                    ) : (
                      <ExclamationCircleOutlined
                        style={{ color: orange, fontSize: smallIcon }}
                      />
                    )
                  ) : (
                    <QuestionCircleOutlined
                      style={{ color: orange, fontSize: smallIcon }}
                    />
                  )}
                  <p
                    className="ml-2 mb-0"
                    style={{ lineHeight: 2, height: 24 }}
                  >
                    {item.name}
                    {item.creator && " (Organizador)"}
                  </p>
                </div>
              )}
              {type === "file" && (
                <a
                  className="mb-0"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={item.uri}
                  style={{ lineHeight: 2, height: 24 }}
                >
                  {item.name}
                </a>
              )}
            </div>
          ))}
        </div>
      )}
      {type === "log" && (
        <div className="col pl-0">
          <div>
            <p className="mb-2" style={{ lineHeight: 2, height: 24 }}>
              {title}:{" "}
            </p>
          </div>
          {modifications.map((item) => {
            let parts = item.split("[");

            // if(parts.length > 2){
            //   parts[1] = parts[1] + ":" + parts[2] + ":" + parts[3] + ":" + parts[4] + ":" + parts[5]
            // }
            return (
              item.trim() !== "" && (
                <p className="mb-2">
                  <b>{parts[0]}</b> [{parts[1]} [{parts[2]} [{parts[3]}
                </p>
              )
            );
          })}
        </div>
      )}
      {!disable && <Divider />}
    </div>
  );
};
