import React, { Component } from "react";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import axios from "axios";
import Header from "../../../utils/template/Header/Header";
import Logo from "../../../utils/template/Logo/Logo";
import Menu from "../../../utils/template/Menu/Menu";
import {
  cpfMask,
  dateMask,
  phoneMask,
  cellPhoneMask,
  CEPMask,
} from "../../../utils/masks";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import { PlusCircleOutlined, DownOutlined } from "@ant-design/icons";
import {
  Button,
  message,
  Select,
  Tag,
  TimePicker,
  Radio,
  Menu as DropdownMenu,
  Dropdown,
} from "antd";
import {
  newUser,
  fetchAllUsers,
} from "../../../utils/store/actions/registerActions";

const { Option } = Select;
const { RangePicker } = TimePicker;

const weekDays = [
  "Segunda-feira",
  "Terça-feira",
  "Quarta-feira",
  "Quinta-feira",
  "Sexta-feira",
  "Sábado",
];

const initialState = {
  redirect: false,
  showPassword: false,
  sending: false,
  time: [null, null],
  timeString: ["", ""],
  day: "",
  type: "",
  registration: {
    email: "",
    password: "",
  },
  userInfos: {
    name: "",
    email: "",
    rg: "",
    gender: "",
    cpf: "",
    birthDate: "",
    phone: "",
    cellPhone: "",
    address: {
      street: "",
      number: "",
      complement: "",
      neighborhood: "",
      city: "",
      state: "",
      cep: "",
    },
    timeDisponibility: [],
    bankAccount: [
      {
        bank: "",
        numberAccount: "",
        agency: "",
      },
      {
        bank: "",
        numberAccount: "",
        agency: "",
      },
    ],
  },
  error: {
    type: false,
    name: false,
    email: false,
    password: false,
    rg: false,
    cpf: false,
    birthDate: false,
    gender: false,
    cellPhone: false,
    street: false,
    number: false,
    neighborhood: false,
    complement: false,
    city: false,
    state: false,
  },
};

class CadastroResponsaveis extends Component {
  state = {
    ...initialState,
    time: [null, null],
    timeString: ["", ""],
    registration: { ...initialState.registration },
    userInfos: {
      ...initialState.userInfos,
      address: { ...initialState.userInfos.address },
      timeDisponibility: [],
      bankAccount: [{...initialState.userInfos.bankAccount[0]}, {...initialState.userInfos.bankAccount[1]}],
    },
    error: { ...initialState.error },
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.loading && !this.props.loading) {
      this.setState({
        ...initialState,
        time: [null, null],
        timeString: ["", ""],
        registration: { ...initialState.registration },
        userInfos: {
          ...initialState.userInfos,
          address: { ...initialState.userInfos.address },
          timeDisponibility: [],
          bankAccount: [{...initialState.userInfos.bankAccount[0]}, {...initialState.userInfos.bankAccount[1]}],
        },
        error: { ...initialState.error },
        redirect: true,
        sending: false,
      });
    }
  };

  handleClickShowPassword = () => {
    this.setState({ ...this.state, showPassword: !this.state.showPassword });
  };

  menuGender = (
    <DropdownMenu onClick={(e) => this.handleChange(e, "gender")}>
      <DropdownMenu.Item key="Feminino">Feminino</DropdownMenu.Item>
      <DropdownMenu.Item key="Masculino">Masculino</DropdownMenu.Item>
    </DropdownMenu>
  );

  getAdress = () => {
    axios
      .get(
        `https://api.pagar.me/1/zipcodes/${this.state.userInfos.address.cep}`
      )
      .catch((err) => {
        const state = { ...this.state };
        state.userInfos.address.street = "";
        state.userInfos.address.city = "";
        state.userInfos.address.neighborhood = "";
        state.userInfos.address.state = "";
        this.setState({ ...state });
        message.error("CEP não encontrado");
      })
      .then((res) => {
        if (res) {
          const state = { ...this.state };
          state.userInfos.address.street = res.data.street;
          state.userInfos.address.city = res.data.city;
          state.userInfos.address.neighborhood = res.data.neighborhood;
          state.userInfos.address.state = res.data.state;
          this.setState({ ...state });
        }
      });
  };

  handleAdress = (e, option) => {
    let state = { ...this.state };
    switch (option) {
      case "street":
        state.userInfos.address.street = e.target.value;
        this.setState({ ...state });
        break;
      case "number":
        state.userInfos.address.number = e.target.value;
        this.setState({ ...state });
        break;
      case "complement":
        state.userInfos.address.complement = e.target.value;
        this.setState({ ...state });
        break;
      case "neighborhood":
        state.userInfos.address.neighborhood = e.target.value;
        this.setState({ ...state });
        break;
      case "city":
        state.userInfos.address.city = e.target.value;
        this.setState({ ...state });
        break;
      case "state":
        state.userInfos.address.state = e.target.value;
        this.setState({ ...state });
        break;
      case "cep":
        state.userInfos.address.cep = e.target.value;
        this.setState({ ...state });
        break;
      default:
        this.setState({ ...state });
        break;
    }
  };

  checkRequired = () => {
    let state = { ...this.state };
    let flag = false;

    //Checa quais campos da página foram preenchidos
    state.error.type = state.type.trim() === "";
    state.error.name = state.userInfos.name.trim() === "";
    state.error.email = state.userInfos.email.trim() === "";
    state.error.password = state.registration.password.length < 6;
    // state.error.rg = state.userInfos.rg.trim() === "";
    // state.error.cpf = state.userInfos.cpf.trim() === "";
    // state.error.birthDate = state.userInfos.birthDate.trim() === "";
    // state.error.gender = state.userInfos.gender.trim() === "";
    // state.error.cellPhone = state.userInfos.cellPhone.trim() === "";
    // state.error.street = state.userInfos.address.street.trim() === "";
    // state.error.number = state.userInfos.address.number.trim() === "";
    // state.error.neighborhood = state.userInfos.address.neighborhood.trim() === "";
    // state.error.city = state.userInfos.address.city.trim() === "";
    // state.error.state = state.userInfos.address.state.trim() === "";
    // state.error.type = state.type.trim() === "";

    for (let key in state.error) {
      if (state.error[key] === true) {
        flag = true;
      }
    }

    if (flag) {
      message.error("Todos os campos obrigatórios precisam ser preenchidos.");
      this.setState({ ...state });
    } else {
      state.sending = true;
      this.setState({ ...state });
      const data = {
        type: state.type,
        folder: state.type === "tutor" ? "tutores" : "orientadores",
        registration: { ...state.registration },
        userInfos: { ...state.userInfos },
      };
      this.props.onRegisterUser(data);
      message.info(
        "Aguarde um momento, as informações estão sendo processadas."
      );
    }
  };

  handleTime(time, timeString) {
    let state = { ...this.state };
    state.time = [time[0], time[1]];
    state.timeString = [timeString[0], timeString[1]];
    this.setState({ ...state });
  }

  handleDisponibility() {
    let state = { ...this.state };
    state.userInfos.timeDisponibility.push(
      `${state.day}, ${state.timeString[0]} - ${state.timeString[1]}`
    );
    state.day = "";
    state.time = [null, null];
    state.timeString = ["", ""];
    this.setState({ ...state });
  }

  removeItem(type, item) {
    let state = { ...this.state };
    if (type === "disponibility") {
      for (let i = 0; i < state.userInfos.timeDisponibility.length; i++) {
        if (state.userInfos.timeDisponibility[i] === item) {
          state.userInfos.timeDisponibility.splice(i, 1);
        }
      }
    }
    this.setState({ ...state });
  }

  handleChange = (e, option) => {
    let state = { ...this.state };
    switch (option) {
      case "name":
        state.userInfos.name = e.target.value;
        this.setState({ ...state });
        break;
      case "email":
        state.userInfos.email = e.target.value;
        state.registration.email = e.target.value;
        this.setState({ ...state });
        break;
      case "password":
        state.registration.password = e.target.value;
        this.setState({ ...state });
        break;
      case "rg":
        state.userInfos.rg = e.target.value;
        this.setState({ ...state });
        break;
      case "cpf":
        state.userInfos.cpf = cpfMask(e.target.value);
        this.setState({ ...state });
        break;
      case "birthDate":
        state.userInfos.birthDate = dateMask(e.target.value);
        this.setState({ ...state });
        break;
      case "gender":
        state.userInfos.gender = e.key;
        this.setState({ ...state });
        break;
      case "phone":
        state.userInfos.phone = phoneMask(e.target.value);
        this.setState({ ...state });
        break;
      case "cellPhone":
        state.userInfos.cellPhone = cellPhoneMask(e.target.value);
        this.setState({ ...state });
        break;
      case "timeDisponibility":
        state.userInfos.timeDisponibility.push(e);
        this.setState({ ...state });
        break;
      case "category":
        state.type = e.target.value;
        this.setState({ ...state });
        break;
      case "numberAccount1":
        state.userInfos.bankAccount[0].numberAccount = e.target.value;
        this.setState({ ...state });
        break;
      case "agency1":
        state.userInfos.bankAccount[0].agency = e.target.value;
        this.setState({ ...state });
        break;
      case "bank1":
        state.userInfos.bankAccount[0].bank = e.target.value;
        this.setState({ ...state });
        break;
      case "numberAccount2":
        state.userInfos.bankAccount[1].numberAccount = e.target.value;
        this.setState({ ...state });
        break;
      case "agency2":
        state.userInfos.bankAccount[1].agency = e.target.value;
        this.setState({ ...state });
        break;
      case "bank2":
        state.userInfos.bankAccount[1].bank = e.target.value;
        this.setState({ ...state });
        break;
      case "day":
        state.day = e;
        this.setState({ ...state });
        break;
      default:
        this.setState({ ...state });
        break;
    }
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.getAdress();
    }
  };

  render() {
    return this.state.redirect ? (
      <Redirect to={`/usuarios/buscar/tutores`} />
    ) : this.state.loading ? null : (
      <div className="page tutor">
        {/*--------------------------------- HEADER ----------------------------------------- */}
        <Header />
        <Logo />
        <Menu />
        <div className="content mt-4 ml-4">
          <h1 className="title">Cadastro de novo tutor/orientador</h1>
          {/*--------------------------------- Tutor ----------------------------------------- */}
          <div className="section">
            <div
              className={`sectionContent full-border ${
                this.state.error.type ? "error" : ""
              }`}
            >
              <Radio.Group
                buttonStyle="solid"
                name="radiogroup"
                value={this.state.type}
                onChange={(e) => this.handleChange(e, "category")}
              >
                <Radio value="tutor">Tutor</Radio>
                <Radio value="orientador">Orientador</Radio>
              </Radio.Group>
            </div>
          </div>
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Nome</h3>
            </div>
            <div className="sectionContent">
              <Input
                value={this.state.userInfos.name}
                onChange={(e) => this.handleChange(e, "name")}
                error={this.state.error.name}
                required
                fullWidth
              />
            </div>
          </div>
          {/*--------------------------------- EMAIL ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">E-mail</h3>
            </div>
            <div className="sectionContent">
              <Input
                value={this.state.userInfos.email}
                placeholder="exemplo@email.com"
                onChange={(e) => this.handleChange(e, "email")}
                error={this.state.error.email}
                required
                fullWidth
              />
            </div>
          </div>
          {/*--------------------------------- SENHA ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Senha</h3>
            </div>
            <div className="sectionContent">
              <Input
                placeholder="xxx-xxx-xxx"
                value={this.state.registration.password}
                onChange={(e) => this.handleChange(e, "password")}
                fullWidth
                type={this.state.showPassword ? "text" : "password"}
                error={this.state.error.password}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={this.handleClickShowPassword}>
                      {this.state.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <p className="password">*Mínimo de 6 caracteres</p>
            </div>
          </div>
          {/*--------------------------------- RG ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">RG</h3>
            </div>
            <div className="sectionContent">
              <Input
                value={this.state.userInfos.rg}
                placeholder="xxxxxxxxx-x"
                onChange={(e) => this.handleChange(e, "rg")}
                fullWidth
                error={this.state.error.rg}
              />
            </div>
          </div>
          {/*--------------------------------- CPF ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">CPF</h3>
            </div>
            <div className="sectionContent">
              <Input
                value={this.state.userInfos.cpf}
                placeholder="xxx.xxx.xxx-xx"
                onChange={(e) => this.handleChange(e, "cpf")}
                fullWidth
                error={this.state.error.cpf}
              />
            </div>
          </div>
          {/*--------------------------------- NASCIMENTO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Data de nascimento</h3>
            </div>
            <div className="sectionContent">
              <Input
                value={this.state.userInfos.birthDate}
                placeholder="dd/mm/aaaa"
                onChange={(e) => this.handleChange(e, "birthDate")}
                error={this.state.error.birthDate}
                required
                fullWidth
              />
            </div>
          </div>
          {/*--------------------------------- GÊNERO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Gênero</h3>
            </div>
            <div
              className={`sectionContent ${
                this.state.error.gender ? "error" : ""
              }`}
            >
              <Dropdown overlay={this.menuGender}>
                <Button size="small" className="acoes-massa">
                  {this.state.userInfos.gender
                    ? this.state.userInfos.gender
                    : "Gênero"}
                  <DownOutlined />
                </Button>
              </Dropdown>
            </div>
          </div>
          {/*--------------------------------- TELEFONE ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Telefone</h3>
            </div>
            <div className="sectionContent">
              <Input
                value={this.state.userInfos.phone}
                placeholder="(xx) xxxx-xxxx"
                onChange={(e) => this.handleChange(e, "phone")}
                fullWidth
              />
            </div>
          </div>
          {/*--------------------------------- CELULAR ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Celular</h3>
            </div>
            <div className="sectionContent">
              <Input
                value={this.state.userInfos.cellPhone}
                placeholder="(xx) xxxxx-xxxx"
                onChange={(e) => this.handleChange(e, "cellPhone")}
                error={this.state.error.cellPhone}
                fullWidth
              />
            </div>
          </div>
          {/*--------------------------------- ENDEREÇO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Endereço</h3>
            </div>
            <div className="sectionContent address">
              <Input
                value={CEPMask(this.state.userInfos.address.cep)}
                placeholder="CEP"
                onChange={(e) => this.handleAdress(e, "cep")}
                fullWidth
                onKeyPress={(e) => this.handleKeyPress(e)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={(e) => this.getAdress()}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
              <Input
                value={this.state.userInfos.address.street}
                placeholder="Rua/Avenida"
                onChange={(e) => this.handleAdress(e, "street")}
                error={this.state.error.street}
                required
                fullWidth
              />
              <Input
                value={this.state.userInfos.address.number}
                placeholder="Número"
                onChange={(e) => this.handleAdress(e, "number")}
                error={this.state.error.number}
                required
                fullWidth
              />
              <Input
                value={this.state.userInfos.address.complement}
                placeholder="Complemento"
                onChange={(e) => this.handleAdress(e, "complement")}
                required
                fullWidth
              />
              <Input
                value={this.state.userInfos.address.neighborhood}
                placeholder="Bairro"
                onChange={(e) => this.handleAdress(e, "neighborhood")}
                error={this.state.error.neighborhood}
                required
                fullWidth
              />
              <Input
                value={this.state.userInfos.address.city}
                placeholder="Cidade"
                onChange={(e) => this.handleAdress(e, "city")}
                error={this.state.error.city}
                required
                fullWidth
              />
              <Input
                value={this.state.userInfos.address.state}
                placeholder="Estado"
                onChange={(e) => this.handleAdress(e, "state")}
                error={this.state.error.state}
                required
                fullWidth
              />
            </div>
          </div>
          {/*---------------------------------------------- Disponibilidade de horário------------------------ */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Disponibilidade de horário</h3>
            </div>
            <div className="sectionContent time">
              {this.state.userInfos.timeDisponibility.map(
                (timeDisponibility) => (
                  <Tag
                    closable
                    onClose={() =>
                      this.removeItem("disponibility", timeDisponibility)
                    }
                  >
                    {timeDisponibility}
                  </Tag>
                )
              )}
            </div>
            <div className="sectionContent time-form">
              <Select
                value={this.state.day}
                style={{ width: 200 }}
                placeholder="Selecione um dia da semana"
                onChange={(value) => this.handleChange(value, "day")}
              >
                {weekDays.map((day) => (
                  <Option key={`day`} value={day}>
                    {day}
                  </Option>
                ))}
              </Select>
              <RangePicker
                size="large"
                placeholder={["Horário inicial", "Horário final"]}
                value={[this.state.time[0], this.state.time[1]]}
                onChange={(time, timeString) =>
                  this.handleTime(time, timeString)
                }
                format={"HH:mm"}
              />
              <Button onClick={(e) => this.handleDisponibility()}>
                <PlusCircleOutlined />
              </Button>
              <p>
                *Pressione o botão de ok para confirmar a seleção dos horários
              </p>
            </div>
          </div>
          {/*--------------------------------- CONTA BANCÁRIA ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Conta bancária</h3>
            </div>
            <div className="sectionContent">
              <Input
                value={
                  this.state.userInfos.bankAccount[0]
                    ? this.state.userInfos.bankAccount[0].bank
                    : ""
                }
                placeholder="Banco"
                onChange={(e) => this.handleChange(e, "bank1")}
                fullWidth
              />
              <Input
                value={
                  this.state.userInfos.bankAccount[0]
                    ? this.state.userInfos.bankAccount[0].numberAccount
                    : ""
                }
                placeholder="Número da conta"
                onChange={(e) => this.handleChange(e, "numberAccount1")}
                required
                fullWidth
              />
              <Input
                value={
                  this.state.userInfos.bankAccount[0]
                    ? this.state.userInfos.bankAccount[0].agency
                    : ""
                }
                placeholder="Agência"
                onChange={(e) => this.handleChange(e, "agency1")}
                required
                fullWidth
              />
            </div>
            <div className="sectionContent">
              <Input
                value={
                  this.state.userInfos.bankAccount[1]
                    ? this.state.userInfos.bankAccount[1].bank
                    : ""
                }
                placeholder="Banco"
                onChange={(e) => this.handleChange(e, "bank2")}
                fullWidth
              />
              <Input
                value={
                  this.state.userInfos.bankAccount[1]
                    ? this.state.userInfos.bankAccount[1].numberAccount
                    : ""
                }
                placeholder="Número da conta"
                onChange={(e) => this.handleChange(e, "numberAccount2")}
                required
                fullWidth
              />
              <Input
                value={
                  this.state.userInfos.bankAccount[1]
                    ? this.state.userInfos.bankAccount[1].agency
                    : ""
                }
                placeholder="Agência"
                onChange={(e) => this.handleChange(e, "agency2")}
                required
                fullWidth
              />
            </div>
          </div>
          {/*--------------------------------- ENVIAR ----------------------------------------- */}
          <Button
            type="primary"
            className="submit"
            onClick={(e) => this.checkRequired()}
            loading={this.state.sending}
          >
            Enviar
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ register }) => ({
  loading: register.loading,
  selectedUser: register.selectedUser,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onRegisterUser: (data) => dispatch(newUser(data)),
    onFetchUsers: () => dispatch(fetchAllUsers()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CadastroResponsaveis);
