import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../../../utils/template/Header/Header";
import Logo from "../../../utils/template/Logo/Logo";
import Menu from "../../../utils/template/Menu/Menu";
import { Input } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { CheckOutlined, CalendarTwoTone } from "@ant-design/icons";
import {
  fetchReports,
  clearFilter,
} from "../../../utils/store/actions/reportsActions";
import { filterByName } from "../../../utils/store/reducers/messagesReducer";
import {
  Button,
  Table,
  Spin,
  Space,
} from "antd";
import moment from "moment";

const { Search } = Input;


function HeaderWithDate(props) {
  let bday = null;
  let bmonth = null;
  let byear = null;
  let fday = null;
  let fmonth = null;
  let fyear = null;

  if (moment(props.older).date() < 10) {
    bday = `0${moment(props.older).date()}`;
  } else {
    bday = `${moment(props.older).date()}`;
  }

  if (moment(props.newer).date() < 10) {
    fday = `0${moment(props.newer).date()}`;
  } else {
    fday = `${moment(props.newer).date()}`;
  }

  if (moment(props.older).month() + 1 < 10) {
    bmonth = `0${moment(props.older).month() + 1}`;
  } else {
    bmonth = `${moment(props.older).month() + 1}`;
  }

  if (moment(props.newer).month() + 1 < 10) {
    fmonth = `0${moment(props.newer).month() + 1}`;
  } else {
    fmonth = `${moment(props.newer).month() + 1}`;
  }

  byear = moment(props.older).year();
  fyear = moment(props.newer).year();

  return (
    bday && (
      <div className="table-title-date">
        <CalendarTwoTone />
        <p>
          Resultados entre {bday}/{bmonth}/{byear} e {fday}/{fmonth}/{fyear}
        </p>
      </div>
    )
  );
}

class BuscaMensagens extends Component {
  state = {
    filter: "",
    dates: [null, null],
    firstDate: null,
    finalDate: null,
    data: [],
    search: false,
    width: 1080,
  };

  componentDidMount() {
    window.addEventListener("resize", () => this.updateWindowDimensions());
  }

  componentWillUnmount() {
    window.removeEventListener("resize", () => this.updateWindowDimensions());
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  columns_lg = [
    {
      title: "Conversa",
      dataIndex: "id",
      key: "id",
      render: (id) => <Link to={`/mensagens/visualizar/${id}`}>{id}</Link>,
    },
    {
      title: "Usuário",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "Mensagem",
      dataIndex: "message",
      key: ""
    },    
    {
      title: "Data",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Lido",
      dataIndex: "readAdmin",
      key: "readAdmin",
      render: (readAdmin) => (readAdmin ? <CheckOutlined /> : null),
    },
  ];

  columns_md = [
    {
      title: "Conversa",
      dataIndex: "id",
      key: "id",
      render: (id) => <Link to={`/mensagens/visualizar/${id}`}>{id}</Link>,
    },
    {
      title: "Responsável",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "Data",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Lido",
      dataIndex: "readAdmin",
      key: "readAdmin",
      render: (readAdmin) => (readAdmin ? <CheckOutlined /> : null),
    },
  ];

  columns_xs = [
    {
      title: "Conversa",
      dataIndex: "id",
      key: "id",
      render: (id) => <Link to={`/mensagens/visualizar/${id}`}>{id}</Link>,
    },
    {
      title: "Lido",
      dataIndex: "readAdmin",
      key: "readAdmin",
      render: (readAdmin) => (readAdmin ? <CheckOutlined /> : null),
    },
  ];

  clear = () => {
    this.props.onClear();
    this.setState({
      filter: "",
      dates: [null, null],
      firstDate: null,
      finalDate: null,
      search: false,
    });
  };

  handleChange = (e) => {
    let filtered = [];
    filtered = filterByName(this.props.messages, e.target.value);
    this.setState({
      ...this.state,
      filter: e.target.value,
      data: [...filtered],
    });
  };

  selectLastMessage = (report) => {
    let lastMessage = 0;
    if(report.messages){
      lastMessage = report.messages.length - 1
      return({...report.messages[lastMessage], student: report.student, id: report.id, key: report.id})
    }
  }

  buildArray = (reports) => {
    let data = [];
    reports.forEach(report => {
      let item = {};
      item = this.selectLastMessage(report)
      if(item) data.push({...item})
    });
    return data
  }

  render() {
    let data = this.props.reports.length > 0 ? this.buildArray(this.props.reports) : [];
    return (
      <div className="page search">
        {/*--------------------------------- HEADER ----------------------------------------- */}
        <Header />
        <Logo />
        <Menu />
        <div className="content mt-4 ml-4">
          <h1 className="title">Mensagens</h1>
          {/*--------------------------------- BOTÕES SUPERIORES ------------------------------ */}
          <Row className="mx-0 justify-content-between">
            {/*..................................... Atualizar ................................... */}
            <Col lg={1} md={2} xs={1} className="pl-0 my-1">
              <Button
                size="middle"
                className="mr-4"
                onClick={() => this.props.onFetchReports()}
              >
                Atualizar
              </Button>
            </Col>
            {/*.............................. Campo de pesquisa .................................... */}
            <Col lg={5} md={4} xs={4} className="pl-0 my-1 mr-4">
              <Search
                size="large"
                value={this.state.filter}
                onChange={(e) => this.handleChange(e)}
                enterButton
                allowClear
                placeholder="Buscar responsável"
              />
            </Col>
          </Row>
          {/*--------------------------------- TABELA DE RESULTADOS ------------------------------ */}
          <div className="mt-5 mr-5">
          {
            !this.props.isLoading ? (
              <Table
              showHeader
              title={() =>
                HeaderWithDate({
                  older: this.props.older,
                  newer: this.props.newer,
                })
              }
              footer={() => {
                return (
                  <Space>
                    <Button
                      onClick={(e) => {
                        this.props.onFetchReports(this.props.older);
                      }}
                      type="primary"
                      shape="round"
                    >
                      Carregar mais resultados
                    </Button>
                  </Space>
                );
              }}              
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-dark" : "table-row-light"
                }
                rowSelection={{ type: "checkbox" }}
                dataSource={this.state.filter ? this.state.data : data}
                columns={this.state.width >= 935 ? this.columns_lg : (this.state.width >= 760 && this.state.width <= 935) ? this.columns_md : this.columns_xs}
                locale={{
                  emptyText: (
                      <img src={require("../../../assets/images/no-data.png")} alt="Imagem 'não encontrado'"/>
                  ),
                }}
              />
            ) : (
              <Space
              direction="vertical"
              style={{
                width: '100%',
              }}
              align="center"
            >              
              <Spin tip="Carregando" size="large"/>
              </Space>
            )
          }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ reports, user, messages }) => ({
  messages: messages.messages,
  user: user.name,
  isLoading: reports.isLoading,
  older: reports.timestamps.older,
  newer: reports.timestamps.newer,  
  reports: reports.reports
});

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchReports: (lastDate) => dispatch(fetchReports(lastDate)),
    onClear: () => dispatch(clearFilter()),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(BuscaMensagens);
