import {
  SET_MESSAGES,
  SORT_MESSAGES,
  FILTER_MESSAGE_BY_NAME,
  CLEAR_FILTER,
  FILTER_MESSAGE_BY_DATE,
  CHECK_MESSAGES,
  MESSAGE_UPLOADING,
  MESSAGE_SENT,
} from "./actionTypes";
import axios from "axios";
import { fetchReports, setReports } from "./reportsActions";
import { findPosition } from "../reducers/reportReducer";
import { message } from "antd";

export const fetchMessages = () => {
  return (dispatch, getState) => {
    const token = getState().user.userValidation.token;
    axios
      .get(`/relatorios.json?auth=${token}`)
      .catch((err) => {
        console.log(err);
        message.error(
          "M01. Ocorreu um erro ao recuperar as mensagens dos relatórios. Por favor, tente novamente ou refaça o login."
        );
      })
      .then((res) => {
        const rawReports = res.data;
        const messages = [];
        for (let key in rawReports) {
          if (rawReports[key].messages) {
            for (let id in rawReports[key].messages) {
              if (rawReports[key].messages[id]) {
                messages.push({
                  ...rawReports[key].messages[id],
                  idReport: key,
                  id: id,
                  student: rawReports[key].student,
                });
              }
            }
          }
        }
        localStorage.setItem("messages", JSON.stringify(messages));
        dispatch(setMessages(messages));
        dispatch(messageLoaded());
        dispatch(sortMessages());
      });
  };
};

export const markAsSeen = (idReport) => {
  if (idReport) {
    return (dispatch, getState) => {
      const token = getState().user.userValidation.token;
      let allReports = getState().reports.reports;
      const previousTimestamps = getState().reports.timestamps;

      const index = findPosition(allReports, idReport);
      if (index >= 0) {
        if (allReports[index].messages) {
          for (let i in allReports[index].messages) {
            allReports[index].messages[i].readAdmin = true;
          }
        }
      }

      axios
        .patch(`/relatorios/${idReport}.json?auth=${token}`, {
          ...allReports[index],
        })
        .catch((err) => {
          console.log(err);
          message.error(
            "M02. Ocorreu um erro ao atualizar as mensagens dos relatórios. Por favor, tente novamente ou refaça o login."
          );
        })
        .then(() => {
          dispatch(
            setReports(
              allReports,
              previousTimestamps.older,
              previousTimestamps.newer
            )
          );
          localStorage.removeItem("reports");
          localStorage.setItem("reports", JSON.stringify(allReports));
        });
    };
  }
};

export const newMessage = (report, idReport) => {
  //if (idReport) {
  return (dispatch, getState) => {
    const token = getState().user.userValidation.token;
    let allReports = getState().reports.reports;
    const previousTimestamps = getState().reports.timestamps;
    const messages = report.messages
    console.log(messages);
    // axios
    //   .patch(`/relatorios/${idReport}/messages.json?auth=${token}`, { messages })
    //   .catch((err) => {
    //     console.log(err);
    //     message.error(
    //       "M03. Ocorreu um erro ao enviar a mensagem. Por favor, tente novamente ou refaça o login."
    //     );
    //   })
    //   .then(() => {
        const index = findPosition(allReports, idReport);
        if (index >= 0) {
          allReports[index] = { ...report };
          dispatch(
            setReports(
              allReports,
              previousTimestamps.older,
              previousTimestamps.newer
            )
          );
          localStorage.removeItem("reports");
          localStorage.setItem("reports", JSON.stringify(allReports));
        }
      // });
  };
  //}
};

export const checkMessages = () => {
  return {
    type: CHECK_MESSAGES,
  };
};

export const clearFilter = () => {
  return {
    type: CLEAR_FILTER,
  };
};

export const setMessages = (messages) => {
  if (messages) {
    return {
      type: SET_MESSAGES,
      payload: messages,
    };
  }
};

export const sortMessages = () => {
  return {
    type: SORT_MESSAGES,
  };
};

export const filterByName = (filter) => {
  return {
    type: FILTER_MESSAGE_BY_NAME,
    payload: filter,
  };
};

export const filterByDate = (initialDate, finalDate) => {
  return {
    type: FILTER_MESSAGE_BY_DATE,
    payload: {
      initialDate: initialDate,
      finalDate: finalDate,
    },
  };
};

export const loadingMessage = () => {
  return {
    type: MESSAGE_UPLOADING,
  };
};

export const messageLoaded = () => {
  return {
    type: MESSAGE_SENT,
  };
};
