import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "antd";
import "./Login.css";
import { login } from "../../../utils/store/actions/userActions";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Person from "@material-ui/icons/Person";
import Lock from "@material-ui/icons/Lock";
import IconButton from "@material-ui/core/IconButton";
import { message } from "antd";
import { Link } from "react-router-dom";

class Login extends Component {
  state = {
    email: "",
    password: "",
    loading: false,
    showPassword: false,
    errorPassword: false,
    errorUser: false,
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.loading && !this.props.loading) {
      this.setState({ loading: false });
    }
  };

  login = () => {
    let enableLogin = false;
    let state = { ...this.state };

    if (state.email.trim() !== "") {
      if (state.password.trim() !== "") {
        enableLogin = true;
      } else {
        state.errorPassword = true;
      }
    } else {
      state.errorUser = true;
      if (state.password.trim() === "") {
        state.errorPassword = true;
      }
    }

    if (enableLogin) {
      this.props.onLogin({ ...state });
      this.setState({
        email: "",
        password: "",
        errorPassword: false,
        errorUser: false,
        loading: true,
      });
    } else {
      this.setState({ ...state });
      message.error("Todos os campos precisam ser preenchidos.");
    }
  };

  handleClickShowPassword = () => {
    this.setState({ ...this.state, showPassword: !this.state.showPassword });
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.login();
    }
  };

  render() {
    return (
      <div className="login">
        <img src={require("../../../assets/images/logo.png")} alt="logo" />
        <div className="form">
          <Input
            placeholder="E-mail"
            value={this.state.email}
            onChange={(e) => this.setState({ email: e.target.value })}
            fullWidth
            error={this.state.errorUser}
            helperText="Campo obrigatório."
            startAdornment={
              <InputAdornment position="start">
                <Person />
              </InputAdornment>
            }
          />
          <Input
            placeholder="Senha"
            value={this.state.password}
            onChange={(e) => this.setState({ password: e.target.value })}
            onKeyPress={(e) => this.handleKeyPress(e)}
            fullWidth
            type={this.state.showPassword ? "text" : "password"}
            error={this.state.errorPassword}
            helperText="Campo obrigatório."
            startAdornment={
              <InputAdornment position="start">
                <Lock />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={this.handleClickShowPassword}>
                  {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          <div className="d-flex flex-column justify-content-center align-items-center">
            <Button
              onClick={(e) => {
                this.setState({ loading: true });
                this.login();
              }}
              loading={this.state.loading}
              type="link"
              size="large"
            >
              Entrar
            </Button>
            <Link  to="/recuperar-senha">
            <Button
              type="link"
              ghost
              className="forgotPassword"
            >
              Esqueci minha senha
            </Button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  loading: user.isLoading,
  name: user.name,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (user) => dispatch(login(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
