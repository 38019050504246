import React, { Component } from "react";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import axios from "axios";
import Header from "../../../utils/template/Header/Header";
import Logo from "../../../utils/template/Logo/Logo";
import Menu from "../../../utils/template/Menu/Menu";
import {
  cpfMask,
  dateMask,
  phoneMask,
  cellPhoneMask,
  CEPMask,
} from "../../../utils/masks";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import {
  Button,
  message,
  Select,
  Tag,
  Menu as DropdownMenu,
  Dropdown,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import {
  newUser,
} from "../../../utils/store/actions/registerActions";

const { Option } = Select;

const initialState = {
  redirect: false,
  showPassword: false,
  sending: false,
  time: [null, null],
  timeString: ["", ""],
  day: "",
  registration: {
    email: "",
    password: "",
  },
  userInfos: {
    name: "",
    email: "",
    rg: "",
    gender: "",
    cpf: "",
    birthDate: "",
    phone: "",
    cellPhone: "",
    address: {
      street: "",
      number: "",
      complement: "",
      neighborhood: "",
      city: "",
      state: "",
      cep: "",
    },
    children: [],
  },
  error: {
    name: false,
    email: false,
    password: false,
    rg: false,
    cpf: false,
    birthDate: false,
    gender: false,
    cellPhone: false,
    street: false,
    number: false,
    neighborhood: false,
    complement: false,
    city: false,
    state: false,
    children: false,
  },
};

class CadastroResponsaveis extends Component {
  state = {
    ...initialState,
    time: [null, null],
    timeString: ["", ""],
    registration: { ...initialState.registration },
    userInfos: {
      ...initialState.userInfos,
      address: { ...initialState.userInfos.address },
      children: [],
    },
    error: { ...initialState.error },
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.loading && !this.props.loading) {
      this.setState({
        ...initialState,
        time: [null, null],
        timeString: ["", ""],
        registration: { ...initialState.registration },
        userInfos: {
          ...initialState.userInfos,
          address: { ...initialState.userInfos.address },
          children: [],
        },
        error: { ...initialState.error },
        redirect: true,
        sending: false,
      });
    }
  };

  handleClickShowPassword = () => {
    this.setState({ ...this.state, showPassword: !this.state.showPassword });
  };

  getAdress = () => {
    axios
      .get(
        `https://api.pagar.me/1/zipcodes/${this.state.userInfos.address.cep}`
      )
      .catch((err) => {
        const state = { ...this.state };
        state.userInfos.address.street = "";
        state.userInfos.address.city = "";
        state.userInfos.address.neighborhood = "";
        state.userInfos.address.state = "";
        this.setState({ ...state });
        message.error("CEP não encontrado");
      })
      .then((res) => {
        if (res) {
          const state = { ...this.state };
          state.userInfos.address.street = res.data.street;
          state.userInfos.address.city = res.data.city;
          state.userInfos.address.neighborhood = res.data.neighborhood;
          state.userInfos.address.state = res.data.state;
          this.setState({ ...state });
        }
      });
  };

  handleAdress = (e, option) => {
    let state = { ...this.state };
    switch (option) {
      case "street":
        state.userInfos.address.street = e.target.value;
        this.setState({ ...state });
        break;
      case "number":
        state.userInfos.address.number = e.target.value;
        this.setState({ ...state });
        break;
      case "complement":
        state.userInfos.address.complement = e.target.value;
        this.setState({ ...state });
        break;
      case "neighborhood":
        state.userInfos.address.neighborhood = e.target.value;
        this.setState({ ...state });
        break;
      case "city":
        state.userInfos.address.city = e.target.value;
        this.setState({ ...state });
        break;
      case "state":
        state.userInfos.address.state = e.target.value;
        this.setState({ ...state });
        break;
      case "cep":
        state.userInfos.address.cep = e.target.value;
        this.setState({ ...state });
        break;
      case "children":
        state.userInfos.children.push(e);
        this.setState({ ...state });
        break;
      default:
        this.setState({ ...state });
        break;
    }
  };

  checkRequired = () => {
    let state = { ...this.state };
    let flag = false;

    state.error.name = state.userInfos.name.trim() === ""
    state.error.email = state.userInfos.email.trim() === "";
    state.error.password = state.registration.password.length < 6;
    // state.error.rg = state.userInfos.rg.trim() === "";
    // state.error.cpf = state.userInfos.cpf.trim() === "";
    // state.error.birthDate = state.userInfos.birthDate.trim() === "";
    // state.error.gender = state.userInfos.gender.trim() === "";
    // state.error.cellPhone = state.userInfos.cellPhone.trim() === "";
    // state.error.street = state.userInfos.address.street.trim() === "";
    // state.error.number = state.userInfos.address.number.trim() === "";
    // state.error.neighborhood = state.userInfos.address.neighborhood.trim() === "";
    // state.error.city = state.userInfos.address.city.trim() === "";
    // state.error.state = state.userInfos.address.state.trim() === "";
    // state.error.children = state.userInfos.children.length === 0;
    
    for (let key in state.error){
      if (state.error[key] === true){
        flag = true;
      }
    }

    if (flag) {
      message.error("Todos os campos obrigatórios precisam ser preenchidos.");
      this.setState({ ...state });
    } else {
      state.sending = true;
      this.setState({ ...state });
      const data = {
        type: "responsavel",
        folder: "responsaveis",
        registration: {...state.registration},
        userInfos: { ...state.userInfos },
      };
      this.props.onRegisterUser(data);
      message.info(
        "Aguarde um momento, as informações estão sendo processadas."
      );
    }
  };

  removeItem(type, item) {
    let state = { ...this.state };
    if (type === "children") {
      for (let i = 0; i < state.userInfos.children.length; i++) {
        if (state.userInfos.children[i] === item) {
          state.userInfos.children.splice(i, 1);
        }
      }
    }
    this.setState({ ...state });
  }

  menuGender = (
    <DropdownMenu onClick={(e) => this.handleChange(e, "gender")}>
      <DropdownMenu.Item key="Feminino">Feminino</DropdownMenu.Item>
      <DropdownMenu.Item key="Masculino">Masculino</DropdownMenu.Item>
    </DropdownMenu>
  );

  handleChange = (e, option) => {
    let state = { ...this.state };
    switch (option) {
      case "name":
        state.userInfos.name = e.target.value;
        this.setState({ ...state });
        break;
      case "email":
        state.userInfos.email = e.target.value;
        state.registration.email = e.target.value;
        this.setState({ ...state });
        break;
      case "password":
        state.registration.password = e.target.value;
        this.setState({ ...state });
        break;
      case "rg":
        state.userInfos.rg = e.target.value;
        this.setState({ ...state });
        break;
      case "cpf":
        state.userInfos.cpf = cpfMask(e.target.value);
        this.setState({ ...state });
        break;
      case "birthDate":
        state.userInfos.birthDate = dateMask(e.target.value);
        this.setState({ ...state });
        break;
      case "gender":
        state.userInfos.gender = e.key;
        this.setState({ ...state });
        break;
      case "phone":
        state.userInfos.phone = phoneMask(e.target.value);
        this.setState({ ...state });
        break;
      case "cellPhone":
        state.userInfos.cellPhone = cellPhoneMask(e.target.value);
        this.setState({ ...state });
        break;
      case "children":
        state.userInfos.children.push(e);
        this.setState({ ...state });
        break;
      default:
        this.setState({ ...state });
        break;
    }
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.getAdress();
    }
  };

  render() {
    return this.state.redirect ? (
      <Redirect to={`/usuarios/buscar/responsaveis/`} />
    ) : this.state.loading ? null : (
      <div className="page parents">
        {/*--------------------------------- HEADER ----------------------------------------- */}
        <Header />
        <Logo />
        <Menu />
        <div className="content mt-4 ml-4">
          <h1 className="title">Cadastro de novo responsável</h1>
          {/*--------------------------------- PAI ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Nome</h3>
            </div>
            <div className="sectionContent">
              <Input
                value={this.state.userInfos.name}
                onChange={(e) => this.handleChange(e, "name")}
                error={this.state.error.name}
                required
                fullWidth
              />
            </div>
          </div>
          {/*--------------------------------- EMAIL ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">E-mail</h3>
            </div>
            <div className="sectionContent">
              <Input
                value={this.state.userInfos.email}
                placeholder="exemplo@email.com"
                onChange={(e) => this.handleChange(e, "email")}
                error={this.state.error.email}
                required
                fullWidth
              />
            </div>
          </div>
          {/*--------------------------------- SENHA ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Senha</h3>
            </div>
            <div className="sectionContent">
              <Input
                placeholder="xxx-xxx-xxx"
                value={this.state.registration.password}
                onChange={(e) => this.handleChange(e, "password")}
                fullWidth
                type={this.state.showPassword ? "text" : "password"}
                error={this.state.error.password}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={this.handleClickShowPassword}>
                      {this.state.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
               <p className="password">*Mínimo de 6 caracteres</p>
            </div>
          </div>
          {/*--------------------------------- RG ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">RG</h3>
            </div>
            <div className="sectionContent">
              <Input
                value={this.state.userInfos.rg}
                placeholder="xxxxxxxxx-x"
                onChange={(e) => this.handleChange(e, "rg")}
                fullWidth
              />
            </div>
          </div>
          {/*--------------------------------- CPF ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">CPF</h3>
            </div>
            <div className="sectionContent">
              <Input
                value={this.state.userInfos.cpf}
                placeholder="xxx.xxx.xxx-xx"
                onChange={(e) => this.handleChange(e, "cpf")}
                fullWidth
              />
            </div>
          </div>
          {/*--------------------------------- NASCIMENTO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Data de nascimento</h3>
            </div>
            <div className="sectionContent">
              <Input
                value={this.state.userInfos.birthDate}
                placeholder="dd/mm/aaaa"
                onChange={(e) => this.handleChange(e, "birthDate")}
                error={this.state.error.birthDate}
                required
                fullWidth
              />
            </div>
          </div>
          {/*--------------------------------- GÊNERO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Gênero</h3>
            </div>
            <div
              className={`sectionContent ${
                this.state.error.gender ? "error" : ""
              }`}
            >
              <Dropdown overlay={this.menuGender}>
                <Button size="small" className="acoes-massa">
                  {this.state.userInfos.gender
                    ? this.state.userInfos.gender
                    : "Gênero"}
                  <DownOutlined />
                </Button>
              </Dropdown>
            </div>
          </div>
          {/*--------------------------------- TELEFONE ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Telefone</h3>
            </div>
            <div className="sectionContent">
              <Input
                value={this.state.userInfos.phone}
                placeholder="(xx) xxxx-xxxx"
                onChange={(e) => this.handleChange(e, "phone")}
                fullWidth
              />
            </div>
          </div>
          {/*--------------------------------- CELULAR ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Celular</h3>
            </div>
            <div className="sectionContent">
              <Input
                value={this.state.userInfos.cellPhone}
                placeholder="(xx) xxxxx-xxxx"
                onChange={(e) => this.handleChange(e, "cellPhone")}
                error={this.state.error.cellPhone}
                fullWidth
              />
            </div>
          </div>
          {/*--------------------------------- ENDEREÇO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Endereço</h3>
            </div>
            <div className="sectionContent address">
              <Input
                value={CEPMask(this.state.userInfos.address.cep)}
                placeholder="CEP"
                onChange={(e) => this.handleAdress(e, "cep")}
                fullWidth
                onKeyPress={(e) => this.handleKeyPress(e)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={(e) => this.getAdress()}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
              <Input
                value={this.state.userInfos.address.street}
                placeholder="Rua/Avenida"
                onChange={(e) => this.handleAdress(e, "street")}
                error={this.state.error.street}
                required
                fullWidth
              />
              <Input
                value={this.state.userInfos.address.number}
                placeholder="Número"
                onChange={(e) => this.handleAdress(e, "number")}
                error={this.state.error.number}
                required
                fullWidth
              />
              <Input
                value={this.state.userInfos.address.complement}
                placeholder="Complemento"
                onChange={(e) => this.handleAdress(e, "complement")}
                required
                fullWidth
              />
              <Input
                value={this.state.userInfos.address.neighborhood}
                placeholder="Bairro"
                onChange={(e) => this.handleAdress(e, "neighborhood")}
                error={this.state.error.neighborhood}
                required
                fullWidth
              />
              <Input
                value={this.state.userInfos.address.city}
                placeholder="Cidade"
                onChange={(e) => this.handleAdress(e, "city")}
                error={this.state.error.city}
                required
                fullWidth
              />
              <Input
                value={this.state.userInfos.address.state}
                placeholder="Estado"
                onChange={(e) => this.handleAdress(e, "state")}
                error={this.state.error.state}
                required
                fullWidth
              />
            </div>
          </div>
          {/*--------------------------------- Filhos ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Filhos</h3>
            </div>
            <div
              className={`sectionContent time`}
            >
              {this.state.userInfos.children.map((child) => (
                <Tag
                  closable
                  onClose={() => this.removeItem("children", child)}
                >
                  {child}
                </Tag>
              ))}
            </div>
            <div
              className={`sectionContent ${
                this.state.error.children ? "error" : ""
              }`}
            >
              <Select
                showSearch
                placeholder="Selecione um aluno"
                optionFilterProp="children"
                onChange={(value) => this.handleChange(value, "children")}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.props.users.map(
                  (user, index) =>
                    user.type === "aluno" && (
                      <Option key={`${user.localId}child`} value={user.name}>
                        {user.name}
                      </Option>
                    )
                )}
              </Select>
            </div>
          </div>
          {/*--------------------------------- ENVIAR ----------------------------------------- */}
          <Button
            type="primary"
            className="submit"
            onClick={(e) => this.checkRequired()}
            loading={this.state.sending}
          >
            Enviar
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ register }) => ({
  loading: register.loading,
  selectedUser: register.selectedUser,
  users: register.users
});

const mapDispatchToProps = (dispatch) => {
  return {
    onRegisterUser: (data) => dispatch(newUser(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CadastroResponsaveis);
