import React, { useEffect, useState } from "react";
import { DatePicker, Modal, Checkbox, Radio, Select, InputNumber } from "antd";
import {
  customFrequency as initialFrequency,
  options,
  days,
} from "./helpers/constants";
import {
  generateMessage,
  getRepetitionType,
  disabledDate,
} from "./helpers/functions";
import moment from "moment";

const { Option } = Select;

export default ({ visible, onOk, onCancel, currentFrequency }) => {
  const [frequency, setFrequency] = useState({ ...initialFrequency });

  useEffect(() => {
    if (currentFrequency && currentFrequency.index === 4) {
      setFrequency({
        ...currentFrequency,
        repetionType: getRepetitionType(currentFrequency.repetionType),
      });
    }
  }, [currentFrequency]);

  const handleCustomFrequency = (key, value) => {
    const state = { ...frequency };
    switch (key) {
      case "repetionType":
        state.repetionType = value;
        state.onDay = [];
        setFrequency({ ...state });
        break;
      case "onDay":
        if (state.onDay.includes(value)) {
          state.onDay = state.onDay.filter((day) => day !== value);
        } else {
          state.onDay.push(value);
        }
        setFrequency({ ...state });
        break;
      case "date":
        state.date = value;
        setFrequency({ ...state });
        break;
      case "radio":
        state.endType = value.target.value;
        setFrequency({ ...state });
        break;
      case "endOn":
        state.endOn = value;
        state.after = "";
        state.endType = "on";
        setFrequency({ ...state });
        break;
      case "after":
        state.after = value ? value : 1;
        state.endType = "after";
        state.endOn = moment();
        setFrequency({ ...state });
        break;
      default:
        setFrequency({ ...state });
        break;
    }
  };

  const validation = () => {
    if (
      frequency.repetionType === "semanalmente" &&
      frequency.onDay.length === 0
    ) {
      alert("Selecione os dias em que o evento se repete.");
    } else {
      onOk(frequency);
    }
  };

  return (
    <Modal
      title="Frequência personalizada"
      visible={visible}
      onOk={validation}
      onCancel={onCancel}
      okText="Selecionar"
      cancelText="Cancelar"
      centered
      maskClosable
    >
      <div className="modal-frequency-section row">
        <div className="col-2">
          <p className="modal-section-title">Repetir: </p>
        </div>
        <div className="col-8">
          <Select
            placeholder="Escolha a frequência de repetição"
            onChange={(value) => handleCustomFrequency("repetionType", value)}
            value={frequency.repetionType}
          >
            {options.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      {frequency.repetionType === "semanalmente" && (
        <div className="modal-frequency-section">
          <div>
            <p className="modal-section-title">Repetir nos dias: </p>
          </div>
          <div>
            {days.map((day, index) => (
              <Checkbox
                checked={frequency.onDay.includes(index)}
                onClick={() => handleCustomFrequency("onDay", index)}
                style={{ marginLeft: 0, width: 80 }}
              >
                {days[index]}
              </Checkbox>
            ))}
          </div>
        </div>
      )}
      {(frequency.repetionType === "mensalmente" ||
        frequency.repetionType === "anualmente") && (
        <div className="modal-frequency-section row align-items-end">
          <div className="col-4">
            <p className="modal-section-title mb-0">
              Todo dia:
              {generateMessage(frequency.date, frequency.repetionType)}
            </p>
          </div>
          <div className="col-8">
            <div className="row align-items-end">
              <div className="col-5">
                <p className="modal-section-title mb-0">Escolher outro dia:</p>
              </div>
              <div className="col-5 px-0">
                <DatePicker
                  placeholder="Selecione outro dia"
                  style={{ width: "100%" }}
                  defaultValue={frequency.date}
                  onChange={(date) => handleCustomFrequency("date", date)}
                  format="DD/MM/YYYY"
                  suffixIcon=""
                  allowClear={false}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="modal-frequency-section">
        <div>
          <p className="modal-section-title">Termina em: </p>
        </div>
        <div>
          <Radio.Group
            buttonStyle="solid"
            name="radiofrequency"
            value={frequency.endType}
            onChange={(e) => handleCustomFrequency("radio", e)}
          >
            <div className="radio-frequency-item">
              <Radio value="never">Nunca</Radio>
            </div>
            <div className="row px-0 radio-frequency-item">
              <div className="col-3 ml-0">
                <Radio value="on">Em: </Radio>
              </div>
              <div className="col-6">
                <DatePicker
                  placeholder="Selecione outro dia"
                  style={{ width: "100%" }}
                  value={frequency.endOn}
                  onChange={(date) => handleCustomFrequency("endOn", date)}
                  format="DD/MM/YYYY"
                  suffixIcon=""
                  allowClear={false}
                  disabledDate={disabledDate}
                />
              </div>
            </div>
            <div className="row px-0 radio-frequency-item">
              <div className="col-9 ml-0 pr-0">
                <Radio value="after">Após (número de vezes): </Radio>
              </div>
              <div className="col-2 px-0 mr-0">
                <InputNumber
                  onChange={(e) => handleCustomFrequency("after", e)}
                  value={frequency.after}
                  min={1}
                />
              </div>
            </div>
          </Radio.Group>
        </div>
      </div>
    </Modal>
  );
};
