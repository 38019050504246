import React, { useEffect, useState } from "react";
import { Modal, Radio } from "antd";

export const OpcoesNotificacao = ({ value, handleChange }) => {
  return (
    <Radio.Group
      buttonStyle="solid"
      name="notification-option"
      value={value}
      onChange={(e) => handleChange(e, "sendNotification")}
      className={"exclusion col-9 ml-3"}
    >
      <div className="row">
        <div className="col-2 mr-3">
          <Radio value="yes">Sim</Radio>
        </div>
        <div className="col-2 mr-3">
          <Radio value="no">Não</Radio>
        </div>
      </div>
    </Radio.Group>
  );
};

export default ({
  visible,
  onOk,
  onCancel,
  answerType,
  details,
  handleChange,
  answer,
}) => {
  const [title, setTitle] = useState("Aceitar convite");
  useEffect(
    () => (answer ? setTitle("Aceitar convite") : setTitle("Recusar convite")),
    [answer]
  );
  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onCancel}
      onOk={() => onOk(details, answer, false)}
      okText="Responder"
      cancelText="Cancelar"
      centered
      maskClosable
      closable={false}
    >
      <React.Fragment>
        <React.Fragment>
          <p>
            {details.type === "class"
              ? answer ? "Deseja aceitar somente esta ou esta e os próximas aulas?" : "Deseja recusar somente esta ou esta e os próximas aulas?"
              : answer ? "Deseja aceitar somente este ou este e os próximos eventos?" : "Deseja recusar somente este ou este e os próximos eventos?" }
          </p>
          <Radio.Group
            buttonStyle="solid"
            name="exclusion-option"
            value={answerType}
            onChange={(e) => handleChange(e, "answerType")}
            className={"exclusion"}
          >
            <div className="row mx-0">
              <div>
                <Radio value="this">
                  {details.type === "class" ? "Esta aula" : "Este evento"}
                </Radio>
              </div>
              <div>
                <Radio value="all">
                  {details.type === "class"
                    ? "Esta e as próximas aulas"
                    : "Este e os próximos eventos"}
                </Radio>
              </div>
            </div>
          </Radio.Group>
        </React.Fragment>
      </React.Fragment>
    </Modal>
  );
};
