import {
  SET_USERS_FOR_REGISTER,
  SET_USER_INFO,
  FETCHING_INFO,
  INFO_LOADED,
  UPDATING_INFO,
  INFO_UPDATED,
  FILTER_USERS_BY_NAME,
  CLEAR_FILTER,
  SET_STUDENTS,
  SET_TUTORS,
} from "../actions/actionTypes";
import axios from "axios";
import { message } from "antd";

const functionsBaseURL =
  "https://us-central1-banco-de-dados-realize.cloudfunctions.net";

export const fetchAllUsers = () => {
  return (dispatch, getState) => {
    dispatch(loadingInfo());
    const token = getState().user.userValidation.token;
    axios
      .get(`/usuarios.json?auth=${token}`)
      .catch((err) => {
        console.log(err);
        message.error(
          "RG01. Ocorreu um erro ao recuperar as informações dos usuários. Por favor, tente novamente ou refaça o login."
        );
      })
      .then((res) => {
        const rawData = res.data;
        let users = [];
        let tutors = [];
        let students = [];
        let parents = [];
        for (let key in rawData) {
          users.push({ ...rawData[key], localId: key });
          if (
            rawData[key].type === "tutor" ||
            rawData[key].type === "admin" ||
            rawData[key].type === "orientador"
          ) {
            tutors.push({ ...rawData[key], localId: key });
          } else if (rawData[key].type.includes("aluno")) {
            students.push({ ...rawData[key], localId: key });
          } else if (rawData[key].type.includes("responsavel")) {
            parents.push({ ...rawData[key], localId: key });
          }
        }
        axios
          .get(`/responsaveis.json?auth=${token}`)
          .catch((err) => {
            console.log(err);
            message.error("RP04. Erro ao acessar as informações dos pais.");
          })
          .then((res) => {
            let children = [];
            const rawData = res.data;
            for (let key in rawData) {
              if (rawData[key]) {
                children.push({
                  name: rawData[key].name,
                  id: key,
                  email: rawData[key].email,
                  children: rawData[key].children,
                });
              }
            }
            dispatch(
              setUsersforRegister(users, tutors, students, parents, children)
            );
          });
      });
  };
};

export const setUsersforRegister = (
  users,
  tutors,
  students,
  parents,
  children
) => {
  return {
    type: SET_USERS_FOR_REGISTER,
    payload: {
      users: users,
      tutors: tutors,
      students: students,
      parents: parents,
      children: children,
    },
  };
};

export const fetchUserInfos = (type, localId) => {
  const folder = databaseFolder(type);
  return (dispatch, getState) => {
    dispatch(loadingInfo());
    const token = getState().user.userValidation.token;
    axios.get(`/${folder}/${localId}.json?auth=${token}`).then(
      (res) => {
        const userInfos = res.data;
        dispatch(setUserInfos(userInfos));
        dispatch(infoLoaded());
      },
      (err) => {
        console.log(err);
        message.error(
          "RG02. Ocorreu um erro ao recuperar a informação dos usuário. Por favor, tente novamente ou refaça o login."
        );
      }
    );
  };
};

export const fetchUser = (localId) => {
  return (dispatch, getState) => {
    dispatch(loadingInfo());
    const token = getState().user.userValidation.token;
    axios
      .get(`/usuarios/${localId}.json?auth=${token}`)
      .then((res) => {
        dispatch(fetchUserInfos(res.data.type, localId));
      })
      .catch((err) => {
        console.log(err);
        message.error(
          "RG03. Ocorreu um erro ao recuperar a informação dos usuário. Por favor, tente novamente ou refaça o login."
        );
      });
  };
};

const loadingInfo = () => {
  return {
    type: FETCHING_INFO,
  };
};

const infoLoaded = () => {
  return {
    type: INFO_LOADED,
  };
};

const setUserInfos = (info) => {
  return {
    type: SET_USER_INFO,
    payload: info,
  };
};

export const updateUser = (user, type, localId) => {
  return (dispatch, getState) => {
    dispatch(updatingInfo());
    const token = getState().user.userValidation.token;
    const folder = databaseFolder(type);
    const newInfo = {
      name: user.name,
      type: type,
      email: user.email,
    };
    const requests = [
      axios.patch(`/${folder}/${localId}.json?auth=${token}`, user),
      axios.patch(`/usuarios/${localId}.json?auth=${token}`, newInfo),
    ];
    axios.all(requests).then(
      () => {
        dispatch(setUserInfos(user));
        dispatch(fetchAllUsers());
      },
      (err) => {
        console.log(err);
        message.error(
          "RG04. Ocorreu um erro ao atualizar a informação dos usuário. Por favor, tente novamente ou refaça o login."
        );
      }
    );
  };
};

export const newUser = (data) => {
  return (dispatch) => {
    dispatch(loadingInfo());
    const basicInfo = {
      name: data.userInfos.name,
      image: "",
      type: data.type,
    };
    axios({
      url: "register",
      baseURL: functionsBaseURL,
      method: "post",
      data: {
        email: data.registration.email,
        password: data.registration.password,
      },
    })
      .then((res) => {
        if(res.data.config){
        const userInfos = res.data.config;
        axios
          .put(`/usuarios/${userInfos.localId}.json?auth=${userInfos.token}`, {
            ...basicInfo,
          })
          .then(() =>
            axios
              .put(
                `/${data.folder}/${userInfos.localId}.json?auth=${userInfos.token}`,
                { ...data.userInfos, type: data.type }
              )
              .catch((err) => {
                console.log(err);
                message.error(
                  "RG05. Ocorreu um erro ao registrar a informação do novo usuário cadastrado. Por favor, tente novamente ou refaça o login."
                );
              })
              .then(() => {
                dispatch(fetchAllUsers());
                message.success("Usuário criado com sucesso");
              })
          )
          .catch((err) => {
            console.log(err);
            message.error(
              "RG05. Ocorreu um erro ao recuperar as informações dos usuários. Por favor, tente novamente ou refaça o login."
            );
          });
        } else {
          message.error(
            "Ocorreu um erro ao recuperar ao registrar o usuário. Por favor, verifique o console."
          );
          console.log(res)
        }
      })
      .catch((err) => {
        console.log(err);
        message.error(
          "RG05. Ocorreu um erro ao cadastrar o novo usuário. Por favor, tente novamente ou refaça o login."
        );
      });
  };
};

export const fetchTutors = () => {
  return (dispatch, getState) => {
    const token = getState().user.userValidation.token;
    axios
      .get(`/usuarios.json?auth=${token}`)
      .catch((err) => {
        console.log(err);
        message.error(
          "RP02. Ocorreu um erro ao recuperar as informações dos tutores. Por favor, tente novamente ou refaça o login."
        );
      })
      .then((res) => {
        let tutor = [];
        const rawData = res.data;
        for (let key in rawData) {
          if (
            rawData[key].type === "tutor" ||
            rawData[key].type === "orientador" ||
            rawData[key].type === "admin"
          ) {
            tutor.push(rawData[key].name);
          }
        }
        dispatch(setTutors(tutor));
        return tutor;
      });
  };
};

export const fetchStudents = () => {
  return (dispatch, getState) => {
    const token = getState().user.userValidation.token;
    axios
      .get(`/usuarios.json?auth=${token}`)
      .catch((err) => {
        console.log(err);
        message.error(
          "RP03. Ocorreu um erro ao recuperar as informações dos alunos. Por favor, tente novamente ou refaça o login."
        );
      })
      .then((res) => {
        let students = [];
        const rawData = res.data;
        for (let key in rawData) {
          if (rawData[key].type === "aluno") {
            students.push(rawData[key].name);
          }
        }
        dispatch(setStudents(students));
        return students;
      });
  };
};

export const setFilteredUsers = (filter, type) => {
  return {
    type: FILTER_USERS_BY_NAME,
    payload: {
      filter: filter,
      type: type,
    },
  };
};

const updatingInfo = () => {
  return {
    type: UPDATING_INFO,
  };
};

const infoUpdated = () => {
  return {
    type: INFO_UPDATED,
  };
};

export const clearFilter = () => {
  return {
    type: CLEAR_FILTER,
  };
};

export const setTutors = (tutors) => {
  return {
    type: SET_TUTORS,
    payload: tutors,
  };
};

export const setStudents = (students) => {
  return {
    type: SET_STUDENTS,
    payload: students,
  };
};

const databaseFolder = (type) => {
  let folder = "";
  switch (type) {
    case "aluno":
      folder = "alunos";
      break;
    case "aluno-responsavel":
      folder = "alunos";
      break;
    case "admin":
      folder = "admin";
      break;
    case "orientador":
      folder = "orientadores";
      break;
    case "responsavel":
      folder = "responsaveis";
      break;
    case "tutor":
      folder = "tutores";
      break;
    default:
      folder = "";
      break;
  }
  return folder;
};
