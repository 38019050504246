import React, { Component } from "react";
import { connect } from "react-redux";

class Header extends Component {
  firstName = this.props.name.split(" ", 1);
  initial = this.firstName[0].split("", 1);

  render() {
    return (
      <header className="header d-sm-flex flex-column pr-2">
        <div className="d-flex align-items-center mr-2">
          <p className="saudacao mb-0 mr-2">Olá, {this.firstName}</p>
          <div className="avatar">
            {this.props.image ? (
              <img src={this.props.image} alt="User profile"/>
            ) : (
              <p>{this.initial}</p>
            )}
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  name: user.name,
  image: user.image,
});

export default connect(mapStateToProps)(Header);
